import React from 'react';
import Button from "../Button";
import { useNavigate } from "react-router-dom";

function BackButton({onClick}: {onClick?: string}) {
    const navigate = useNavigate();

    return (
        <Button label="action.back" background="danger" onClick={() => onClick ?? navigate(-1)}/>
    );
}

export default BackButton;
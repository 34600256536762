import React from 'react';
import {IntLabel} from "@app/i18n";
import {
    ContractPaymentStatus,
    ContractStatus,
    enumLabel, InterestStatus, InvitationStatus,
    PaymentStatus,
    PropertyStatus,
    PropertyVisibility, TransactionType,
    Verified
} from "../interface/Enums";
import {PermissionLevel} from "../interface/PermissionLevel";
import {PermissionAccess} from "../interface/PermissionAccess";

export function PaymentStatusBadge({status}: {status: PaymentStatus}) {
    function getStatusClass(status: string): string {
        switch (enumLabel(PaymentStatus, status)) {
            case PaymentStatus.pending:
                return 'bg-secondary';
            case PaymentStatus.failed:
                return 'bg-danger';
            case PaymentStatus.done:
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <div className={`badge badge-sm ${getStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(PaymentStatus, status)}/>
        </div>
    );
}

export function ContractStatusBadge({status}: {status: string}) {
    function getStatusClass(status: string): string {
        switch (enumLabel(ContractStatus, status)) {
            case ContractStatus.ongoing:
                return 'bg-info';
            case ContractStatus.cancelled:
                return 'bg-danger';
            case ContractStatus.finish:
                return 'bg-success';
            case ContractStatus.draft:
                return 'bg-secondary';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <div className={`badge badge-sm ${getStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(ContractStatus, status) ?? ''}/>
        </div>
    );
}

export function PropertyVisibilityStatusBadge({status}: {status: string}) {
    function getStatusClass(status: string): string {
        switch (enumLabel(PropertyVisibility, status)) {
            case PropertyVisibility.draft:
                return 'bg-secondary';
            case PropertyVisibility.unpublished:
                return 'bg-danger';
            case PropertyVisibility.published:
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <div className={`badge badge-sm ${getStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(PropertyVisibility, status) ?? ''}/>
        </div>
    );
}

export function TransactionBadge({status}: {status: string}) {
    function getStatusClass(status: string): string {
        switch (enumLabel(TransactionType, status)) {
            case TransactionType.disburse:
                return 'bg-danger';
            case TransactionType.payment:
                return 'bg-success';
            case TransactionType.deposit:
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <div className={`badge badge-sm ${getStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(TransactionType, status) ?? ''}/>
        </div>
    );
}

export function ContractPaymentStatusBadge({status}: {status: string}) {
    function getStatusClass(status: string): string {
        switch (enumLabel(ContractPaymentStatus, status)) {
            case ContractPaymentStatus.late:
                return 'bg-danger';
            case ContractPaymentStatus.advanced:
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <div className={`badge badge-sm ${getStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(PropertyVisibility, status) ?? ''}/>
        </div>
    );
}

export function InvitationStatusBadge({status} : {status: string}){
    function getInvitationStatusClass(status: string){
        switch (enumLabel(InvitationStatus, status)){
            case InvitationStatus.created:
                return 'bg-info';
            case InvitationStatus.accepted:
                return 'bg-success';
            case InvitationStatus.rejected:
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    }

    return(
        <div className={`badge badge-sm ${getInvitationStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(InvitationStatus, status) ?? ''}/>
        </div>
    )
}

export function PermissionLevelStatusBadge({status, size} : {status: string, size?: any}){
    const access = enumLabel(PermissionLevel, status);
    function getPermissionLevelStatusClass(status: string){
        switch (access){
            case PermissionLevel.BASIC:
                return 'bg-info';
            case PermissionLevel.MEDIUM:
                return 'bg-success';
            case PermissionLevel.HIGH:
                return 'bg-danger';
            case PermissionLevel.OWNER:
                return 'bg-primary'
            default:
                return 'bg-secondary';
        }
    }
    return(
        <div className={`badge badge-sm ${getPermissionLevelStatusClass(status)}`}
             style={{fontSize: "1.03rem", width: size ?? '90%'}}>
            <IntLabel label={enumLabel(PermissionLevel, status)}/>
        </div>
    )
}
export function PropertyInterestBadge({status}: {status: string}) {
    function getStatusClass(status: string): string {
        switch (enumLabel(InterestStatus, status)) {
            case InterestStatus.generated:
                return 'bg-info';
            case InterestStatus.unpaid:
                return 'bg-danger';
            case InterestStatus.paid:
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <div className={`badge badge-sm ${getStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(InterestStatus, status)}/>
        </div>
    );
}

export function PropertyStatusBadge({status}: {status: string}) {
    function getStatusClass(status: string): string {
        switch (enumLabel(PropertyStatus, status)) {
            case PropertyStatus.sold:
                return 'bg-info';
            case PropertyStatus.booked:
                return 'bg-danger';
            case PropertyStatus.available:
                return 'bg-success';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <div className={`badge badge-sm ${getStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(PropertyStatus, status)}/>
        </div>
    );
}

export function PermissionAccessBadge({status, className}: {status: string, className?: any}) {
    return <div className={`badge badge-sm bg-success ${className}`} style={{fontSize: "1.03rem"}}>
        <IntLabel label={`text.enum.${status}`}/>
    </div>;
}

export function UserEmailVerifiedBadge({status} : {status: true}){
    let ver = ""
    function getVerifiedState(status: any)  {
        if(status === true){
            ver = "verified"
            return "bg-success";
        }
        else if(status === false){
            ver = "pending";
            return 'bg-secondary';
        }
    }

    return(
        <div className={`badge badge-sm ${getVerifiedState(status)}`} style={{fontSize: "1.03rem"}}>
            {/*<IntLabel label={enumLabel(Verified, ver)}/>*/}
        </div>
    )
}

export function InterestStatusBadge({status} : {status: string}){
    function getInterestStatusStatusClass(status: string){
        switch (enumLabel(InterestStatus, status)){
            case InterestStatus.generated:
                return 'bg-info';
            case InterestStatus.paid:
                return 'bg-success';
            case InterestStatus.unpaid:
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    }

    return(
        <div className={`badge badge-sm ${getInterestStatusStatusClass(status)}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={enumLabel(InterestStatus, status) ?? ''}/>
        </div>
    )
}

export function BooleanStatus({status} : {status: boolean}){
    return(
        <div className={`badge badge-sm ${status ? 'bg-success' : 'bg-danger'}}`} style={{fontSize: "1.03rem"}}>
            <IntLabel label={status ? 'text.active' : 'text.inactive'}/>
        </div>
    )
}
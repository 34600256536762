const actionLabels  = {
    "btn.close": "Fermé",
    "btn.add.contract": "Nouveau contrat",
    "action.back":"Retour",
    "btn.validate":"Validé",
    "btn.delete":"Modifier",
    "btn.add.employee":"Nouveau employé",
    "btn.add.land":"Nouveau Terrain",
    "btn.add.invitation":"Nouvelle invitation",
    "btn.add.payment.link":"Génerer un lien de paiement",
    "btn.delete.land": "Supprimer le terrain",
    "btn.unpublished.land": "Arreter la publication",
    "btn.published.land": "Publier",
    "btn.edit.land": "Modifier les informations",
    "btn.add.land.part": "Nouvelle parcelle",
    "btn.add.payment": "Ajouter un paiement",

    'btn.edit': "Modifier",
    'btn.add,partner': "Ajouter un partenaire",
    'btn.edit.partner': "Modifier un partenaire",
    "btn.add.template": "Nouveau template",
    'btn.save': "Sauvegarder",

    'btn.declined': "Refuser",
    'btn.add.transaction': "Ajouter une transaction",

}
export default actionLabels
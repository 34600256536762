import React from "react";
import {IntLabel} from "@app/i18n";
import {PermissionLevelStatusBadge, UserEmailVerifiedBadge} from "@app/core/src/components/Badges";
import {parseUserAccess} from "@app/core/src/helpers/stringHelpers";


interface EmployeeDetailsOverviewParams {
    label: string; children?: any; actionButtons?: any[]; details?: any[]; access?: any;
}

export default function EmployeeDetailsOverview(props: EmployeeDetailsOverviewParams){

    return(
        <div className="card mb-5 mb-xl-10 shadow-sm">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0"><IntLabel label={props.label}/></h3>
                </div>
                <div className="ms-auto my-auto">
                    {(props?.actionButtons || []).map((item: any, index: number) =>
                        <button onClick={item.onClick}
                                className={`btn btn-sm btn-${item.color ?? 'primary'} align-self-center mx-3`}>
                            <IntLabel label={item.label}/>
                        </button>)}
                </div>
            </div>
            <div className="card-body p-9">
                <div className="row">
                    <div className="col-12 col-md-10">
                        {(props.details || []).map((item: any, index: number) =>
                            <div className="row mb-7" key={index}>
                                <label className="col-lg-3 fw-bold text-muted h5">
                                    <IntLabel label={item.label}/>
                                </label>
                                <div className="col-lg-6">
                                    <span className="fw-bolder fs-6 text-gray-800">
                                        {item.value}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12 col-md-10">
                        {props.access ?? 'Aucun role'}
                    </div>
                </div>
            </div>
        </div>
    )
}
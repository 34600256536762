export function formatCurrency(price: number, local?: string, currency?: string) {
    return new Intl.NumberFormat(
        local ?? 'fr-FR',
        { style: 'currency', currency: currency ?? 'XAF' })
        .format(price)
}

export function formatNumber(price: number, local?: string) {
    return new Intl.NumberFormat(local ??'fr-FR')
        .format(price)
}
import React from 'react';
import AppLayout from "@apps/app/src/components/Layout/index";
import {companyRoutesPaths} from "./companyRoutesPaths";
import CompanyEmployeesView from "../pages/employees/CompanyEmployeesView";
import CompanyEmployeeDetailsView from "../pages/employees/CompanyEmployeeDetailsView";
import ManageTemplatePage from "../pages/templates/ManageTemplatePage";
import EmployeesInvitationsView from "../pages/employees/invitations/EmployeesInvitationsView";
import NotificationLogPage from "../pages/NotificationLogPage";

const companyRoutes = [
    {
        path: companyRoutesPaths.COMPANY_VIEW,
        element: <AppLayout/>,
        children:[
            {
                path: companyRoutesPaths.COMPANY_EMPLOYEES,
                element: <CompanyEmployeesView/>,
            },
            {
                path: companyRoutesPaths.COMPANY_EMPLOYEE_DETAIL,
                element: <CompanyEmployeeDetailsView/>
            },
            {
                path: companyRoutesPaths.COMPANY_TEMPLATES,
                element: <ManageTemplatePage/>,
            },
            {
                path: companyRoutesPaths.COMPANY_LOGS,
                element: <NotificationLogPage/>,
            },
            {
                path: companyRoutesPaths.COMPANY_INVITATIONS,
                element: <EmployeesInvitationsView/>
            }
        ]
    },
];

export default companyRoutes;
import React, {useEffect, useState} from 'react';
import {getLabel, IntLabel} from "@app/i18n/src/components/IntLabel";

interface LandPartFormParams {
    onSubmit?: any;
    onCancelClick?: any;
    isLoading?: boolean;
    landPart?: any;
}

function LandPartForm(props: LandPartFormParams) {
    const [landPart, setLandPart] = useState({
        bloc: undefined,
        partNumber: undefined,
        area: 0
    })

    useEffect(() => {
        if (props.landPart !== undefined) {
            setLandPart((p: any) => ({...p, utmCoordinates: []}))
            setTimeout(() => setLandPart(props.landPart), 5);
        }

        return () => {};
    }, [props.landPart]);


    return (
        <form className="form-row"
              onSubmit={(e) => {
                  e.preventDefault()
                  props?.onSubmit(landPart)
              }}>
            <div className="row mb-6">
                <div className="col-12">
                    <label>{getLabel("text.bloc")}</label>
                    <input
                        className="form-control"
                        required={true}
                        onChange={(e ) => setLandPart((p: any) => ({...p, bloc: e.target.value}))}
                        value={landPart?.bloc}
                    />
                </div>
                <div className="col-12 col-md-6 my-6">
                    <label>{getLabel("text.land.part.number")}</label>
                    <input type="number"
                           className="form-control"
                           required={true}
                           onChange={(e ) => setLandPart((p: any) => ({...p, partNumber: parseFloat(e.target.value)}))}
                           value={landPart?.partNumber}
                    />
                </div>

                <div className="col-12 col-md-6 my-6">
                    <label>{getLabel("text.area")}</label>
                    <input  className="form-control"
                           type="number"
                           required={true}
                           onChange={(e ) => setLandPart((p: any) => ({...p, area: parseFloat(e.target.value)}))}
                            value={landPart?.area}
                    />
                </div>
            </div>
            <div className="row mt-5">
                <div className="form-group col-md-6 mb-6">
                    <button  className="btn  btn-secondary w-100" onClick={props.onCancelClick}>
                        <IntLabel label="text.onCancel"/>
                    </button>
                </div>
                <div className="form-group col-md-6 mb-6" >
                    <button  className="btn btn-primary w-100" disabled={props.isLoading} type="submit">
                        <IntLabel label="text.finish"/>
                    </button>
                </div>
            </div>
        </form>
    );
}

export default LandPartForm;
import {companyRoutesPaths} from "./companyRoutesPaths";

export const companyMenu = [
    {
        domain: 'company',
        title: 'menu.domain.company',
        description: 'menu.domain.company.description',
        children: [
            {
                label: 'menu.domain.company.employees',
                icon: 'windows_menu',
                children: [
                    {
                        label: "menu.domain.company.employees.list",
                        path: companyRoutesPaths.COMPANY_EMPLOYEES,
                        parent: 'company',
                    },
                    // {
                    //     label: "menu.domain.company.employees.invitations",
                    //     path: companyRoutesPaths.COMPANY_INVITATIONS,
                    //     parent: 'company',
                    // },

                ]
            },
            {
                label: 'menu.domain.company.notifications',
                icon: 'template',
                children: [
                    {
                        label: "menu.domain.company.templates",
                        path: companyRoutesPaths.COMPANY_TEMPLATES,
                        parent: 'company',
                    },
                    {
                        label: "menu.domain.company.logs",
                        path: companyRoutesPaths.COMPANY_LOGS,
                        parent: 'company',
                    },
                ]
            },
        ],
    },
] as any;
import {formatCurrency, formatNumber} from "@app/core/src/service/formatService";
import {IntlDate} from "@app/i18n";
import React from "react";

export const validateColumn = [
    {
        title: "text.creator",
        render: (item: any) => item.user?.name
    },
    {
        title: "text.land.title",
        render: (item: any) => <strong>{item.landTitle}</strong>
    },
    {
        title: "land.part",
        align: 'right',
        render: (item: any) => `${item.parts ?? 0} Lot(s)`
    },
    {
        title: "text.area",
        align: 'right',
        render: (item: any) => <div>{formatNumber(item.area)} m<sup>2</sup></div>
    },
    {
        title: "text.square.price",
        align: 'right',
        render: (item: any) => formatCurrency(item.squarePrice)
    },
    {
        title: "text.amount",
        align: 'right',
        render: (item: any) => formatCurrency(item.amount)
    },
    {
        title: "text.date",
        align: 'right',
        render: (item: any) => <IntlDate date={item.createdAt}/>
    },
]
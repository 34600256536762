import {useSelector} from "react-redux";


export function useCompanySecurity() {
    const {access} = useSelector((state: any) => state.appSlice)

    function canAccess(features: any): boolean {
        try {
            if (access === undefined) {
                return false
            }

            if (Array.isArray(features)) {
                const hasCommonElement = access.some((item: string) => features.includes(item));

                if (hasCommonElement) {
                    return true
                }
            }

            if (access.includes(features)) {
                return true
            }
        } catch {
            return false
        }

        return false
    }

    return {canAccess, access}
}
import React from 'react';

export default function AppFooter() {

    return(
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container-fluid d-flex flex-column flex-md-row flex-stack">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-gray-400 fw-bold me-1">Copyright by</span>
                    <a href="#" target="_blank" className="text-muted text-hover-primary fw-bold me-2 fs-6">Eden It systems</a>
                </div>
            </div>
        </div>
    )
}
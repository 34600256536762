import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

interface Modal {
    type: string;
    modalProps: any;
    visible: boolean;
}

interface ModalState {
    modals: Modal[];
}

const initialState: ModalState = {
    modals: [],
};

const MODAL_ANIMATION_DELAY = 300;

export const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        showModal: (state, action: PayloadAction<{ type: string; modalProps: any }>) => {
            const { type, modalProps } = action.payload;
            state.modals.push({ type, modalProps, visible: true });
        },
        hideModal: (state, action: PayloadAction<string>) => {
            const modalType = action.payload;

            state.modals = state.modals.filter((e) => e.type !== modalType);
        },
        hideAllModals: (state) => {
            state.modals = [];
        },
    },
});

export const useModal = () => {
    const dispatch = useDispatch();
    const modals = useSelector((state: any) => state.modalSlice);

    const showModal = (type: string, modalProps: any) =>
        dispatch(modalSlice.actions.showModal({ type, modalProps }));

    const hideModal = (modalType: string) =>
        dispatch(modalSlice.actions.hideModal(modalType));

    const hideAllModals = () => dispatch(modalSlice.actions.hideAllModals());

    return { modals, showModal, hideModal, hideAllModals };
};

export default modalSlice.reducer;
import { createContext } from 'react';

import { Language } from '../languages';

interface IntlContextProps {
    lang: string,
    toggleLang: any
}

const IntlContext = createContext<IntlContextProps>({
    lang: Language.FRA,
    toggleLang: () => {},
});

export default IntlContext;

import React from "react";
import EmployeeDetailsOverview from "./components/EmployeeDetailsOverview";
import {useModal} from "@app/core/src/modals";
import {IntlDate} from "@app/i18n";
import {PermissionAccessBadge} from "@app/core/src/components/Badges";

function EmployeeDetails({employee}: any){
    const {showModal} = useModal()

    return(
        <EmployeeDetailsOverview
            label={"text.detailed.infos"}
            actionButtons={[
                {
                    label: 'btn.edit',
                    color: 'primary',
                    onClick: () => showModal(
                        'EDIT_COMPANY_EMPLOYEE_MODAL',
                        {
                            id: employee?.id
                        }
                    )
                }]}
            details={[
                {
                    label: "text.complete.name",
                    value: (employee?.userName)?.normalize()
                },
                {
                    label: "text.added.at",
                    value: <IntlDate date={employee?.createdAt}/>,
                },
            ]}
            access={<div>
                {employee?.access?.map((item: any, index: number) =>
                    <PermissionAccessBadge className="m-2"  key={index} status={item}/>)}
            </div>}
        />
)}

export default EmployeeDetails;
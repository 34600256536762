import React, {useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import {ModalProps} from "../ModalContainer";
import {getLabel} from "@app/i18n/src/components/IntLabel";

function AppValidationModal(props: ModalProps) {
    const {title, onDeclined, onValidate, content} = props.modalProps;

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {getLabel(title ?? "confirmation.modal.title")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 my-3">
                        <span className="fs-4">
                            {content ?? getLabel( "confirmation.modal.message")}
                        </span>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="row">
                            <div className="col-6">
                                <button onClick={onDeclined} className="btn btn-sm btn-danger w-100">
                                    {getLabel("btn.declined")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button onClick={onValidate} className="btn btn-sm btn-primary w-100">
                                    {getLabel("btn.validate")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AppValidationModal;
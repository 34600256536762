import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {ModalProps} from "../../ModalContainer";
import {IntLabel} from "@app/i18n";
import {PermissionAccess} from "../../../interface/PermissionAccess";
import useUserApi from "../../controllers/useUserApi";
import {fetchUsers} from "../../../api/domain/admin/admin";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import Select from 'react-select';
import AppAsyncSelect from "../../../components/AsyncSelect/AppAsyncSelect";

export interface CompanyEmployeeInterface {
    firstName?: string,
    lastName?: string,
    userName?: any,
    access: PermissionAccess[],
    userId?: any,
    isOut?: any,
    isOwner?: any,
}

function AddCompanyEmployeeModal(props: ModalProps){
    const {user, setUser, addEmployee, loading, error, setError} = useUserApi();
    const [options, setOptions] = useState<any[]>([])

    useEffect(() => {
        setError(undefined);

        Object.values(PermissionAccess).map((item: string)=> {
            setOptions((prevState: any) => [...prevState, {value: item, label: getLabel(`text.enum.${item}`)}])
        })
    }, []);

    return(
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props?.modalProps?.title ?? "Modal title"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="pt-3 pb-3">
                    <div className="row gy-6">
                        <div style={{display: (error !== undefined) ? 'block' : 'none', fontSize: "1.3rem", width: "80%", marginLeft: '10%'}}>
                            <div className="text-center rounded-1 text-white" style={{backgroundColor: "#FC7C8BFF"}}>{error}</div>
                        </div>
                        <div className="col-md-12">
                            <AppAsyncSelect
                                fetchData={fetchUsers}
                                onChange={(e: any) => setUser((prev: any) => ({...prev, userId: e.value, firstName: e.label}))}
                                parsing={(data: any) => {
                                    return {label: `${data.name}`, value: data.id}
                                }}/>
                        </div>
                        <div className="col-12">
                            <Select
                                closeMenuOnSelect={false}
                                isMulti={true}
                                placeholder={"Lister des access"} name='access' required={true}
                                value={user?.access?.map((item: any) => ({
                                    value: item,
                                    label: getLabel(`text.enum.${item}`)
                                }))}
                                options={options}
                                onChange={(e: any) => setUser((prev: any) => ({
                                    ...prev,
                                    access: e.map((item: any) => item.value)
                                }))}
                            />
                        </div>
                        <div className="col-12 col-md-6 mt-12">
                            <button className={"btn w-100 btn-secondary border border-none"} disabled={false} onClick={(e: any) => props.onClose()}>
                                <IntLabel label={'text.cancel'}/>
                            </button>
                        </div>
                        <div className="col-12 col-md-6 mt-12">
                            <button type="submit" disabled={loading} onClick={() => addEmployee(user)}
                                    className={`btn w-100  ${loading ? 'btn-outline-primary' : 'btn-primary'}`}>
                                {loading ? <IntLabel label="text.loading"/> : <IntLabel label="text.save"/>}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCompanyEmployeeModal;
import AddContractModal from "./AddContractModal";
import AddLandModal from "./AddLandModal";
import EditLandModal from "./EditLandModal";
import AddLandPartModal from "./AddLandPartModal";
import EditLandPartModal from "./EditLandPartModal";
import AddLandPaymentModal from "./AddLandPaymentModal";
import EditLandPaymentModal from "./EditLandPaymentModal";

export const landModal = {
    'ADD_CONTRACT_MODAL': AddContractModal,
    'ADD_LAND_MODAL': AddLandModal,
    'EDIT_LAND_MODAL':  EditLandModal,
    'EDIT_LAND_PART_MODAL':  EditLandPartModal,
    'ADD_LAND_PART_MODAL':  AddLandPartModal,
    'ADD_LAND_PAYMENT_MODAL':  AddLandPaymentModal,
    'EDIT_LAND_PAYMENT_MODAL':  EditLandPaymentModal,
}
import React, {useState} from "react";
import {IntLabel} from "@app/i18n";
import {useDispatch} from "react-redux";
import {companyLogin} from "@app/core/src/api";
import LocalStorage from "@app/core/src/api/axios/localStorage";
import {onLoginSuccess} from "../../../../../application/appStore";
import axios from "axios";
import {landRoutePaths} from "../../../land/routing/landRoutePaths";
import {Link} from "react-router-dom";
import {securityRoutesPaths} from "../../routing/securityRoutesPaths";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {Tab, Tabs} from "react-bootstrap";

interface CredentialsProps {
    phoneNumber?: any,
    email?: any,
    password: any,
}

export default function AppLoginPage(){
    const dispatch = useDispatch()
    const [key, setKey] = useState('phoneNumber')
    const [credentials, setCredentials] = useState<CredentialsProps>({
        email: undefined,
        phoneNumber: undefined,
        password: undefined,
    })
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const onFinish = () => {
        setError('')
        setLoading(true)
        companyLogin({
            username: credentials.email ?? credentials.phoneNumber,
            password: credentials.password,
        }).then((res: any) =>  {
            if (res !== undefined) {
                LocalStorage.setAuthToken(res.token)
                LocalStorage.setAppContext(res.data)
                dispatch(onLoginSuccess(res.data))
                axios.defaults.headers.common = {
                    'Authorization': `Bearer ${LocalStorage.getAuthToken()}`,
                };

                window.location.href = res.data.currentCompany ?  landRoutePaths.LAND_VIEW : appPaths.APP
            }
            setLoading(false)
        }).catch((res: any) => {
            setLoading(false)
            res !== undefined && setError(res.message)
        })
    }

    return(
        <div className="container">
            <div className="row vh-100">
               <div className="col-12 col-md-6 col-lg-4 m-auto">
                   <div  className="card rounded p-3 w-100 shadow-sm rounded-2">
                       <form onSubmit={(e) => {
                           e.preventDefault()
                           onFinish()
                       }}>
                           <div className="row card-security-container p-5">
                               <h1 className="text-center text-dark fw-bold fs-1 pt-5 mb-10 fw-bolder">
                                   <IntLabel label={'text.connexion'}/>
                               </h1>

                               {error !== '' && <div className="form-group col-12 my-3">
                                   <div className="alert alert-danger">
                                       {error}
                                   </div>
                               </div>}

                               <Tabs
                                   id="controlled-tab-example"
                                   activeKey={key}
                                   onSelect={(k: any) => setKey(k)}
                                   className="mb-3"
                               >
                                   <Tab eventKey="phoneNumber" title="Numero de telephone">
                                       <div className="form-group col-12 my-3">
                                           <div className="input-group mb-3">
                                               <div className="input-group-prepend">
                                                   <span className="input-group-text" id="basic-addon1">+237</span>
                                               </div>
                                               <input type="number" value={credentials.phoneNumber?.replace('+237-', '')} className="form-control" id="text.username"
                                                      placeholder="6******"
                                                      onChange={(e: any) => setCredentials(
                                                          (prev: CredentialsProps) => ({
                                                              ...prev,
                                                              phoneNumber: '+237-' + e.target.value,
                                                              email: undefined,
                                                          }))}
                                               />
                                           </div>
                                       </div>
                                   </Tab>
                                   <Tab eventKey="email" title="Adresse email">
                                       <div className="form-group col-12 my-3">
                                           <input type="text" value={credentials?.email} className="form-control" id="text.username"
                                                  placeholder="email@email.com"
                                                  onChange={(e: any) => setCredentials(
                                                      (prev: CredentialsProps) => ({
                                                          ...prev,
                                                          email: e.target.value,
                                                          phoneNumber: undefined,
                                                      }))}
                                           />
                                       </div>
                                   </Tab>
                               </Tabs>

                               <div className="form-group col-12 my-5">
                                   <label htmlFor="text.password"><IntLabel label="text.password"/></label>
                                   <input type="password" value={credentials.password} className="form-control" id="text.password"
                                          onChange={(e: any) => setCredentials(
                                              (prev: CredentialsProps) => ({...prev, password: e.target.value}))}
                                          placeholder="********" />
                               </div>
                               {/*<div className="form-check col-12 my-3">*/}
                               {/*    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>*/}
                               {/*    <label className="form-check-label" htmlFor="exampleCheck1">Check me*/}
                               {/*        out</label>*/}
                               {/*</div>*/}
                               <div className="col-12 my-5">
                                   <button type="submit" disabled={loading}
                                           className={`btn w-100  ${loading ? 'btn-outline-primary' : 'btn-primary'}`}>
                                       {loading ? <IntLabel label="text.loading"/> : <IntLabel label="text.connexion"/>}
                                   </button>
                               </div>
                               <div className="col-12 text-center" style={{ marginTop: '20px' }}>
                                   <IntLabel label="text.not.has.account"/>
                                   <Link to={securityRoutesPaths.REGISTER_PAGE}>
                                       <IntLabel label="text.register.question"/>
                                   </Link>
                               </div>
                           </div>
                       </form>
                   </div>
               </div>
            </div>
        </div>
    )
}
const TextLabelsFra = {
    "text.real.estate":"Agent immobilier",
    "text.space.pro":"Espace pro",
    "text.login": "Connexion",
    "text.newsletter":"Newsletter",
    'text.follow_us':'Suivez-nous',
    'text.call_us':'Applez-nous',
    'text.page_not_found':"Page Introuvable!",
    "text.phone_number": "Telephones",
    "text.address_number": "Localisation",
    "text.email_address": "E-mail",
    "text.creation.date": "Date de création",
    "text.success.operation": "Opération effectuée",
    "text.error.operation": "L'opération a echoué",
    "text.choose.element": "Selectionner",
    "text.choose.sorting": "Trier par",
    "text.late.month": "Mois de retard",
    "text.advanced.month": "Mois d'avance",
    "text.months": "Mois",
    "text.month.amount": "Montant mensuel",

    "text.search.more": "Voir plus",
    "text.no.more.data": "Aucun element à charger",
    "text.loading": "Chargement...",
    "text.price": "Prix",
    "text.booked.land": "Reserver le terrain",
    "text.summary": "Montant total",
    "text.land.selection": "Votre selection de terrains",

    "text.sold": "Vendu",
    "text.add.land": "Ajoutez le terrain",
    "text.location": "Selon la zone",
    "text.budget": "Selon le budget",
    "text.mesures": "Selon la Surface",
    "text.rooms": "Nombre de pieces blablablabla",

    "text.my.profile": "Mon profile",
    "land.part": "Lot(s)",
    "text.lands": "Terrains",
    "text.company": "Entreprise",
    "text.contract.amount": "Montant du contrat",
    "text.remained.amount": "Montant restant",
    "text.paid.amount": "Montant versé",
    "land.part.available": "Lot(s) disponible(s)",
    "land.has.parts": "Terrains lotis",
    "text.details": "Détails",
    "text.land.parts": "Lots",
    "text.contracts": "Contrats",
    "text.medias": "Média",
    "text.interests": "Intérets",
    "text.views": "vues",
    "text.payment.lists": "Listes des paiements",
    "text.contract.interest.lists": "Listes des pénalités",
    "contract.interests.amount": "Montant des pénalités",

    // menu
    "text.home": "Acceuil",
    "text.save": "Enregistrer",
    "text.not.has.account": "Vous n'avez pas encore de compte ? ",
    "text.has.account": "Vous avez déjà un compte ? ",
    "text.land.catalog": "Catalogue de terrains",
    "text.connexion": "Se connecter",
    "text.sign.in": "Connectez-vous",
    "text.register": "Creer votre compte",
    "text.connexion.question": "Connectez-vous",
    "text.register.question": "Creer votre compte",

    "text.firstName": "Nom(s)",
    "text.lastName": "Prénom(s)",
    "text.password": "Entrez le nouveau mot de passe",
    "text.old.password": "Entrez votre ancien mot de passe",
    "text.password.confirm": "Confirmer votre nouveau mot de passe",
    "text.password.forgot": "Mot de passe oublié ?",
    "text.email": "Adresse email",
    "text.email.notice": "",
    "text.phoneNumber": "Numero de téléphone",
    "text.username": "Vos identificants",
    "text.authenticator": "Indentifiants",

    "text.username.notice": "Vos identificants peuvent etre votre email ou votre numero de telephone",
    "text.login.question": "Connexion",
    "text.password.notice":"Choisir un mot de passe robuste",

    // advices and announcements
    "text.advices": "Conseils et avis",
    "text.announcements": "Informations et Annonces",
    "text.all": "Tous les posts",
    "text.tops": "Les plus populaires",
    "text.all.articles": "Tous les articles",
    "text.land.information": "Informations foncière",
    "text.land.title.information": "Titre fonciers",

    //admin
    "text.date":" Date ",
    "text.amount":" Montant",
    "text.payment.intent":"Motif",
    "text.payment.method" :" Mode de paiement",
    "text.contract":"Contrat",
    "text.status":"Statut ",
    "text.printed":"Imprimer",
    "text.privilege": "Prérogatives",
    "text.edit": "Modifier",

    "text.profile.password.change.description": "Cher utilisateur, <br/> <br/>" +
        "Nous vous remercions pour votre fidélité à notre plateforme. Afin de garantir <br/>" +
        "la sécurité de votre compte, nous vous recommandons vivement de changer <br/>" +
        "régulièrement votre mot de passe.<br/> Cela permettra de protéger " +
        "vos données personnelles et de prévenir toute tentative de piratage." +
        "Pour changer votre mot de passe, veuillez suivre les étapes suivantes :<br/><br/>" +
        "1. Entrez votre ancien mot de passe et le nouveau mot de passe que vous souhaitez utiliser<br/>" +
        "2. Confirmez votre nouveau mot de passe en le saisissant une seconde fois<br/>" +
        "3. Enregistrez les modifications<br/><br/>" ,

    //contracts
    "contract.reference": "Référence du contrat",
    "contract.company": "Société affiliée",
    "contracts.count":"Nombre total / Contrat en retard",
    "contracts.value": "Montant total",
    "contracts.paid": "Montant payé",
    "contracts.remain": "Montant restant",
    "contract.amount":" Montant du contrat",
    "contract.amount.cumulated":"Montant cumulé",
    "text.contractor":"Client",
    "contract.remain.month": " Mois payé sur ",
    "contract.month.pay": " Mois déjà payé ",
    "text.due.amount": "Dette total",
    "text.contract.late.month": "Mois de retard",
    "text.contract.month.amount": "Montant mensuel",
    "text.creator": "Intiateur",
    "text.info": "Détails",
    "contract.contractor": "Client",
    "contract.creator": "Intiateur",
    "contract.next.payment": "Delais de payement",
    "contract.amount.remain": " Montant restant",
    "contract.penalties": "Frais de pénalité",
    "contract.information": "Informations du contrat",
    "text.land.part.number": "Numéro de lot",
    "contract.period": "Periode",
    "contract.start": "Date de signature",
    "text.edit.contractor.label": "Modifier",
    "text.payments": "Paiements",
    "text.parts": "Lots",
    "text.action": "Action posé",
    "text.logs": "Journal d'utilisations",
    "text.contract.start.date": "Début du contrat",

    "text.add.company": "Creez votre entreprise",
    "text.add.company.description": "Lorsque vous créez une entreprise, vous devez fournir des in" +
        "formations telles que le nom de l'entreprise, l'adresse, le numéro de téléphone et le domaine d'activité. ",

    //land
    "text.land.title":"Titre fonciers",
    "land.information":" Description du terrain ",
    "land.part.count":"Nombre de lots",
    "land.square.price":"Prix du mètre carré",
    "land.area":"Superficie du terrain",
    "land.paid.amount":"Montant versé",
    "land.values":"Valeur du terrain",
    "land.count":"Nombre de terrain",
    "land.value":"Montant total",
    "land.paid":"Montant payé",
    "update.date": "Dernière mise  à jour",
    "text.area": "Superficie",
    "text.published":"Publié",
    "text.label.description":"Description",
    "text.label.Landtitle":"Numero d'immatriculation",
    "text.label.title":"Titre",
    "text.label.location": "Localité",
    "text.add.land.label": "Nouveau Terrain",
    "text.label.landvalue":" Montant du terrain",
    "text.label.square.price":"Prix du mêtre carrre",
    "text.finish":"Valider",
    "text.onCancel":"Annuler",
    "text.edit.land.label":"Modifier",
    "text.bloc":"Bloc(s)",
    "text.number":"Quandité",
    "text.square.price":"Mètre carré ",
    "text.sold.percent":"Vente (%)",
    "text.payment.status":"Statut de paiement",
    "text.add.land.payment.label": "Ajouter un paiement sur terrain",

    "text.label.land.title": "Titre foncier",
    "text.title": "Institulé",
    "text.label.town": "Localisation",
    "text.label.visit.price": "Frais visite immobilier",
    "text.label.latitude": "Latitude",
    "text.label.longitude": "Longitude",
    "text.label.axisX": "Coordonnée horizontale (X)",
    "text.label.axisY": "Coordonnée verticale (Y)",

  //payment
    "text.label.payment.information": "Informations du paiement",
    "text.label.payment.contact": "Numero de paiement",
    "text.label.payment.method": "Méthode de paiement",
    "text.label.payment.amount": "Montant du paiement",

    "payment.bank_transfer": "transfert banquaire",
    "payment.cash": "paiment cash",

    //status
    'text.ongoing': "En cours",
    'text.draft': "Brouillon",
    'text.rejected': "Rejeté(e)",
    'text.finished': "Terminé(e)",
    "text.validate":"info",
    "payment.count":" Nombre de transactions",
    "payment.value":"Montant total",
    "text.author":"Auteur",
    "text.enter.quarter.for.search":"Entrez le nom d'un quartier..",

    //Company
    "text.actions":"Permissions",
    "text.cancel":"Retour",
    "text.delete":"Supprimé",
    "text.generate":"Génerer",
    "text.copied":"Copié",
    "text.expired.at":"Date d'expiration",
    "text.designation":"Désignation",

    //==== payment
    "text.generate.payment.link": "Genérer un lien de paiement",
    "text.payment.link.placeholder": "Lien de paiemet de charles",
    "text.payment.link.not.found": "Le liens de paiement n'a pas été trouvée",
    "text.payment.link.notice.title": "Comment genérer un lien de paiement",
    "text.confirm.payment.link.deletion": "Voulez vous vraiment supprimer ce lien de paiement ?",
    "text.payment.link.notice": "Genérer un lien de paiement et envoyé à vos proches pour valider votre payment",


    "text.pm.orange.money": "Orange money",
    "text.pm.orange.money.info.title": "Comment payer par Orange money",
    "text.pm.orange.money.info.description": "Entrez le montant que vous" +
        " compter payer dans le champ nommer 'montant' <br/>" +
        "- Vous serrez rediriger vers une page de paiement<br/>" +
        "- Suivez les indication de la plateforme pour procceder aux payment en toute securité<br/>"
    ,
    "text.pm.mobile.money": "MTN Mobile money",
    "text.pm.mobile.money.info.title": "Bientot disponible",
    "text.pm.mobile.money.info.description": "Vous pouriez desormais paye avec l'operateur MTN CAMEROUN bientot",
    "text.decided": "Decision",
    "text.declined": "Decliner",
    "text.contact": "Contact",

    "text.pm.card.transfer": "Carte bancaire",
    "text.pm.card.transfer.info.title": "Bientot disponible",
    "text.pm.card.transfer.info.description": "Vous pouriez desormais paye avec votre carte bancaire  internationnal",

    // ==== profile
    "text.profile.information.update": "Lire avant de modidier vos informations",
    "text.profile.information.update.description": "Votre email et votre numero de téléphone sera utilisés pour vous connectez",


    //------------------Invitation
    "invitation.date.created":" Date de creation",
    "invitation.recipient":"Destinnataire",
    "invitation.recipient.email":"Email",
    "text.satus":" Statut",
    "text.add.contractor.label":"Nouveau contrat",
    "text.invitations.all":"Genérale",
    "text.invitations.user":" Utilisateurs",
    "text.invitation.details":"Informations",
    "text.land":"Terrain",
    "text.hotel":" Hotel",
    "text.real_estate": " Bien immobilier ",

    "section.about.us": "A propos de nous",
    "section.about.us.description": "",
    "text.user.name": "Nom d'utilisateur",

    //====  select
    "text.select.element": "Selectionner un élément ",
    "text.select.date": "Selectionner une date",
    "text.export.excel.data": "Exportez les données en excell",
    "text.export.pdf.data": "Exportez les données en pdf",
    "text.export.data": "Exportez vos données",
    "text.sort.by.created.date.desc": "Classez par date décroissante",
    "text.sort.by.created.date.asc": "Classez par date croissante",
    "text.sort.by.highest.payer": "Classez par client non retardataire",
    "text.sort.by.lowest.payer": "Classez par client retardataire",

    "text.choose.lang": 'Langue',
    'text.filter.status': "Filtrer par statut",

    //card
    "total.interest.count": "Décompte des intérêts",
    "total.paid.interest.count": "Intérêts payés",
    "total.interest.amount": "Montant des intérêts",
    "total.paid.interest.amount": "Montant intérêts payés",
    "text.invitations.list": "Liste des invitations",

    'text.register.new.company': "Enregistrer une nouvelle entreprise",


    //contractor
    'text.contract.number': "Numéro du contrat",
    'contract.interest.percent': "Interet composé",
    "text.contractor.name": "Nom du Client",
    "text.contractor.address": "Addresse du Client",
    'contractor.count': "Nombre de contrats",
    "contractor.total.paid": "Montant total",

    'transaction.count': 'Nombre d\'operation',
    'transaction.debit': 'Decaissement',
    'transaction.credit': 'Encaissement',
    'transaction.balance': 'Solde',

    //templates
    'text.templates.models': "Modèles de documents",
    'text.roles': "Roles",
    'text.infos': "Informations",
    'text.complete.name': "Nom complet",
    'text.working.company': "Entreprise",
    'text.created.at': "Date de creation",
    'text.added.at': "Date d'ajout",
    'text.detailed.infos': "Informations detaillees",
    'text.edit.infos': "Modifier les informations",

    // transaction
    'text.transaction.category': 'Pole de dépense',
    'text.transaction.partner': 'Partenaire',
    'text.beneficiary': 'Beneficiaire',
    'text.transaction.user': 'Utilisateur',
    'text.transaction.description': 'Motif de l\'opération',
    'text.transaction.type': 'Type de l\'operation',
    'text.description': 'Description',
    'text.partner': 'Partnenaires',
    'text.contract.count': 'Nombre de contract',
    'text.name': 'Nom',
}



export default TextLabelsFra;
import React, {useEffect, useState} from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import AppTable, {ColumnsProps} from "./index";
import {defaultListData} from "../../../interface/ListDataInterface";

export function listParser (tableParams: {limit: number, offset: number},  query?: any): any {
    let objectQuery : any = {
        'page[limit]': tableParams.limit,
        'page[offset]': tableParams.offset,
    }

    query !== undefined && query !== '' ? objectQuery.q = query : null;
    return objectQuery;
}

export interface ListTableProps {
    fetchData: any,
    columns: ColumnsProps[],
    addQuery?: any;
    addParam?: any;
    setClear?: any;
    clear?: any;
    refresh?: any
    children?: any
    withShadow?: boolean,
    searchWith?: any
}

export function parseData(
    props: ListTableProps,
    paginate: { offset: number; limit: number },
    filter: string | undefined,
    query: string | undefined
) {
    return props.addQuery === undefined ?
        listParser(paginate, filter ?? query)
        : {...listParser(paginate, filter ?? query), ...props.addQuery};
}

function ListTable(props: ListTableProps) {
    const [query, setQuery] = useState<string | undefined>()
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(defaultListData());
    const [page, setPage] = useState(1);

    useEffect(() => {
        loadData()
    }, [page, props.addQuery, props.refresh, query])

    const loadData = (filter?: string) => {
        setLoading(true)
        let paginate: any = {limit: data?.meta?.pagination.limit, offset: page}
        let callBack = props.addParam === undefined ?
            props.fetchData(parseData(props, paginate, filter, query)) :
            props.fetchData(props.addParam, parseData(props, paginate, filter, query))

        callBack.then((res: any) => {
            setData(res)
            setLoading(false)
        }).catch(() => setLoading(false))
    }

    return (
        <div className="row">
            <div className="col-12 mb-5">
                <div className={`card ${props.withShadow ?? 'shadow-sm'} p-3`}>
                    <div className="row">
                        <div className="col-12 col-md-3 mb-md-0 mb-5">
                            <input onChange={(e) => setQuery(e.target.value)} value={query}
                                   className="form-control form-control-sm" placeholder="Recherche ..."
                            />
                        </div>
                        <div className="col-12 col-md-9">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-5">
                <div className={`card ${props.withShadow ?? 'shadow-sm'} p-5`}>
                    <AppTable data={data?.data} isLoading={isLoading} columns={props.columns}/>
                </div>
            </div>
            <div className="col-12 col-md-6 mt-5">
                Total {data?.meta?.pagination.total}
            </div>
            <div className="col-12 col-md-6 mt-5">
                <PaginationControl
                    page={page}
                    between={4}
                    total={data?.meta?.pagination.total}
                    limit={data?.meta?.pagination.limit}
                    changePage={(page) => setPage(page)}
                    ellipsis={1}
                />
            </div>
        </div>
    );
}

export default ListTable;
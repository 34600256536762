import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {ModalProps} from "../../ModalContainer";
import AsyncSelect from "react-select/async";
import {IntLabel} from "@app/i18n";
import {getLabel} from "@app/i18n/src/components/IntLabel";

function AddCompanyModal(props: ModalProps){
    const {lang} = props.modalProps
    const [company, setCompany] = useState<any>()
    const [localLang, setLocaLang]=useState<any>("")
    const countryIsoCodes = [{label: 'Cameroun', value: 'CM'}, {label: 'United States', value: 'US'}, {label: 'France', value: 'FR'}]
    const currencyIsoCodes = [{label: 'Euro (€)', value: 'euro'}, {label: 'Dollard ($)', value: 'dollard'},
        {label: 'Francs CFA', value: 'XAF'}, {label: 'Livre Sterling (£)', value: 'livre sterling'}]

    const locals = [{label: 'Francais', value: 'fr'},{label: 'English', value: 'en'},{label: 'Espagnol', value: 'es'},
        {label: 'Deutsh', value: 'ge'},{label: 'Arabic', value: 'ar'},{label: 'Japanese', value: 'ja'}]

    const onAddCompany = (values: any) => {
        // new Promise<any>((resolve) => {
        //   resolve(setCompany((c: any) => ({...c, locale: localLang+"-"+c?.countryIsoCode})))
        // }).then(()=>{
        //     new Promise<any>((resolve) => {
        //         resolve(setCompany((c: any) => ({...c, timeZone: (new Intl.Locale(c?.locale)).timeZones[0]})))
        //     }).then(()=>{
        //         addCompany({...values, timeFormat: "yyyy mm dd"})
        //             .then((id: any)=>{
        //                 console.log(id)
        //             }).catch((e:any)=>{
        //             console.log(e)
        //         })
        //     })
        // }).catch((e: any)=> console.log(e.toString()))

    }

    const filterOptions = (inputValue: string, options: any[]) => {
        return options.filter((i) =>
            i.label?.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            setTimeout(() => {
                resolve(filterOptions(inputValue, countryIsoCodes));
            }, 1000);
        });

    const promiseCurrency = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            setTimeout(() => {
                resolve(filterOptions(inputValue, currencyIsoCodes));
            }, 1000);
        });

    const promiseLocales = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            setTimeout(() => {
                resolve(filterOptions(inputValue, locals));
            }, 1000);
    });

    return(
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size={'xl'}
        >
            <Modal.Header closeButton>
                <Modal.Title>{getLabel('text.company.acronym', lang)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="row mx-4 gy-sm-6">
                        <div className="col-md-6 col-12">
                            <div className="row gy-6">
                                <div className="col-md-6 col-12">
                                    <input type="text" name="name" className="form-control" placeholder={getLabel('text.company.name', lang)}
                                           defaultValue={company?.name ?? ""}
                                           onChange={(e: any) => setCompany((c: any) => ({...c, name: e.target.value})) }
                                    />
                                </div>
                                <div className="col-md-6 col-12">
                                    <input type="text" name="acronym" className="form-control" placeholder={getLabel('text.company.acronym', lang)}
                                           defaultValue={company?.acronym ?? ""}
                                           onChange={(e: any) => setCompany((c: any) => ({...c, acronym: e.target.value})) }
                                    />
                                </div>
                                <div className="col-12">
                                    <input type="text" name="address" className="form-control" placeholder={getLabel('text.company.head.office.address', lang)}
                                           defaultValue={company?.address ?? ""}
                                           onChange={(e: any) => setCompany((c: any) => ({...c, address: e.target.value})) }
                                    />
                                </div>

                                <div className="col-12">
                                    <input type="email" name="email" className="form-control" placeholder={getLabel('text.company.email.address', lang)}
                                           defaultValue={company?.email ?? ""}
                                           onChange={(e: any) => setCompany((c: any) => ({...c, email: e.target.value})) }
                                    />
                                </div>
                                <div className="col-12">
                                    <input type="text" name="website" className="form-control" placeholder={getLabel('text.company.website.link', lang)}
                                           defaultValue={company?.website ?? ""}
                                           onChange={(e: any) => setCompany((c: any) => ({...c, website: e.target.value})) }
                                    />
                                </div>
                                <div className="col-md-6 col-12 ">
                                    <input type="tel" name="phone" className="form-control" placeholder={getLabel('text.company.phone.number', lang)}
                                           defaultValue={company?.phoneNumber ?? ""}
                                           onChange={(e: any) => setCompany((c: any) => ({...c, website: e.target.value})) }
                                    />
                                </div>
                                <div className="col-md-6 col-12 ">
                                    <input type="tel" name="phone" className="form-control" placeholder={getLabel('text.company.phone.number', lang)}
                                           defaultValue={company?.phoneNumber ?? ""}
                                           onChange={() => {} }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="row gy-6">
                                <div className="col-md-12 col-12 ">
                                    <AsyncSelect
                                        placeholder={getLabel('text.company.country', lang)}
                                        className="mt-1" options={countryIsoCodes}
                                        styles={{
                                            multiValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "#E1EEF1FF",
                                                color: 'darkblue',
                                                fontSize: "1.3rem",
                                            }),
                                            placeholder: (baseStyle) => ({
                                                ...baseStyle,
                                                color: '#A9ADBDFF',
                                                fontSize: '1.13rem',
                                            }),
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderBlockColor: '#e9ebee',
                                                borderLeftColor: '#e9ebee',
                                                borderRightColor: '#e9ebee',
                                            })

                                        }}

                                        defaultValue={company ? [{label: company?.countryIsoCode, value: company?.countryIsoCode}] : undefined}
                                        loadOptions={promiseOptions}
                                        onChange={(e: any) => setCompany((c: any) => ({...c, countryIsoCode: e.value})) }
                                    />
                                </div>
                                <div className="col-md-6 col-12 ">
                                    <AsyncSelect
                                        placeholder={getLabel('text.company.local.language', lang)}
                                        className="mt-1"
                                        options={locals}
                                        styles={{
                                            multiValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "#E1EEF1FF",
                                                color: 'darkblue',
                                                fontSize: "1.3rem",
                                            }),
                                            placeholder: (baseStyle) => ({
                                                ...baseStyle,
                                                color: '#A9ADBDFF',
                                                fontSize: '1.13rem',
                                            }),
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderBlockColor: '#e9ebee',
                                                borderLeftColor: '#e9ebee',
                                                borderRightColor: '#e9ebee',
                                            })

                                        }}
                                        defaultValue={company ? [{label: company?.locale, value: company?.locale}] : undefined}
                                        loadOptions={promiseLocales}
                                        onChange={(e: any) => setLocaLang((l: any) => e.value) }
                                    />
                                </div>
                                <div className="col-md-6 col-12 ">
                                    <AsyncSelect
                                        placeholder={getLabel('text.currency', lang)}
                                        className="mt-1"
                                        options={currencyIsoCodes}
                                        styles={{
                                            multiValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "#E1EEF1FF",
                                                color: 'darkblue',
                                                fontSize: "1.3rem",
                                            }),
                                            placeholder: (baseStyle) => ({
                                                ...baseStyle,
                                                color: '#A9ADBDFF',
                                                fontSize: '1.13rem',
                                            }),
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderBlockColor: '#e9ebee',
                                                borderLeftColor: '#e9ebee',
                                                borderRightColor: '#e9ebee',
                                            })

                                        }}
                                        defaultValue={company ? [{label: company?.currenncyIsoCode, value: company?.currencyIsoCode}] : undefined}
                                        loadOptions={promiseCurrency}
                                        onChange={(e: any) => setCompany((c: any) => ({...c, currencyIsoCode: e.value})) }
                                    />
                                </div>
                                <div className="col-12">
                                    <input type="text" name="agree" placeholder={getLabel('text.company.aggreement.number', lang)} className="form-control required"
                                           defaultValue={company?.agreementNumber ?? ""}
                                           onChange={(e: any) => setCompany((c: any) => ({...c, agreementNumber: e.target.value})) }
                                    />
                                </div>
                                <div className="col-12">
                                    <textarea name="address" className="form-control" placeholder={getLabel('text.company.about', lang)}
                                              defaultValue={company?.description ?? ""} rows={4}
                                              onChange={(e: any) => setCompany((c: any) => ({...c, description: e.target.value})) }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mt-md-6 mt-4">
                            <button type="submit" disabled={false} onClick={props.onClose} className={"btn w-100 btn-secondary border border-secondary"}>
                                <IntLabel label={'text.cancel'}/>
                            </button>
                        </div>
                        <div className="col-md-6 col-12 mt-md-6 mt-4">
                            <button type="submit" disabled={false} onClick={() => onAddCompany(company)} className={"btn w-100 btn-primary"}>
                                <IntLabel label={'text.save'}/>
                            </button>
                        </div>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    );
    document.querySelector('form')?.addEventListener('submit', (e: any)=>{
        e.preventDefault()
    })

}

export default AddCompanyModal;
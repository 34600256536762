import React from 'react';
import printLink from "../../../../helpers/printHelpers";
import CustomSVG from "../../../icons/svg";
import {IntLabel} from "@app/i18n";

function PrintButton({url}: {url: string}) {

    return (
        <a onClick={() => printLink(url)}
           className="text-primary cursor-pointer d-flex">
            <CustomSVG type="printed"/>&nbsp;<IntLabel label="text.printed"/>
        </a>
    );
}

export default PrintButton;
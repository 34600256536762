import React, {useEffect, useState} from 'react';
import {AsyncTypeahead} from "react-bootstrap-typeahead";

interface AppAsyncSelectProps {
    label?: string,
    searchKey?: string,
    isMulti?: boolean,
    onChange?: any,
    defaultValue?: any,
    fetchData: any,
    addQuery?: any,
    addParam?: any,
    parsing?: any,
}

function AppAsyncSelect(props: AppAsyncSelectProps) {
    const [options, setOptions] = useState<any>([])
    const [isLoading, setLoading] = useState(false)
    const [selected, setSelected] = useState<any[]>([])

    useEffect(() => {
        loadOptions()
        props.defaultValue && setSelected([{label: props.defaultValue ?? ''}])
        return () => {}
    }, [props.defaultValue]);

    const loadOptions = (query?: string) => {
        setLoading(true)
        let filter = query !== undefined ? {
            ...props.addQuery,
            [props.searchKey ?? 'q']: query
        } : props.addQuery
        let callBack = props.addParam === undefined ? props.fetchData(filter) : props.fetchData(props.addParam, filter)

        callBack.then((res: any) => {
            setOptions(() => (res.data ? res.data : res).map(
                (item: any) => props.parsing ?
                    props.parsing(item) :
                    {value: item.id, label: item.name}
            ))
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    return (
        <AsyncTypeahead
            filterBy={() => true}
            onChange={(v: any) => {
                props.onChange(v)
                setSelected(v)
            }}
            useCache={false}
            ignoreDiacritics={true}
            selected={selected}
            id="async-example"
            isLoading={isLoading}
            multiple={props.isMulti}
            onSearch={loadOptions}
            options={options}
            placeholder={props.label}
            renderMenuItemChildren={(option: any) => option.label}
        />
    );
}

export default AppAsyncSelect;
import React, {useEffect, useState} from 'react';
import {appStoreProps, isLoading} from "../../../../../application/appStore";
import {useDispatch, useSelector} from "react-redux";
import {formatCurrency, formatNumber} from "@app/core/src/service/formatService";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {ListTable} from "@app/core";
import {
    declineCompanyContract,
    fetchCompanyContracts,
    fetchCompanyLandParts,
    validateCompanyContract,
} from "@app/core/src/api";
import CustomSVG from "@app/core/src/components/icons/svg";
import {useModal} from "@app/core/src/modals";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import moment from "moment";
import {validationColumn} from "./config";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import toast from "react-hot-toast";

function ModalContractConfirmationInfo(props: {item: any, currentCompany: any}) {
    const [loading, setLoading] = useState(false)
    const [parts, setParts] = useState<any[]>([])

    useEffect(() => {
        setLoading(true)
        fetchCompanyLandParts(
            {companyId: props.currentCompany},
            {'uuid[contract_id]': props.item.id}
        ).then((res: any) => {
            setLoading(false)
            setParts(res.data)
        }).catch(() => setLoading(false))
        return () => {};
    }, []);

    return <ul className="list-group list-group-flush">
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.land.parts")}</div>
                {loading ? 'Recherche en cours...' :
                <div className="row justify-content-around">
                    {parts.map((item, index) =>
                        <div key={index} className="col-12 m-3 btn btn-sm btn-outline-dark">
                            {`Bloc: ${item.bloc} | Numéro de lot: ${item.number} | Superficie: ${formatNumber(item.area)}`} m<sup>2</sup>
                        </div>
                    )}
                </div>}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.contractor")}</div>
                {props.item.contractor?.name}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.contractor")}</div>
                {props.item.contractor?.name}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.land")}</div>
                {props.item.land?.landTitle}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.month.amount")}</div>
                {formatCurrency(props.item.amountPerMonth)}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.square.price")}</div>
                {formatCurrency(props.item.squarePrice)}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.amount")}</div>
                {formatCurrency(props.item.amount)}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.date")}</div>
                <IntlDate date={props.item.createdAt}/>
            </div>
        </li>

    </ul>;
}

function LandContractValidation() {
    const {showModal, hideModal} = useModal()
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const dispatch = useDispatch()

    const columns: any[] = [
        ...validationColumn,
        {
            title: 'text.decided',
            width: '10%',
            align: 'center',
            render: (item: any) => <span
                className="text-primary text-center cursor-pointer"
                onClick={() => showModal(
                    'APP_VALIDATION_MODAL',
                    {
                        content: <ModalContractConfirmationInfo item={item} currentCompany={currentCompany}/>,
                        onValidate: () => onValidate(item),
                        onDeclined: () => onDeclined(item),
                    })
                }
            >
                <CustomSVG type="pencil"/>
            </span>
        },
    ]

    const onValidate = (item: any) => {
        dispatch(isLoading(true))
        validateCompanyContract({
            id: item.id,
            companyId: currentCompany,
        }).then(() => {
            toast.success("text.success.message")
            dispatch(isLoading(false))
            hideModal('APP_VALIDATION_MODAL')
        }).catch((res: any) => {
            toast.error("text.error.message" ?? res.message)
            dispatch(isLoading(false))
        })
    }

    const onDeclined = (item: any) => {
        dispatch(isLoading(true))
        declineCompanyContract({
            id: item.id,
            companyId: currentCompany,
        }).then(() => {
            toast.success("text.success.message")
            dispatch(isLoading(false))
            hideModal('APP_VALIDATION_MODAL')
        }).catch((res: any) => {
            toast.error("text.error.message" ?? res.message)
            dispatch(isLoading(false))
        })
    }

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.contract'},
        ]} title={<IntLabel label="menu.domain.land.contract"/>}>
            <ListTable columns={columns} fetchData={fetchCompanyContracts}
                addQuery={dateRange.startDate === null ? {
                    'order[created_at]': 'DESC',
                    'equal[status]' : 'draft'
                } : {
                    'order[created_at]': 'DESC',
                    'equal[status]' : 'draft',
                    'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                }}
                addParam={{ companyId: currentCompany }}
            >
                <div className="col-md-4 col-12">
                    <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                </div>
            </ListTable>
        </AppContainer>
    );
}

export default LandContractValidation;
import {Outlet} from "react-router-dom";
import React from "react";
import AppSidebar from "../../components/Layout/AppLayout/AppSidebars";
import AppFooter from "../../components/Layout/AppLayout/AppFooter/AppFooter";

function DesktopLayout(props: any) {

    return (
        <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
                <AppSidebar defaultView={props.defaultView} sidebarConfig={[...props.navItemConfig]}/>

                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper"
                     onClick={(e: any) => {
                         let parent = e.target
                         do {
                             parent = parent?.parentNode
                         }
                         while (parent !== null && !parent?.classList?.contains('mobile_toggle_menu')
                                                    && !parent?.classList?.contains('aside'))

                         parent === null ? document.querySelector('.aside')?.
                            classList.contains("drawer-on") &&
                         document.querySelector('.aside')?.
                            classList.remove("drawer", "drawer-start", "drawer-on") :
                         !document.querySelector('.aside')?.
                            classList.contains("drawer-on") &&
                         document.querySelector('.aside')?.
                            classList.add("drawer", "drawer-start", "drawer-on")

                     }}
                >
                    <Outlet/>
                    <AppFooter />
                </div>
            </div>
        </div>
    );
}

export default DesktopLayout;
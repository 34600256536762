import {callAction, callActionWithCustom, callActionWithId} from "../helpers";

export const fetchAllMarketProperty = callAction<any>('/market/api/', 'get', false);
export const fetchMarketLandParts = callActionWithId<any>('/market/api/land-parts/{id}', 'get', false);
export const fetchMarketPropertyDetails = callActionWithCustom<any>('/market/api/{slug}/{id}/details', 'get');

export const fetchAllMarketCompanies = callAction<any>('/market/api/company', 'get', false);
export const fetchMarketCompany = callActionWithId<any>('/market/api/company/{id}', 'get', false);
export const fetchMarketCompanyProperty = callActionWithId<any>('/market/api/company/{id}', 'get', false);

//============ /login
export const marketLogin = callAction<any>('/market/api/login', 'post', false);
export const marketRegister = callAction<any>('/market/api/register', 'post', false);
export const marketResetPassword = callAction<any>('/market/api/reset-password', 'post', false);

//============ /profile
export const fetchProfileInformation = callAction<any>('/market/profile/api/me', 'get', false);
export const updateProfileInformation = callAction<any>('/market/profile/api/update/information', 'patch', false);
export const updateProfilePassword = callAction<any>('/market/profile/api/update/password', 'patch', false);
export const fetchProfileContracts = callAction<any>('/market/profile/api/contracts', 'get', false);
export const fetchProfilePayments = callAction<any>('/market/profile/api/payments', 'get', false);
export const fetchProfileInterests = callAction<any>('/market/profile/api/interests', 'get', false);
export const fetchProfileContractsDetails = callActionWithId<any>('/market/profile/api/contracts/{id}', 'get', false);
export const fetchProfileContractsPayments = callAction<any>('/market/profile/api/payments/{id}/contracts', 'get', false);

// =========== payment link
export const fetchPaymentLinks = callActionWithCustom<any>('/market/profile/api/link/{id}', 'get', false);
export const checkPaymentLink = callActionWithId<any>('/market/api/details/payment/link/{id}', 'get', false);
export const addPaymentLink = callAction<any>('/market/profile/api/link', 'post', false);
export const disablePaymentLink = callActionWithId<any>('/market/profile/api/link/{id}/disable', 'patch', false);

// ====== payment
export const generateOrangeMoneyLink = callAction<any>('/market/payment/api/om/initiate', 'post', false);
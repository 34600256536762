import React, {useEffect, useState} from 'react';
import {fetchPartnerContract, fetchTransactionPartner} from "../../api/domain/company/transaction";
import {getLabel, IntLabel} from "@app/i18n/src/components/IntLabel";

interface FormPartnerParams {
    partnerId?: any;
    onCancel?: any;
    onFinish: any;
}

function FormPartner(props: FormPartnerParams) {
    const [loading, setLoading] = useState(false)
    const [partner, setPartner] = useState<any>({
        name: undefined,
        description: undefined,
    });

    useEffect(() => {
        if (props.partnerId) {
            setLoading(true)
            fetchTransactionPartner(props.partnerId).then((res: any) =>{
                let data = res.data

                setPartner({
                    name: data.name,
                    description: data.description,
                })
                setLoading(false)
            })
        }

    }, [])

    return (<form onSubmit={(e) => {
        e.preventDefault()
        props.onFinish(partner)
    }}>
        <div className="form-row ">
            <div className="row">
                <div className="form-group col-12 my-3">
                    <label>{getLabel("text.name")}</label>
                    <input value={partner.name}
                           required={true}
                           onChange={(e :any )=> setPartner((prev: any) => ({...prev, name: e.target.value}))}
                           className="form-control"
                           placeholder={getLabel('text.name')} />
                </div>
                <div className="form-group col-12 my-3">
                    <label>{getLabel("text.description")}</label>
                    <textarea value={partner.description}
                           required={true}
                           onChange={(e :any )=> setPartner((prev: any) => ({...prev, description: e.target.value}))}
                           className="form-control"
                           placeholder={getLabel('text.description')} />
                </div>
            </div>

            <div className="form-row  mt-5">
                <div className="row">
                    <div className="form-group col-md-6 mb-6">
                        <button  className="btn btn-secondary w-100"
                                 onClick={props.onCancel}><IntLabel label="text.onCancel"/></button>
                    </div>
                    <div className="form-group col-md-6 mb-6">
                        <button type="submit"
                                disabled={loading}
                                className="btn btn-primary w-100">
                            <IntLabel label="text.finish"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>);
}

export default FormPartner;
import React, {useContext} from 'react';
import {enumLabel} from "../interface/Enums";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import IntlContext from "@app/i18n/src/context/IntlContext";

function EnumSelect(props: {enum: any, className?: any, onChange?: (e: any) => void, value?: string, placeholder?: string}) {
    const {lang} = useContext(IntlContext)

    return (
        <select value={props.value} className={"form-select form-select-sm" + props.className} onChange={props.onChange}>
            <option selected value={""}>{getLabel(props.placeholder ?? "text.select.element", lang)}</option>
            {Object.keys(props.enum).map((item: any) =>
                <option key={item} value={item}>
                    {getLabel(enumLabel(props.enum, item), lang)}
                </option>
            )}
        </select>
    );
}

export default EnumSelect;
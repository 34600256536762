import React from 'react';
import {IntLabel} from "@app/i18n";

export interface AppCardValueDisplayProps {
    value: any;
    label: string;
    loading?: boolean
}

export default function AppCardValueDisplay(props: AppCardValueDisplayProps) {
    return (
        <div className="d-flex">
            <div className="d-flex flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-value="4500" data-kt-countup-prefix="$">
                            {props.loading ?
                                <div className="skeleton-box" style={{ width: '100px', height: '20px'}}/> :
                                props.value
                            }
                        </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">
                        {props.loading ?
                            <div className="skeleton-box" style={{ width: '100px', height: '20px'}}/> :
                            <IntLabel label={props.label}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import CustomSVG from "../../components/icons/svg";
import {onToggleLanguageDrawer} from "@apps/app/src/components/Layout/helpers";
import {IntLabel} from "@app/i18n";

export function SideLanguageSelector(props: { active: any, onClick: () => false | void, onClick1: () => any }) {

    return (
        <div id="kt_explore" className="explore  bg-white drawer drawer-end" data-kt-drawer="true"
             data-kt-drawer-name="explore"
             data-kt-drawer-activate="true" data-kt-drawer-overlay="true"
             data-kt-drawer-width="{default:'300px', 'lg': '440px'}" data-kt-drawer-direction="end"
             data-kt-drawer-toggle="#kt_explore_toggle" data-kt-drawer-close="#kt_explore_close"
        >

            <div className="card shadow-none rounded-0 w-100">
                <div className="card-header" id="kt_explore_header">
                    <h5 className="card-title fw-bold text-gray-600"><IntLabel label={'text.choose.lang'}/></h5>
                    <div className="card-toolbar">
                        <button type="button" className="btn btn-sm btn-icon explore-btn-dismiss me-n5"
                                id="kt_explore_close"
                                onClick={() => onToggleLanguageDrawer()}
                        >
                                    <span className="svg-icon svg-icon-2">
                                        <CustomSVG type={'close'}/>
							        </span>
                        </button>
                    </div>
                </div>
                <div className="card-body" id="kt_explore_body"
                     style={{paddingLeft: "0px", paddingRight: "0px", paddingTop: "0.2rem"}}>
                    <div>

                        <div className={`d-flex  m-1 cursor-pointer language ps-3 select-${props.active ? "on" : "off"}`}
                             onClick={props.onClick}
                        >
                            <div className="btn btn-icon d-flex">
                        <span className="">
                            <CustomSVG type="flag_gb"/>
                        </span>
                            </div>
                            <div className="text-dark fw-bold mt-2 mt-md-2 mb-2 mb-md-2 pt-2 ms-2">English</div>
                        </div>

                        <div className={`d-flex  m-1 cursor-pointer language ps-3 select-${!(props.active) ? "on" : "off"}`}
                             onClick={props.onClick1}
                        >
                            <div className="btn btn-icon d-flex">
                        <span className="">
                            <CustomSVG type="flag_fr"/>
                        </span>
                            </div>
                            <div className="text-dark fw-bold mt-2 mt-md-2 mb-2 mb-md-2 pt-2 ms-2">French</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}
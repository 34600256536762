import React from 'react';
import {IntLabel} from "@app/i18n";

function AppValueDisplay(props: {value?: any, label?: string, isLoading?: boolean}) {

    return (
        <div className="card card-flush h-md-50 shadow-sm">
            <div className="card-header pt-5">
                <div className="card-title d-flex flex-column">
                    <span className="fs-2 fw-boldest text-dark me-2 lh-1 mb-6 ls-n2">
                        {props?.isLoading ?
                            <div className="skeleton-box" style={{ width: '100%', height: '20px'}}/>
                            : (props.value ?? '')
                        }
                    </span>
                    <span className="text-gray-400 pt-1 fw-semibold fs-6">
                        {props?.isLoading ?
                            <div className="skeleton-box" style={{ width: '100%', height: '20px'}}/>
                            : <IntLabel label={props.label ?? ''}/>
                        }
                    </span>
                </div>
            </div>
        </div>
    );
}

export default AppValueDisplay;
import {createSlice} from "@reduxjs/toolkit";
import LocalStorage from "@app/core/src/api/axios/localStorage";

export interface appStoreProps {
    loading: boolean,
    isAuthenticated: boolean,
    currentCompany?: string,
    access?: [],
    user?:  {
        id?: string;
        access?: string;
        firstName?: string;
        lastName?: string;
    }
}


const appSlice = createSlice({
    name: 'appSlice',
    initialState: {
        loading: false,
        isAuthenticated: LocalStorage.getAuthToken() !== null,
        user: LocalStorage.getAppContext()?.user ?? {},
        currentCompany: LocalStorage.getAppContext()?.currentCompany,
        access: LocalStorage.getAppContext()?.access,
    } as appStoreProps,
    reducers: {
        isLoading(state, context: {type: any, payload: boolean}) {
            state.loading = context.payload
        },
        isAuthenticated(state, {type, payload}: {type: any, payload: boolean}) {
            state.isAuthenticated = payload
        },
        onLoginSuccess(state, {type, payload}: {type: any, payload: any}) {

            state.isAuthenticated = true
            state.user = payload.user
            state.currentCompany = payload.currentCompany
            state.access = payload.access
        },
        onLogout(state) {
            state.isAuthenticated = false
            state.user = undefined
        },
        onCompanyChange(state, {type, payload}: {type: any, payload: any}) {
            state.currentCompany = payload.currentCompany
            state.access = payload.access
        }
    }
})

export const {isLoading, isAuthenticated, onLoginSuccess, onLogout, onCompanyChange} = appSlice.actions;
export default appSlice.reducer;
import React, {useEffect, useState} from "react";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel} from "@app/i18n";
import {useParams} from "react-router";
import {fetchOneCompanyEmployee} from "@app/core/src/api";
import {ContractList} from "../../../../../components/Lists";
import EmployeeDetails from "./EmployeeDetails";
import DetailsCardView from "../../../../../components/DetailsCard/DetailsCardView";
import {companyRoutesPaths} from "../../routing/companyRoutesPaths";
import EmployeeInitiatedPayments from "./components/EmployeeInitiatedPayments";
import {useSelector} from "react-redux";

function getTabs(activeTab: string, setActiveTab: (value: (((prevState: string) => string) | string)) => void) {
    return [
        {label: 'text.infos', isActive: activeTab === '', tab: '', handle: (value: any) => setActiveTab(value)},
        {label: 'text.contracts', isActive: activeTab === 'contracts', tab: 'contracts', handle: (value: any) => setActiveTab(value)},
        {label: 'text.payments', isActive: activeTab === 'payments', tab: 'payments', handle: (value: any) => setActiveTab(value)},
    ];
}

function CompanyEmployeeDetailsView(){
    const {currentCompany} = useSelector((state: any) => state.appSlice)
    const { id } = useParams()
    const [employee, setEmployee] = useState<any>()
    const [activeHash, setActiveHash] = useState(window.location.hash?.substring(1))
    const [isLoading, setLoading] = useState(false)

    function getContent(tab: string) {
        switch (tab) {
            case '':
                return <EmployeeDetails employee={employee}/>
            case 'contracts':
                return <ContractList companyId={currentCompany ?? ''} query={{ 'uuid[creator_id]': id ?? "" }}/>
            case 'payments':
                return <EmployeeInitiatedPayments employeeId={id}/>
            default:
                return 'Unknown step';
        }
    }

    useEffect(()=>{
        setLoading(true)
        fetchOneCompanyEmployee(id).then((res: any) => {
            res?.data !== undefined && setEmployee(res?.data)
            setLoading(false)
        }).catch((e: any) =>{
            setLoading(false)
        })
    }, [id])


    return(
        <AppContainer breadcrumb={[
            {label: 'menu.domain.company.overview', path: appPaths.APP},
            {label: 'menu.domain.company.employee.overview', path: companyRoutesPaths.COMPANY_EMPLOYEES},
        ]} title={<IntLabel label="menu.domain.land.overview"/>}>
            <div className="row">
                <div className="col-12">
                    <DetailsCardView
                        title={employee?.staffFirstName + ' ' + employee?.staffLastName}
                        loading={isLoading}
                        description={''}
                        tabs={getTabs(activeHash, setActiveHash)}
                    />
                </div>
                <div className="col-12 mt-5">
                    {getContent(activeHash)}
                </div>
            </div>
        </AppContainer>
    )
}

export default CompanyEmployeeDetailsView;
import React, {useEffect, useState} from 'react';
import {IntLabel} from "@app/i18n";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import AppAsyncSelect from "../AsyncSelect/AppAsyncSelect";
import {fetchCompanyLands} from "../../api";
import EnumSelect from "../EnumSelect";
import {TransactionType} from "../../interface/Enums";
import {fetchTransaction, fetchTransactionPartners, fetchTransactionsCategory} from "../../api/domain/company/transaction";

interface FormTransactionProps {
    onFinish?: any
    onCancel?: any
    companyId?: any
    transactionId?: any
    isLoading?: boolean,
}
function FormTransaction(props: FormTransactionProps) {
    const [loading, setLoading] = useState(false)
    const [transaction, setTransaction] = useState<any>({
        type: undefined,
        categoryId: undefined,
        description: undefined,
        beneficiary: undefined,
        phoneNumber: undefined,
        amount: undefined,
        partnerId: undefined,
        paymentId: undefined,
        propertyType: undefined,
        propertyId: undefined,
        userId: undefined,
    });

    useEffect(() => {
        if (props.transactionId) {
            setLoading(true)
            fetchTransaction(props.transactionId).then((res: any) =>{
                let data = res.data

                setTransaction({
                    type: data.type,
                    categoryId: undefined,
                    description: data.description,
                    phoneNumber: data.phoneNumber,
                    amount: data.amount,
                    beneficiary: data.beneficiary,
                    partnerId: undefined,
                    paymentId: undefined,
                    propertyType: data.propertyType,
                    propertyId: undefined,
                    userId: undefined,
                })
                setLoading(false)
            })
        }

    }, [])

    return (<form onSubmit={(e) => {
        e.preventDefault()
        props.onFinish(transaction)
    }}>
        <div className="form-row ">
            <div className="row">
                <div className="form-group col-md-12 my-3">
                    <label>{getLabel("text.transaction.category")}</label>
                    <AppAsyncSelect
                        fetchData={fetchTransactionsCategory}
                        onChange={(e :any )=> setTransaction((prev: any) => ({...prev, categoryId: e[0]?.value}))}
                        parsing={(data: any) => {
                            return {value: data.id, label: data.name}
                        }}/>
                </div>
                <div className="form-group col-12 my-3">
                    <label>{getLabel("text.description")}</label>
                    <input value={transaction.description}
                           required={true}
                           onChange={(e :any )=> setTransaction((prev: any) => ({...prev, description: e.target.value}))}
                           className="form-control"
                           placeholder={getLabel('text.description')} />
                </div>
                <div className="col-12 col-md-6 my-3">
                    <label>{getLabel("text.transaction.type")}</label>
                    <EnumSelect
                        value={transaction.type}
                        onChange={(e :any )=> setTransaction((prev: any) => ({...prev, type: e.target.value}))}
                        enum={TransactionType} className="form-control"/>
                </div>
                <div className="form-group col-md-6 col-12 my-3">
                    <label>{getLabel("text.amount")}</label>
                    <input value={transaction.amount}
                           type="number"
                           required={true}
                           onChange={(e :any )=> setTransaction((prev: any) => ({...prev, amount: parseFloat(e.target.value)}))}
                           className="form-control"
                           placeholder={getLabel('text.amount')} />
                </div>
                <div className="form-group col-md-6 col-12 my-3">
                    <label>{getLabel("text.contact")}</label>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">+237</span>
                        </div>
                        <input type="text"
                               value={transaction?.phoneNumber?.replace('+237-', '')}
                               onChange={(e :any )=> setTransaction(
                                   (prev: any) => ({...prev, phoneNumber: '+237-' + e.target.value}))}
                               className="form-control" placeholder="655555555"/>
                    </div>
                </div>
                <div className="form-group col-12 col-md-6 my-3">
                    <label>{getLabel("text.beneficiary")}</label>
                    <input value={transaction.beneficiary}
                           required={true}
                           onChange={(e :any )=> setTransaction((prev: any) => ({...prev, beneficiary: e.target.value}))}
                           className="form-control"
                           placeholder={getLabel('text.beneficiary')} />
                </div>
                <div className="form-group col-md-12 col-12 my-3">
                    <label>{getLabel("text.transaction.partner")}</label>
                    <AppAsyncSelect
                        fetchData={fetchTransactionPartners}
                        onChange={(e :any )=> setTransaction((prev: any) => ({...prev, partnerId: e[0]?.value}))}
                        parsing={(data: any) => {
                            return {value: data.id, label: data.name}
                        }}/>
                </div>
                <div className="form-group col-md-12 my-3">
                    <label>{getLabel("text.transaction.user")}</label>
                    <AppAsyncSelect
                        fetchData={fetchTransactionsCategory}
                        onChange={(e :any )=> setTransaction((prev: any) => ({...prev, userId: e[0]?.value}))}
                        parsing={(data: any) => {
                            return {value: data.id, label: data.name}
                        }}/>
                </div>
                <div className="form-group col-md-12 my-3">
                    <label>{getLabel("text.land.title")}</label>
                    <AppAsyncSelect
                        fetchData={fetchCompanyLands}
                        onChange={(e :any )=> setTransaction((prev: any) => ({...prev, propertyId: e[0]?.value}))}
                        addParam={{
                            companyId: props.companyId
                        }}
                        parsing={(data: any) => {
                            return {value: data.id, label: `${data?.landTitle} - ${data?.town}`}
                        }}/>
                </div>
            </div>

            <div className="form-row  mt-3">
                <div className="row">
                    <div className="form-group col-md-6 mb-6">
                        <button  className="btn btn-secondary w-100"
                             onClick={props.onCancel}><IntLabel label="text.onCancel"/></button>
                    </div>
                    <div className="form-group col-md-6 mb-6">
                        <button type="submit"
                                disabled={loading || undefined === transaction.categoryId}
                                className="btn btn-primary w-100">
                            <IntLabel label="text.finish"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>);
}

export default FormTransaction;
import PropertyDetailsOverview from "../../../../../components/DetailsCard/PropertyDetailsOverview";
import {IntlDate} from "@app/i18n";
import MapContainer from "@app/core/src/maps/MapContainer";
import React, {useEffect, useState} from "react";
import {useModal} from "@app/core/src/modals";
import {deleteCompanyLand, editCompanyLand, publishCompanyLand} from "@app/core/src/api";
import {useNavigate, useParams} from "react-router";
import {landRoutePaths} from "../../routing/landRoutePaths";
import toast from "react-hot-toast";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import {isLoading} from "../../../../../application/appStore";
import {useDispatch} from "react-redux";


interface LabelDetailsProps {
    property: any;
    loading: boolean;
    companyId: string;
}

function LandDetails ({property, loading, companyId}: LabelDetailsProps) {
    const {showModal, hideModal} = useModal()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let actionHandle = property?.status === 'published' ? 'unpublished' : 'published'

    const onValidate =  () => {
        dispatch(isLoading(true))
        deleteCompanyLand({id: property.id, companyId: companyId})
            .then((res: any) => {
                navigate(landRoutePaths.LAND_VIEW)
                hideModal('APP_CONFIRMATION_MODAL')
                toast.success(getLabel('text.success.operation'));
                dispatch(isLoading(false))
        }).catch((res: any) => {
                hideModal('APP_CONFIRMATION_MODAL')
                toast.error(res.message);
                dispatch(isLoading(false))
        })
    }

    const onChangeStatus = () => {
        dispatch(isLoading(true))
        publishCompanyLand(
            {companyId: companyId, id: property.id},
            {status: actionHandle}
        ).then(() => {
            toast.success("text.success.message")
            dispatch(isLoading(false))
            hideModal('APP_CONFIRMATION_MODAL')
            window.location.reload()
        }).catch((res: any) => {
            toast.error("text.error.message" ?? res.message)
            dispatch(isLoading(false))
        })
    }

    return <PropertyDetailsOverview
        label="land.information"
        actionButtons={property?.id ? [
            {
                label: `btn.delete.land`,
                color: 'danger',
                disabled: property.paidAmount !== null,
                onClick: () => showModal(
                    'APP_CONFIRMATION_MODAL',
                    {
                        message: `text.delete.land.message.confirm`,
                        onValidate: onValidate
                    }
                )
            },
            {
                label: `btn.${actionHandle}.land`,
                color: actionHandle === 'unpublished' ? 'danger' : 'primary',
                onClick: () => showModal(
                    'APP_CONFIRMATION_MODAL',
                    {
                        message: `text.${actionHandle}.land.message.confirm`,
                        onValidate: onChangeStatus
                    }
                )
            },
            {
                label: 'btn.edit.land',
                onClick: () => showModal(
                    'EDIT_LAND_MODAL',
                    {
                        companyId: companyId,
                        landId: property.id,
                    }
                )
            }
        ] : []}
        details={[
            {
                label: 'text.creator',
                value: property?.user?.name,
            },
            {
                label: 'update.date',
                value: <IntlDate date={property?.createdAt ?? ''}/>,
            },
            {
                label: 'update.date',
                value: <IntlDate date={property?.updatedAt ?? ''}/>,
            }
        ]}
    >
        {loading ?
            <div className="skeleton-box" style={{ width: '100%', height: '400px'}}/> :
            <div className="w-100" style={{ height: '400px'}}>
                <MapContainer point={[
                    property?.point?.latitude ?? 0,
                    property?.point?.longitude ?? 0
                ]} zoom={16} className="card-img"
                      polygon={property?.localisation?.map((
                          {longitude, latitude}: any) => ({ lon: longitude, lat: latitude}))}
                />
            </div>
        }
    </PropertyDetailsOverview>;
}

export default LandDetails;
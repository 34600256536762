import React, {useState} from "react";
import AppPrimarySidebar from "./layers/AppPrimarySidebar";
import AppSecondarySidebar from "./layers/secondary/AppSecondarySidebar";
import {logo} from "@app/core/src/assets";

export function AsideToggleButton() {
    const [active, setActive] = useState<boolean>(true)

    const onClick = () => {
        let onAttr = document.body.getAttribute('data-kt-aside-minimize')
        document.body.setAttribute('data-kt-aside-minimize', onAttr === 'on' ? 'off' : 'on')
        setActive(p => !p)
    }

    return <button className={`btn btn-sm btn-icon bg-body btn-color-gray-600 btn-active-primary
            position-absolute translate-middle start-100 end-0 bottom-0
            shadow-sm d-none d-lg-flex ${!active ? 'active' : ''}`}
            data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize" onClick={onClick}>
            <span className="svg-icon svg-icon-2 rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"/>
                    <path
                        d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                        fill="black"/>
                </svg>
            </span>
    </button>;
}

function AppSidebar({sidebarConfig}: any) {
    const [target, setTarget] = useState<any>('app')

    //  drawer drawer-start --- to close  drawer-on ---- to open
    return(
        <div id="kt_aside" className={`aside aside-extended`}>
            <AppPrimarySidebar navItemConfig={sidebarConfig[0]} logo={logo} targeted={target}
                               setTarget={setTarget}/>
            <AppSecondarySidebar onToggle={setTarget} navItemConfig={sidebarConfig[1]} targeted={target}/>
            <AsideToggleButton/>
        </div>
    )
}

export default AppSidebar;

import {useEffect, useState} from "react";
import {CompanyEmployeeInterface} from "../modals/user/AddCompanyEmployeeModal";
import {useDispatch, useSelector} from "react-redux";
import {addCompanyEmployee, editCompanyEmployee} from "../../api";
import {useModal} from "../index";

export default function useUserApi(){
    const [user, setUser] = useState<CompanyEmployeeInterface | undefined>()
    const [error, setError] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const {hideAllModals} = useModal();

    const addEmployee = (value: any) =>{
        setLoading(true)
        addCompanyEmployee(value).then((res: any) => {
            res.errors ? setError(res.errors.error) : hideAllModals();
        }).finally(() => {
            setLoading(false)
        })
    }

    const editEmployee = (id: any, value: any) =>{
        setLoading(true)
        editCompanyEmployee(id,{
            firstName: value.firstName,
            lastName: value.lastName,
            access: value.access,
        }).then((res: any) => {
            res.errors ? setError(res.errors.error) : hideAllModals();
        }).finally(() => {
            setLoading(false)
        })
    }

    return {
        user,
        error,
        loading,
        setUser,
        addEmployee,
        editEmployee,
        setError,
    }

}
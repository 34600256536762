import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {IntLabel} from "@app/i18n";
import FormLand from "@app/core/src/components/form/FormLand"
import {addCompanyLand} from "../../../api";
import toast from "react-hot-toast";


function AddLandModal(props: any) {
    const {companyId}:{companyId?:any} = props.modalProps
    const [fetching, setFetching] = useState(false);

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><IntLabel label="modal.add.land"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormLand onSubmit={(land: any) => {
                        setFetching(true);
                        addCompanyLand({companyId:companyId}, land)
                            .then(() => {
                                toast.success("text.success.message")
                                setFetching(false)
                                props.onClose()
                        }).catch((res: any) => {
                                toast.error("text.error.message" ?? res.message)
                                setFetching(false)
                        })
                   }}
                  isLoading={fetching}
                  onCancelClick={()=>props.onClose()}/>
            </Modal.Body>
        </Modal>
    );
}

export default AddLandModal;
import React from "react";
import PropertyDetailsOverview from "../../../../../components/DetailsCard/PropertyDetailsOverview";
import {IntlDate} from "@app/i18n";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import {formatCurrency} from "@app/core/src/service/formatService";

function LandContractDetails({contract, loading, companyId}: {contract: any, companyId:any, loading: boolean}){

    return(
        <PropertyDetailsOverview
            label={"contract.information"}
            actionButtons={[]}
            details={[
                {
                    label: 'contract.company',
                    value: contract?.company.name,
                },
                {
                    label: "contract.reference",
                    value: contract?.reference,
                },
                {
                    label: "contract.creator",
                    value: contract?.creator.name,
                },
                {
                    label: "contract.period",
                    value: `${contract?.period} ${getLabel("text.months")}`,
                },
                {
                    label: "text.square.price",
                    value: formatCurrency(contract?.squarePrice),
                },
                {
                    label: "text.month.amount",
                    value: formatCurrency(contract?.monthAmount),
                },
                {
                    label: "contract.start",
                    value: <IntlDate date={contract?.startAt} dateFormat="DD MMMM YYYY"/>,
                },
                {
                    label: "contract.next.payment",
                    value: <IntlDate date={contract?.endDate} dateFormat="DD MMMM YYYY"/>,
                },
            ]}>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <div className="ms-2 me-auto">
                        <div className="fs-5 text-gray-400">{getLabel("text.contractor")}</div>
                        {contract?.contractor?.name}
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="ms-2 me-auto">
                        <div className="fs-5 text-gray-400">{getLabel("text.contact")}</div>
                        {contract?.contractor?.contact}
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="ms-2 me-auto">
                        <div className="fs-5 text-gray-400">{getLabel("text.email")}</div>
                        {contract?.contractor?.email}
                    </div>
                </li>
            </ul>
        </PropertyDetailsOverview>

    )
}

export default LandContractDetails;
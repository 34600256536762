import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {ModalProps} from "../../ModalContainer";
import {IntLabel} from "@app/i18n";
import Select from 'react-select';
import {PermissionAccess} from "../../../interface/PermissionAccess";
import useUserApi from "../../controllers/useUserApi";
import {fetchOneCompanyEmployee} from "../../../api";
import {getLabel} from "@app/i18n/src/components/IntLabel";

function EditCompanyEmployeeModal(props: ModalProps){
    const {id} = props.modalProps
    const [options, setOptions] = useState<any[]>([])
    const [employee, setEmployee] = useState<any>(undefined)
    const [fetching, setFetching] = useState<any>(false)
    const {editEmployee, loading, error} = useUserApi();

    useEffect(() => {
        Object.values(PermissionAccess).map((item: string)=> {
            setOptions((prevState: any) => [...prevState, {value: item, label: getLabel(`text.enum.${item}`)}])
        })

        setFetching(true)
        fetchOneCompanyEmployee(id).then((res: any) => {
            let data = res?.data

            setEmployee({
                firstName: data?.staffFirstName,
                lastName: data?.staffLastName,
                access: data?.access
            })
            setFetching(false)
        }).catch(() =>{
            setFetching(false)
        })
    }, []);

    return(
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><IntLabel label={'text.edit.infos'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="pt-3 pb-3">
                    <div className="row gy-6">
                        <div style={{display: (error !== undefined) ? 'block' : 'none', fontSize: "1.3rem", width: "80%", marginLeft: '10%'}}>
                            <div className="text-center rounded-1 text-white" style={{backgroundColor: "#FC7C8BFF"}}>{error}</div>
                        </div>
                        <div className="col-md-6 col-12">
                            <input type="text" name="lastName" className="form-control" placeholder="First name"
                                   value={employee?.firstName}
                                   onChange={(e: any) => setEmployee((u: any) => ({...u, firstName: e.target.value}) ) }
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <input type="text" name="lastName" className="form-control" placeholder="Prenom"
                                   value={employee?.lastName}
                                   onChange={(e: any) => setEmployee((u: any) => ({...u, lastName: e.target.value}) ) }
                            />
                        </div>
                        <div className="col-12">
                            <Select
                                isMulti={true}
                                closeMenuOnSelect={false}
                                placeholder={"Lister des access"}
                                name='access'
                                required={true}
                                value={employee?.access.map((item: any) => ({
                                    value: item,
                                    label: getLabel(`text.enum.${item}`)
                                }))}
                                options={options}
                                onChange={(e: any) => setEmployee((prev: any) => ({
                                    ...prev,
                                    access: e.map((item: any) => item.value)
                                }))}
                            />
                        </div>
                        <div className="col-12 col-md-6 mt-12">
                            <button className={"btn w-100 btn-secondary border border-none"} disabled={false} onClick={(e: any) => props.onClose()}>
                                <IntLabel label={'text.cancel'}/>
                            </button>
                        </div>
                        <div className="col-12 col-md-6 mt-12">
                            <button type="submit" disabled={loading}
                                    onClick={() => editEmployee(id, employee)}
                                    className={`btn w-100  ${loading ? 'btn-outline-primary' : 'btn-primary'}`}>
                                {loading ? <IntLabel label="text.loading"/> : <IntLabel label="text.save"/>}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditCompanyEmployeeModal;
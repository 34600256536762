import React from 'react';
import Modal from "react-bootstrap/Modal";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import FormTransactionCategory from "../../../components/form/FormTransactionCategory";

function TransactionCategoryModal(props: any) {
    const {onValidate, categoryId} = props.modalProps;

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            size="lg"
            backdrop="static"
            centered
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {getLabel("transaction.category.modal")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <FormTransactionCategory categoryId={categoryId} onFinish={onValidate}/>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default TransactionCategoryModal;
import React from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {useModal} from "@app/core/src/modals";
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import CustomSVG from "@app/core/src/components/icons/svg";
import {fetchCompanyContracts} from "@app/core/src/api";
import {ListTable} from "@app/core";

function LandReservationView() {
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const {showModal} = useModal()

    const columns: any[] = [
        {
            title: "text.contractor",
            render: (item: any) => <Link to={`/client/${item.creator.id}`} className="fw-bold fs-5">{item.contractor.name}</Link>
        },
        {
            title: "text.creator",
            render: (item: any) => <Link to={`/employee/${item.creator.id}`} className="fw-bold fs-5">{item.creator.name}</Link>
        },
        {
            title: "text.lands",
            render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land.landTitle}</Link>
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: 'text.printed',
            width: '10%',
            render: (item: any) => <PrintButton url={`/company/api/${currentCompany}/land/contract/print/${item.id}`}/>
        },
        {
            title: 'text.validate',
            width: '6%',
            align: 'center',
            render: (item: any) => <CustomSVG type="info"
              onClick={() => showModal(
                  'APP_CONFIRMATION_MODAL',
                  {

                  }
              )}
              className="text-primary cursor-pointer"/>
        }
    ]

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.reservation'},
        ]} title={<IntLabel label="menu.domain.land.reservation"/>}>
            <ListTable columns={columns} fetchData={fetchCompanyContracts}
                       addQuery={{ 'order[created_at]': 'DESC', 'equal[status]' : 'draft' }}
                       addParam={{ companyId: currentCompany }}/>
        </AppContainer>
    );
}

export default LandReservationView;
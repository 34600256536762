const actionLabels  = {
    "btn.close": "Go back",
    "btn.add.land.contract": "Add contract",
    "btn.add.land": "New land",

    "action.back": "Return",
    "btn.delete.land": "Delete land",
    "btn.delete": "Delete",
    "btn.unpublished.land": "Unpublish land",
    "btn.edit.land": "Edit land information",
    "btn.add.land.part": "New land part",
    "btn.add.employee": "New employee",
    'btn.add.invitation': "New invitations",

    "btn.add.land.payment": "Add new payment",
    'btn.declined': "Decline",
    'btn.validate': "Validate",
    'btn.edit': "Edit",

}

export default actionLabels;
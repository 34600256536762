import React, {useContext, useEffect, useState} from "react";
import translate from "translate";
import IntlContext from "../context/IntlContext";
import {Language} from "../languages";

export async function translateText(text: string, target: string) {
    const translated = await translate(text, {
        engine: 'google',
        from: 'fr',
        to: target,
    });

    return translated;
}


function TextTranslator({text} : {text: string}){
    const [translation, setTrans] = useState(text)
    const {lang} = useContext(IntlContext);

    useEffect(()=>{
        lang !== Language.FRA && translateText(text, 'en').then((res: any) => {
            res !== 'null' && setTrans(res)
        }).catch((e: any) => {
            console.log(e.toString())
        })
    }, [lang])

    return<>{ lang !== Language.FRA ? translation : text}</>

}

export default TextTranslator;
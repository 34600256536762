import React, {useState} from 'react';
import {fetchCompanyContracts} from "@app/core/src/api";
import {formatCurrency} from "@app/core/src/service/formatService";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {ContractStatus} from "@app/core/src/interface/Enums";
import {Button, ListTable} from "@app/core";
import {Link} from "react-router-dom";
import {IntlDate} from "@app/i18n";
import {ContractStatusBadge} from "@app/core/src/components/Badges";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import {useModal} from "@app/core/src/modals";

interface ContractListProps {
    companyId: string,
    landId?: string,
    query?: object,
    contract?: any
    canAdd?: boolean
}
function ContractList(props: ContractListProps) {
    const [status, setStatus] = useState<any | undefined>(undefined);
    const {showModal} = useModal()

    const columns: any[] = [
        {
            title: "text.contractor",
            render: (item: any) => <Link to={`/client/${item.creator.id}`} className="fw-bold fs-5">{item.contractor.name}</Link>
        },
        {
            title: "text.creator",
            render: (item: any) => <Link to={`/company/employee/${item.creator.id}`} className="fw-bold fs-5">{item.creator.name}</Link>
        },
        {
            title: "text.lands",
            render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land.landTitle}</Link>
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.paid.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.paidAmount)}</strong>
        },
        {
            title: "text.date",
            align: 'center',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => <ContractStatusBadge status={item.status}/>
        },
        {
            title: 'text.printed',
            width: '10%',
            render: (item: any) => <PrintButton url={`/company/api/${props.companyId}/land/contract/print/${item.id}`}/>
        },
        {
            title: 'text.info',
            width: '6%',
            align: 'center',
            render: (item: any) => <OpenButton to={`/land/contract/${item.id}`}/>
        }
    ]

    return (
        <ListTable columns={columns} fetchData={fetchCompanyContracts}
            addQuery={{
                ...{'order[created_at]': 'DESC', 'equal[status]' : status},
                ...props.query
            }}
            addParam={{ companyId: props.companyId }}>
            <div className="row">
                <div className="col-md-4 col-12">
                    <EnumSelect enum={ContractStatus}
                          onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                </div>
                {props.canAdd && <div
                    className="col-md-3 col-12 ms-auto mb-md-0 mb-5">
                    <Button label="btn.add.contract"
                        onClick={() => showModal(
                            'ADD_CONTRACT_MODAL',
                            {
                                companyId: props.companyId,
                                landId: props.landId
                            })
                        }/>
                </div>}
            </div>
        </ListTable>
    );
}

export default ContractList;
import React from 'react';
import {defaultView, primaryNavItemConfig, secondaryNavItemConfig} from './navbarConfig'
import DesktopLayout from "../../shared/DesktopLayout";

function AppLayout() {

    return (
        <DesktopLayout defaultView={defaultView} navItemConfig={[primaryNavItemConfig, secondaryNavItemConfig]}/>
    );
}

export default AppLayout;
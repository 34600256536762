export enum landRoutePaths {
    LAND_VIEW = '/land',
    LAND_DETAIL_VIEW = '/land/:id',
    LAND_VALIDATION_VIEW = '/land/validation',

    LAND_CONTRACT = '/land/contracts',
    LAND_CONTRACT_VALIDATION = '/land/contract/validation',
    LAND_CONTRACT_DETAILS = '/land/contract/:id',

    LAND_PAYMENT = '/land/payments',
    LAND_PAYMENT_VALIDATION = '/land/payments/validation',

    LAND_RESERVATION = '/land/reservations',

    LAND_CONTRACTOR = '/land/contractors',

    LAND_INTEREST = '/land/interests',
}
import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {IntLabel} from "@app/i18n";
import {ModalProps} from "../../ModalContainer";
import {
    editCompanyPayment,
    fetchCompanyPayment
} from "../../../api";
import toast from "react-hot-toast";
import LandPaymentForm from "../../../components/form/LandPaymentForm";

function EditLandPaymentModal(props: ModalProps) {
    const {contractId, companyId, paymentId, lang}:{lang: any,contractId?:any, companyId: any, paymentId: any} = props.modalProps
    const [fetching, setFetching] = React.useState(false);
    const [payment, setPayment] = useState<any | undefined>(undefined)

    useEffect(() => {
        setFetching(true)
        fetchCompanyPayment({companyId: companyId, id: paymentId})
            .then((res: any) => {
                setPayment(res.data)
                setFetching(false)
            })
        return () => {};
    }, [paymentId]);

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><IntLabel label="text.add.land.payment.label"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LandPaymentForm
                    lang={lang}
                    payment={{
                        information: payment?.information,
                        amount: payment?.amount,
                        paymentMethod: payment?.paymentMethod,
                        contact: payment?.contact,
                    }}
                    onSubmit={(payment: any) => {
                        setFetching(true);
                        editCompanyPayment(
                            {companyId: companyId, id: paymentId},
                            {
                                contractId: contractId,
                                ...payment
                            })
                            .then(() => {
                                toast.success("text.success.message")
                                setFetching(false)
                                props.onClose()
                                window.location.reload()
                            }).catch((res: any) => {
                            toast.error("text.error.message" ?? res.message)
                            setFetching(false)
                        })
                    }}
                    isLoading={fetching}
                    onCancelClick={()=>props.onClose()}
                />
            </Modal.Body>
        </Modal>
    );
}

export default EditLandPaymentModal;
import React from 'react';
import {IntLabel} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {emptyCompany} from "@app/core/src/assets";
import {useModal} from "@app/core/src/modals";
import {useSelector} from "react-redux";

function AppPageView(){
    const {showModal} = useModal()
    const {isLoading} = useSelector((state: any) => state.appSlice)

    return(
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview'},
        ]} title={<IntLabel label="menu.domain.app.overview"/>}>
            <div className="row mt-5">
                <div className="col-12  my-5 m-auto">
                    <div className="vh-75  w-50 m-auto">
                        <img src={emptyCompany} alt="..." className="w-100 h-100"/>
                    </div>
                </div>
                <div className="col-12 text-center" style={{ margin: '5% 0'}}>
                    <div className="vh-75 vh-md-50 w-md-50 w-100 m-auto">
                        <IntLabel label="text.add.company.description"/>
                    </div>
                </div>
                <div className="col-12 m-auto mt-5 text-center">
                    <div className="vh-50 w-50 m-auto">
                        <button onClick={() => showModal(
                            'ADD_COMPANY_MODAL', {
                                setLoading: (l: boolean) => isLoading(l),
                            }
                        )}  className="btn btn-primary w-100 btn-sm">
                            <IntLabel label="text.add.company"/>
                        </button>
                    </div>
                </div>
            </div>
        </AppContainer>
    )
}

export default AppPageView;
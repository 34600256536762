import React, {useEffect, useState} from "react";
import {appStoreProps} from "../../../../../../application/appStore";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {IntLabel, IntlDate} from "@app/i18n";
import {enumLabel, PaymentMethod, PaymentStatus} from "@app/core/src/interface/Enums";
import {PaymentStatusBadge} from "@app/core/src/components/Badges";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import {ListTable} from "@app/core";
import {fetchCompanyPayments} from "@app/core/src/api";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {ColumnsProps} from "@app/core/src/components/common/Table";


function EmployeeInitiatedPayments(props: {employeeId: any}){
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [status, setStatus] = useState<any | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false)


    const columns: ColumnsProps[] = [
        {
            title: "text.author",
            render: (item: any) => <Link to={`/employee/${item.author.id}`} className="fw-bold fs-5">{item.author.name}</Link>
        },
        {
            title: "text.contract",
            render: (item: any) => <Link to={`/contract/${item.contract.id}`} className="fw-bold fs-6">{item.contract.reference}</Link>
        },
        {
            title: "text.lands",
            render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land.landTitle}</Link>
        },
        {
            title: "text.amount",
            align: 'end',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.contact",
            render: (item: any) => item.contact
        },
        {
            title: 'text.payment.method',
            render: (item: any) => <IntLabel label={enumLabel(PaymentMethod, item.paymentMethod)}/>
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => <PaymentStatusBadge status={item.status}/>
        },
        {
            title: 'text.date',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: 'text.printed',
            width: '10%',
            render: (item: any) => item.status === 'failed' ?
                <PrintButton url={`/market/api/bill/payment/print/${item.id}`}/>
                : ''
        },
        {
            title: 'text.info',
            width: '6%',
            align: 'center',
            render: (item: any) => <OpenButton to={`/payment/${item.id}`}/>
        }
    ]


    return (
        <ListTable columns={columns} fetchData={fetchCompanyPayments}
                        addQuery={{ 'order[created_at]': 'DESC', 'equal[status]' : status, 'uuid[creator_id]': props.employeeId}}
                        addParam={{ companyId: currentCompany }}>
            <div className="row">
                <div className="col-md-4 col-12">
                    <EnumSelect enum={PaymentStatus}
                                onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                </div>
            </div>
        </ListTable>
    );

};

export default EmployeeInitiatedPayments;
const menuLabel = {
    "menu.domain.app": "Dashboard",
    "menu.domain.app.description": "Account management",
    "menu.domain.app.overview": "Dashboard",
    "menu.domain.app.profile": "Profile",

    // ------- land
    "menu.domain.land": "Lands",
    "menu.domain.land.description": "Lands management",
    "menu.domain.land.overview": "Your lands properties",
    "menu.domain.land.contract": "Lands properties contracts",
    "menu.domain.land.payment": "Payment installments",
    "menu.domain.land.reservation": "Properties reservations",
    "menu.domain.land.interest": "Gain on lands properties",
    "menu.domain.land.contractor": "Lands properties customers",
    "menu.domain.land.payment.validation": "Payments validation",

    // ------- hotel
    "menu.domain.hotel": "Hotels",
    "menu.domain.hotel.description": "Hotel management",
    "menu.domain.hotel.overview": "Your hotels properties",
    "menu.domain.hotel.contract": "Hotels properties contracts",
    "menu.domain.hotel.payment": "Payment installments",
    "menu.domain.hotel.reservation": "Properties reservations",
    "menu.domain.hotel.contractor": "Hotels properties customers",

    // ------- property
    "menu.domain.property": "Real estate",
    "menu.domain.property.description": "Real estate properties management",
    "menu.domain.property.overview": "Real estate properties",
    "menu.domain.property.contract": "Real estate contracts",
    "menu.domain.property.payment": "Payment installments",
    "menu.domain.property.reservation": "Real estate reservations",
    "menu.domain.property.contractor": "Real estate customers",

    // --------- market profile
    "text.profile": "My Profile",
    "text.profile.information": "My Information",
    "text.profile.contracts": "My contracts",
    "text.profile.payments": "My Payments",
    "text.profile.favorites": "My Favorites",

    // ----------- security
    "menu.login": "Login",
    "menu.logout": "Logout",
    "menu.register": "Register",

    // -------to land
    "menu.manage.land.interest":"Land Interest Management",
    "menu.manage.land": "Land Management",
    "menu.domain.land.list": "Land List",
    "menu.domain.land.validation": "Land Validation",
    "menu.domain.land.details":"Land Information",

    "menu.manage.contract": "Contract Management",
    "menu.domain.contract.list": "List of contracts",
    "menu.domain.contract.validation": "Contracts validated",
    "menu.domain.contract.overview":"Your contracts",
    "menu.domain.contract.details":"Contract Information",

    "menu.manage.payment": "Payments",
    "menu.domain.payment.list": "Payment List",
    "menu.domain.payment.validation": "Payment validation",

    // ----------- security
    "menu.domain.authentication": "Authentication",
    "menu.domain.authentication.description": "Login",
    "menu.domain.login": "Login",
    "menu.domain.menu.register": "Create Account",

    // -----------business
    "menu.domain.company.employees":"Employees",
    "menu.domain.company":"Your companies",
    "menu.domain.company.overview":"Dashboard",
    "menu.domain.company.employee":"List of employees",
    "text.last_name":"Name",
    "text.role":"Role",
    "text.companyName":"Company",
    "menu.domain.company.autorisations":"Permissions",
    "menu.domain.company.description":"MANAGE YOUR COMPANIES",
    "menu.domain.company.employee.overview":"Employee Information",
    "menu.domain.company.employees.list":"List of employees",
    "menu.domain.company.employees.companyInvitations":"Invitation management",
    "menu.domain.company.invitations":"Invitation Management",
    'menu.domain.company.docs.templates': "Templates models",
    "menu.domain.company.notifications": "Notifications",



}

export default menuLabel;
import axios, {AxiosResponse, Method} from 'axios';
import LocalStorage from "../axios/localStorage";

type Resp = any;

const getMethod = (method: Method) => {
  return (
    {
      get: axios.get,
      delete: axios.delete,
      head: axios.head,
      post: axios.post,
      put: axios.put,
      patch: axios.patch,
    } as any
  )[method];
};

export const getIsOk = (res: AxiosResponse<any>) => {
  return [200, 202, 201, 204].includes(res.status);
};

export const getErrorObj = (data: any) => {
  const { errors, message } = data || { errors: {} };
  const result: any = {};

  if (!errors) {
    return {
      'error': message || data?.error?.message || 'Something went wrong, please try again or contact support.',
    };
  }

  if (Array.isArray(errors)) {
    errors.forEach(({ field, message: errorMessage }: any) => {
      result[field] = errorMessage;
    });
  } else {
    // TODO check if we can remove it
    Object.keys(errors).forEach((key) => {
      const errorText = Array.isArray(errors[key]) ? errors[key].join(' \n') : errors[key];
      if (!Number.isNaN(parseInt(key, 10))) {
        result['error'] = (result['error'] || '').concat(' ').concat(errorText);
      } else if (errors[key] && errors[key].field) {
        result[errors[key].field] = errors[key].message;
      } else {
        result[key] = errorText;
      }
    });
  }

  return result;
};

export const getPath = (path: any, method: Method, props: any) => {

    if (['delete', 'get'].includes(method)) {
        return `${path}${
            props
                ? `?${Object.entries(props)
                    .filter(([key, value]) => value !== undefined)
                    .map((e) => e.join('='))
                    .join('&')}`
                : ''
        }`;
    }

    return path;
};

export const callAction = <T>(path: string, method: Method, isForm?: boolean): ((props?: T) => Promise<Resp>) => {

    loadJWTToken();

  return (props?: T, ops?: any) =>
    getMethod(method)(getPath(path, method, props), props, ops)
      .then((res: AxiosResponse<any>) => {
        try {
          if (isForm) {
            if (!getIsOk(res)) {
              return { errors: getErrorObj(res.data) };
            }
            return res.data;
          } else if (!getIsOk(res)) {
            // window.location.replace('/logout');
            return null;
          }

          return res.data;
        } catch (error) {
          // message.error(`[Error]: ${error}`);
        }
      })
        .catch(({ response }: any) => errorHandler(isForm, response));
};

function loadJWTToken() {
    if (LocalStorage.getAuthToken() !== null) {
        axios.defaults.headers.common = {
            'Authorization': `Bearer ${LocalStorage.getAuthToken()}`,
        };
    }
}

function errorHandler(isForm: undefined | boolean, response: any) {
    if (isForm) {
        return {errors: getErrorObj(response?.data as any)};
    }

    response.status === 401 && window.location.replace('/logout');
    // response.status === 500 && message.error(response.statusText);
    if (response.status >= 400 && response.status < 500) {
        throw new Error(response.data?.message ?? response.data?.error)
    }
}

export const callActionWithId = <T>(
  path: string,
  method: Method,
  isForm?: boolean,
  options?: {
    customUrlFunc?: (id: string, props: any) => string;
  }
): ((id: any, props?: T, addQuery?: any) => Promise<Resp | null>) => {

    loadJWTToken();

  return (id: any, props?: T, addQuery?: any) =>
    getMethod(method)(options?.customUrlFunc?.(id, props) || getPath(path.replace(/{.+}/, id), method, props), props)
      .then((res: AxiosResponse<any>) => {
        try {
          if (isForm) {
            if (!getIsOk(res)) {
              // history.push(RoutePath.LOGOUT);
              return { errors: getErrorObj(res.data) };
            }
            return res.data;
          } else if (!getIsOk(res)) {
            return null;
          }

          return res.data;
        } catch (error) {
          // antMessage.error(`[Error]: ${error}`);
        }
      })
      .catch(({ response }: any) => errorHandler(isForm, response));
};

export const callActionWithCustom = <T>(
    path: string,
    method: Method,
    isForm?: boolean,
    options?: {
        customUrlFunc?: (id: string, props: any) => string;
    }
): ((param: any, props?: T, addQuery?: any) => Promise<Resp | null>) => {
    loadJWTToken();

    return (param: any, props?: T, addQuery?: any) => getMethod(method)
    (options?.customUrlFunc?.(param, props) || getPath(parseCustomUrl(path, param), method, props), props)
        .then((res: AxiosResponse<any>) => {
            try {
                if (isForm) {
                    if (!getIsOk(res)) {
                        // history.push(RoutePath.LOGOUT);
                        return { errors: getErrorObj(res.data) };
                    }
                    return res.data;
                } else if (!getIsOk(res)) {
                    return null;
                }

                return res.data;
            } catch (error) {
                // antMessage.error(`[Error]: ${error}`);
            }
        })
        .catch(({ response }: any) => errorHandler(isForm, response));
};

function parseCustomUrl(path: string, param?: any) {
    return path.split('/')
        .map((part: any) => part.startsWith('{') && part.endsWith('}')
            ? param[part.slice(1, -1)]
            : part)
        .join('/')
}


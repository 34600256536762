import AppLayout from "@apps/app/src/components/Layout/index";
import React from "react";
import {propertyRoutePaths} from "./propertyRoutePaths";

const propertyRoutes = [
    {
        path: propertyRoutePaths.PROPERTY_VIEW,
        element: <AppLayout/>,
        children: []
    }
]

export default propertyRoutes;

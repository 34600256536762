import React, {useState, useEffect, useContext} from 'react';
import {getLabel} from "@app/i18n/src/components/IntLabel";
import {IntLabel, IntlDate} from "@app/i18n";
import CustomSVG from "../icons/svg";
import AppAsyncSelect from "../AsyncSelect/AppAsyncSelect";
import {fetchLocationApi} from "../../api";

function FormLand(props?: any) {

    const [land, setLand] = useState<any>( {
        title: undefined,
        landTitle: undefined,
        town: undefined,
        description: undefined,
        visitPrice: undefined,
        squarePrice: undefined,
        localisations: {
            longitude: 0,
            latitude: 0
        },
        utmCoordinates: [
            { axisX: 0, axisY: 0 },
        ]
    })

    useEffect(() => {
        if (props.land !== undefined) {
            setLand((p: any) => ({...p, utmCoordinates: []}))
            setTimeout(() => setLand(props.land), 5);
        }
        return () => {};
    }, [props.land]);

    return( <>
        <form className="form-row"
              onSubmit={(e) => {
                e.preventDefault()
                props?.onSubmit(land)
            }}>
            <div className="row">
                <div className="col-12 my-4">
                    <label>{getLabel("text.label.title")}</label>
                    <input type="text"
                           className="form-control"
                           required={true}
                           onChange={(e ) => setLand((p: any) => ({...p, title: e.target.value}))}
                           value={land?.title}
                    />
                </div>
                <div className="col-12 my-4">
                    <label>{getLabel("text.label.land.title")}</label>
                    <input
                           className="form-control"
                           required={true}
                           onChange={(e ) => setLand((p: any) => ({...p, landTitle: e.target.value}))}
                           value={land?.landTitle}
                    />
                </div>
                <div className="col-12 my-4">
                    <label>{getLabel("text.label.location")}</label>
                    <AppAsyncSelect
                        fetchData={fetchLocationApi}
                        searchKey="city"
                        onChange={(e: any ) => {
                            let place: any = e[0]?.value
                            place && setLand((p: any) => ({
                                    ...p,
                                    town: place?.display_name,
                                    localisations: {
                                        longitude: parseFloat(place?.lon),
                                        latitude: parseFloat(place?.lat)
                                    }
                                }
                            ))
                        }}
                        defaultValue={land?.town}
                        addQuery={{'format': 'json', 'country': 'cameroon'}}
                        parsing={(place: any) =>  ({value: place, label: `${place.display_name}`})}
                    />
                </div>
                <div className="col-12 my-4">
                    <label>{getLabel("text.label.description")}</label>
                     <textarea  className="form-control"
                            required={true}
                            onChange={(e ) => setLand((p: any) => ({...p, description: e.target.value}))}
                           value={land?.description}
                     />
                </div>
                <div className="col-md-6 col-12 my-4">
                    <label>{getLabel("text.label.visit.price")}</label>
                    <input type="number"
                           required={true}
                           className="form-control"
                           onChange={(e ) => setLand((p: any) => ({...p, visitPrice: parseFloat(e.target.value)}))}
                           value={land?.visitPrice}
                    />
                </div>
                <div className="col-md-6 col-12 my-4">
                    <label>{getLabel("text.label.square.price")}</label>
                    <input type="number"
                           required={true}
                           className="form-control"
                           onChange={(e ) => setLand((p: any) => ({...p, squarePrice: parseFloat(e.target.value)}))}
                           value={land?.squarePrice}
                    />
                </div>
                <div className="col-md-6 col-12 my-4">
                    <label>{getLabel("text.label.latitude")}</label>
                    <input type="number"
                           required={true}
                           className="form-control"
                           onChange={(e ) => {
                               let localisation = land?.localisations
                               localisation.latitude = parseFloat(e.target.value)
                               setLand((p: any) => ({...p, localisations: localisation}))
                           }}
                           value={land?.localisations?.latitude}
                    />
                </div>
                <div className="col-md-6 col-12 my-4">
                    <label>{getLabel("text.label.longitude")}</label>
                    <input type="number"
                           className="form-control"
                           required={true}
                           onChange={(e ) => {
                               let localisation = land?.localisations
                               localisation.longitude = parseFloat(e.target.value)
                               setLand((p: any) => ({...p, localisations: localisation}))
                           }}
                           value={land?.localisations?.longitude}
                    />
                </div>
                <hr className="my-3"/>
                {land?.utmCoordinates?.map((item: any, index: number) =>
                    <div className="col-12 row my-4" key={index}>
                        <div className="col-6">
                            <label>{getLabel("text.label.axisX")}</label>
                            <input
                                   className="form-control"
                                   type="number"
                                   max={999999}
                                   onChange={(e) => {
                                       let utmItem = land?.utmCoordinates
                                       utmItem[index].axisX = parseFloat(e.target.value)
                                       setLand((p: any) => ({...p, utmCoordinates: utmItem}))
                                   }}
                                   required={true}
                                   value={item.axisX}
                            />
                        </div>
                        <div className="col-5">
                            <label>{getLabel("text.label.axisY")}</label>
                            <input
                                   required={true}
                                   type="number"
                                   max={999999}
                                   onChange={(e) => {
                                       let utmItem = land?.utmCoordinates
                                       utmItem[index].axisY = parseFloat(e.target.value)
                                       setLand((p: any) => ({...p, utmCoordinates: utmItem}))
                                   }}
                                   className="form-control"
                                   value={item.axisY}
                            />
                        </div>
                        <div className="col-1 text-end">
                            <button type="button"
                                className={`btn btn-sm mt-6 ${index === 0 ? 'btn-outline-success' : 'btn-outline-danger'}`}
                                onClick={() => {
                                    let utmCoordinates = land?.utmCoordinates
                                    0 === index ? utmCoordinates.push({ axisX: 0, axisY: 0 }) : utmCoordinates.splice(index, 1)
                                    setLand((p: any) => ({...p, utmCoordinates: utmCoordinates}))
                                }}>
                                <CustomSVG type={index === 0 ? 'circlePlus' : 'trash'}/>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div className="row mt-4">
                <div className="form-group col-md-6 mb-6">
                    <button  className="btn  btn-secondary w-100" onClick={props.onCancelClick}>
                        <IntLabel label="text.onCancel"/>
                    </button>
                </div>
                <div className="form-group col-md-6 mb-6" >
                    <button  className="btn btn-primary w-100" disabled={props.isLoading} type="submit">
                        <IntLabel label="text.finish"/>
                    </button>
                </div>
            </div>
        </form>
    </>);
}
export default FormLand;
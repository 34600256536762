import React from 'react';
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {formatCurrency} from "@app/core/src/service/formatService";
import {IntLabel} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {AnalyticsTable} from "@app/core";
import {fecthAllCompanyContractor} from "@app/core/src/api";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import {FormatPhone} from "@app/core/src/helpers/components/FormatPhone";

function LandContractorView() {
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)

    const columns: any[] = [
        // {
        //     title: "text.contract.number",
        //     align: "center",
        //     width: '10%',
        //     render: (value: any) => <Link to={'/'}>{value.numberContract}</Link>
        // },
        {
            title: "text.contractor.name",
            align: 'left',
            render: (value: any) => <strong>{value.firstname} {value.lastname}</strong>
        },
        {
            title: "text.contractor.address",
            align: 'center',
            render: (value: any) => value.address ?? '/'
        },
        {
            title: "text.contact",
            align: 'center',
            width: '15%',
            render: (value: any) => <FormatPhone phone={value.contact}/>
        },
        {
            title: "text.email",
            align: 'right',
            width: '15%',
            render: (value: any) => <em>{value.email}</em>
        },
        {
            title: "text.paid.amount",
            align: 'right',
            render: (value: any) => formatCurrency(value.amountPaid)
        },
        {
            title: 'text.info',
            width: '5%',
            align: 'center',
            render: (value: any) => <OpenButton to={`/land/contractors`}/>
        }
    ]


    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.contractor'},
        ]} title={<IntLabel label="menu.domain.land.contractor"/>}>
            <AnalyticsTable columns={columns} fetchData={fecthAllCompanyContractor}
                cards={[
                    {label: 'contractor.count', render: (value: any) => value?.numberVerse  ?? 0},
                    {label: 'contractor.total.paid', render: (value: any) => formatCurrency(value?.totalAmountPaid ?? 0)},
                ]}
                addQuery={{ 'order[created_at]': 'DESC' }}
                addParam={{ companyId: currentCompany }}/>
        </AppContainer>
    );
}

export default LandContractorView;
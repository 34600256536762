import React from "react";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import { Button, ListTable} from "@app/core";
import {IntLabel, IntlDate} from "@app/i18n";
import {fetchCompanyAllEmployees} from "@app/core/src/api";
import {useModal} from "@app/core/src/modals";
import {companyRoutesPaths} from "../../routing/companyRoutesPaths";
import {useSelector} from "react-redux";
import {appStoreProps} from "../../../../../application/appStore";
import {ColumnsProps} from "@app/core/src/components/common/Table";

export default function CompanyEmployeesView(){
    const { showModal } = useModal()
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const {loading} = useSelector((state: any) => state.appSlice)

    const columns : ColumnsProps[] = [
        {
            title: "text.last_name",
            width: "auto",
            align: "start",
            key: "surname",
            render: (user: any) => user.name?.normalize()
        },
        {
            title: "text.companyName",
            width: "auto",
            align: "start",
            key: 'company',
            render: (user: any) => user.CompanyName?.toUpperCase()
        },
        {
            title: "text.date",
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: 'text.info',
            width: '8%',
            align: "center",
            key: "info",
            render: (user: any) => <OpenButton to={`/company/employee/${user.id}`}/>
        }
    ]

    return(
        <AppContainer breadcrumb={[
            {label: 'menu.domain.company.overview', path: companyRoutesPaths.COMPANY_EMPLOYEES},
            {label: 'menu.domain.company.employees'},
        ]} actions={[
            <Button label="btn.add.employee"
                    onClick={() => showModal(
                        'ADD_COMPANY_EMPLOYEE_MODAL',
                        {}
            )}/>
        ]} title={<IntLabel label="menu.domain.company.overview"/>}>
            
            <ListTable fetchData={fetchCompanyAllEmployees}
                       columns={columns}
                       addParam={{ companyId: currentCompany }}/>
        </AppContainer>

    )
}
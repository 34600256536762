import TextLabelsFra from './FR/text.fr';
import menuLabel from "./FR/menu.fr";
import modalLabel from "./FR/modal.fr";
import sectionLabel from "./FR/section";
import enumLabel from "./FR/enumLabel.fr";
import actionLabels from "./FR/actions.fr";
import {accessLabels} from "./FR/accessLabel.fr";

const labels = {
    'hello.word': 'Bonjour le monde',
    ...TextLabelsFra,
    ...menuLabel,
    ...modalLabel,
    ...sectionLabel,
    ...enumLabel,
    ...actionLabels,
    ...accessLabels,
}

export default labels;
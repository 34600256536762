import TextLabels from './ENG/text.en';
import MenuLabels from './ENG/menu.en';
import actionLabels from './ENG/actions.en';
import sectionLabel from './ENG/section.en';
import enumLabel from "./ENG/enumLabel.en";
import ModalLabels from "./ENG/modal.en";

const labels = {
    'hello.word': 'hello word',
    ...TextLabels,
    ...MenuLabels,
    ...actionLabels,
    ...sectionLabel,
    ...enumLabel,
    ...ModalLabels,
}

export default labels;
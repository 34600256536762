import React from 'react';
import {IntLabel} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {Button, ListTable} from "@app/core";
import {useModal} from "@app/core/src/modals";
import {fetchTransactionPartners} from "@app/core/src/api/domain/company/transaction";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import usePartnerController from "../../controller/usePartnerController";

function TransactionPartner() {
    const {createPartner, loading} = usePartnerController()
    const {showModal, hideModal} = useModal()

    const columns: any[] = [
        {
            title: "text.partner",
            render: (item: any) => item.name
        },
        {
            title: "text.description",
            render: (item: any) => item.description
        },
        {
            title: 'text.contract.count',
            render: (item: any) => item.contractCount ?? 0
        },
        {
            title: 'text.info',
            width: '6%',
            align: 'center',
            render: (item: any) => <OpenButton to={`/transaction/partner/${item.id}`}/>
        }
    ]

    return <AppContainer breadcrumb={[
        {label: 'menu.domain.app.overview', path: appPaths.APP},
        {label: 'menu.domain.transaction.partner.overview'},
    ]}
         title={<IntLabel label="menu.domain.transaction.partner.overview"/>}
         actions={[
             <Button label="btn.add.partner"
                 onClick={() => showModal(
                     'PARTNER_MODAL',
                     {
                         onValidate: (partner: any) => createPartner(partner)
                             .finally(() => hideModal('PARTNER_MODAL'))
                     })
                 }/>
         ]}
    >
        <ListTable
            columns={columns}
            refresh={loading}
            fetchData={fetchTransactionPartners}/>
    </AppContainer>
}

export default TransactionPartner;
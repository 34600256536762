import React from 'react';
import {IntLabel} from "@app/i18n";

function PropertyDetailsOverview(props: {label: string, children?: any, actionButtons?: any[], details?: any[]}) {
    return (
        <div className="card mb-5 mb-xl-10 shadow-sm">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0"><IntLabel label={props.label}/></h3>
                </div>
                <div className="ms-auto my-auto">
                    {(props?.actionButtons || []).map((item, index) =>
                        <button onClick={item.onClick} key={index} disabled={item.disabled ?? false}
                                className={`btn btn-sm btn-${item.color ?? 'primary'} align-self-center mx-3`}>
                            <IntLabel label={item.label}/>
                        </button>)}
                </div>
            </div>
            <div className="card-body p-9">
                <div className="row">
                    <div className="col-12 col-md-6">
                        {(props.details || []).map((item, index) =>
                            <div className="row mb-7" key={index}>
                                <label className="col-lg-4 fw-bold text-muted">
                                    <IntLabel label={item.label}/>
                                </label>
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">
                                        {item.value}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12 col-md-6">
                        {props.children}
                    </div>
                </div>

                {/*<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">*/}
                {/*    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">*/}
                {/*        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
                {/*             viewBox="0 0 24 24" fill="none">*/}
                {/*            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"*/}
                {/*                  fill="black"></rect>*/}
                {/*            <rect x="11" y="14" width="7" height="2" rx="1"*/}
                {/*                  transform="rotate(-90 11 14)" fill="black"></rect>*/}
                {/*            <rect x="11" y="17" width="2" height="2" rx="1"*/}
                {/*                  transform="rotate(-90 11 17)" fill="black"></rect>*/}
                {/*        </svg>*/}
                {/*    </span>*/}
                {/*    <div className="d-flex flex-stack flex-grow-1">*/}
                {/*        <div className="fw-bold">*/}
                {/*            <h4 className="text-gray-900 fw-bolder">We need your attention!</h4>*/}
                {/*            <div className="fs-6 text-gray-700">Your payment was declined. To start using tools, please*/}
                {/*                <a className="fw-bolder" href="#">Add Payment Method</a>.*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default PropertyDetailsOverview;
import axios from './axios';
import {callAction} from "./helpers";

axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;

export const logout = () => {
  delete axios.defaults.headers.common['Authorization'];
};

export const fetchAllUsers = callAction<any>('/app/api/user', 'get', false);

export * from './domain/market';
export * from './domain/company/company';
export * from './domain/company/comanyLand';
export * from './domain/company/companyEmployee';

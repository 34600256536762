
const modalLabel = {
    "confirmation.modal.title": "Confirmation",
    "confirmation.modal.message": "Cette action est irreversible voulez vous vraiment le faire ?",

    //land
    "modal.add.land": "Nouveau terrain",
    'transaction.modal': "Fenetre d'operation",
    'transaction.category.modal': "Fenetre catégorie d'operation",
    'partner.modal': "Fenetre de partenaire",
    'partner.contract.modal': "Fenetre de partenaire",
}

export default  modalLabel;
export enum PermissionAccess {
    MANAGE_LAND = 'manage_land',
    MANAGE_LAND_PAYMENT = 'manage_land_payment',

    MANAGE_LAND_CONTRACT = 'manage_land_contract',
    LAND_ADMINISTRATION = 'land_administration',

    MANAGE_CLIENT = 'manage_client',
    CLIENT_ADMINISTRATION = 'client_administration',

    MANAGE_COMPANY = 'manage_company',
    MANAGE_NOTIFICATION = 'manage_notification',

    MANAGE_TRANSACTION = 'manage_transaction',
    TRANSACTION_ADMINISTRATION = 'transaction_administration',
}


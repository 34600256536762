import React, {useEffect, useState} from 'react';
import {emptyCompany, team1} from "@app/core/src/assets";
import {defaultListData} from "@app/core/src/interface/ListDataInterface";
import {fetchUserCompany} from "@app/core/src/api";
import _ from "lodash";
import {IntLabel} from "@app/i18n";
import {useModal} from "@app/core/src/modals";
import useCompany from "../../../../Hooks/useCompany";

function TabCompanySelect() {
    const {currentCompany, changeCompany} = useCompany()
    const [isLoading, setIsLoading] = useState(false)
    const [companies, setCompanies] = useState(defaultListData)
    const {showModal} = useModal()

    useEffect(() => {
        if (currentCompany) {
            setIsLoading(true)
            currentCompany && fetchUserCompany()
                .then((res: any) => {
                    setCompanies(res)
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        }

        return () => {};
    }, [currentCompany]);

    return (
       <div className="mb-10 mx-3">
           {isLoading ?
               _.times(5, (i) =>
                   <a href="#" key={i}
                        className="custom-list d-flex align-items-center px-5 py-4 mb-5">
                       <div className="symbol symbol-40px me-5">
                        <span className="symbol-label">
                            <div className="skeleton-box" style={{ width: '100%', height: '20px'}}/>
                        </span>
                       </div>
                       <div className="d-flex flex-column flex-grow-1">
                           <h5 className="custom-list-title fw-bold text-gray-800 mb-1">
                               <div className="skeleton-box" style={{ width: '100%', height: '20px'}}/>
                           </h5>
                           <span className="text-gray-400 fw-bold">
                               <div className="skeleton-box" style={{ width: '100%', height: '20px'}}/>
                           </span>
                       </div>
                   </a>)
               :
               companies.data.map((item, index) =>
                   <a href="#" key={index} onClick={() => changeCompany(item.id)}
                      className={`custom-list d-flex align-items-center px-5 py-4 
                        ${item.id === currentCompany && 'shadow-sm border border-primary rounded'}`}>
                       <div className="symbol symbol-40px me-5">
                        <span className="symbol-label">
                            <img src={team1} className="h-50 align-self-center" alt=""/>
                        </span>
                       </div>
                       <div className="d-flex flex-column flex-grow-1">
                           <h5 className="custom-list-title fw-bold text-gray-800 mb-1">
                               {item.name}
                           </h5>
                           <span className="text-gray-400 fw-bold">
                               {item.description}
                           </span>
                       </div>
                   </a>
           )}
           <div className="row mt-5">
               {companies.data.length === 0 &&
                   <div className="col-12 mb-5">
                        <img src={emptyCompany} alt="..." className="w-100"/>
                   </div>
               }
               <div className="col-12 mt-5">
                   <button onClick={() => showModal(
                       'ADD_COMPANY_MODAL', {

                        }
                    )}
                       className="btn btn-primary w-100 btn-sm">
                       <IntLabel label="text.add.company"/>
                   </button>
               </div>
           </div>
        </div>
    );
}

export default TabCompanySelect;
import React, {useEffect, useState} from "react";
import CustomSVG from "../../icons/svg";
import AsyncSelect from "react-select/async";

export function DualAsyncSelect(props: { onChange: Function, options: any[], defaultValues?: any[] }) {
    const [globalVal, setGlobalVal] = useState<any[]>(props.defaultValues ?? [])
    const [input, setInput] = useState<any>("")

    const filterOptions = (inputValue: string, options: any[]) => {
        return options.filter((i) =>
            i.label?.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            setTimeout(() => {
                resolve(filterOptions(inputValue, props.options));
            }, 1000);
        });

    useEffect(() => {
        props.onChange([...globalVal])
    }, [globalVal])

    const filter = (e: any, i: any) => {
        if (e?.label !== i)
            return e;
    }

    return (
        <>
            <div className="col-12 d-flex">
                <input type="email" className="form-control me-4" placeholder="Add a new e-mail address" value={input}
                       onChange={(e: any) => setInput(e.target?.value)}
                />
                <div onClick={(e: any) => {
                    setGlobalVal((gbv: any[]) => [...gbv.map((v: any) => filter(v, input)), {
                        value: input,
                        label: input
                    }])
                }} style={{cursor: 'pointer'}}><CustomSVG type={"circle_plus"}/></div>
            </div>
            <div className="col-12">
                <AsyncSelect placeholder={'Select existing e-mail addresses'} isMulti={true} name='recipients'
                             className="basic-multi-select" options={props?.options}
                             styles={{
                                 multiValue: (baseStyles, state) => ({
                                     ...baseStyles,
                                      backgroundColor: "#E1EEF1FF",
                                     color: 'darkblue',
                                     fontSize: "1.3rem",
                                 }),
                                 placeholder: (baseStyle) => ({
                                     ...baseStyle,
                                     color: '#A9ADBDFF',
                                     fontSize: '1.13rem',
                                 }),
                                 control: (baseStyles) => ({
                                     ...baseStyles,
                                     borderBlockColor: '#e9ebee',
                                     borderLeftColor: '#e9ebee',
                                     borderRightColor: '#e9ebee',
                                 })

                             }}

                                value={[...globalVal]}
                                defaultValue={props.defaultValues ?? []}
                                loadOptions={promiseOptions}

                                onChange={(e: any) => {
                                     setGlobalVal(() => [...e])
                                }}
                />
            </div>

        </>
    )
}
import React, {useState} from 'react';
import {IntLabel} from "@app/i18n/src";
import {logo} from "@app/core/src/assets";
import {Link} from "react-router-dom";
import {appPaths} from "@apps/app/src/ui/pages/app/routing/appRoutesPaths";
import CustomSVG from "@app/core/src/components/icons/svg";

function ModuleHeader(props: any){
    return(
        <div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-name="header"
             data-kt-sticky-offset="{default: '200px', lg: '300px'}">
            <div className="container-fluid d-flex align-items-center justify-content-between" id="kt_header_container">
                <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
                    <div className="btn btn-icon btn-active-icon-primary  mobile_toggle_menu" id="kt_aside_toggle">
                        <span className="svg-icon svg-icon-2x">
                            <CustomSVG type="burger_menu"/>
                        </span>
                    </div>
                    <Link to={appPaths.APP} className="d-flex align-items-center">
                        <img alt="Logo" src={logo} className="h-40px"/>
                    </Link>
                </div>

                <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0"
                    data-kt-swapper="true" data-kt-swapper-mode="prepend"
                    data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                    <h1 className="text-dark fw-bold my-0 fs-2">{<IntLabel label={props.title}/>}</h1>

                    <ul className="breadcrumb breadcrumb-line text-muted fw-bold fs-base my-1">
                        {props.breadcrumb?.map((item: any, index: number) =>
                            item.path !== undefined ? <li key={index} className={`breadcrumb-item text-muted`}>
                                <Link to={item.path} className="text-muted">{<IntLabel label={item.label}/>}</Link>
                            </li> :
                            <li key={index} className="breadcrumb-item text-dark"><IntLabel label={item.label}/></li>
                        )}
                    </ul>
                </div>

                <div className="d-flex">
                    {props.actions?.map((item: any) => <div className="ms-2">{item}</div>)}
                </div>
            </div>
        </div>

    )
};

export default ModuleHeader;
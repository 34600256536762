import { ModalMap } from './constants/constants';
import React from 'react';
import {useModal} from "./index";

export interface ModalProps {
  key: any,
  modalProps: any,
  onClose: any,
  hideAllModals: any,
  visible: boolean,
}

export default function ModalContainer() {
  const { modals, hideModal, hideAllModals } = useModal();

  return (modals?.modals ||  []).map(({ type, modalProps, visible }: any, index: number) => {
    const ModalComponent = (ModalMap as any)[type];
    const hideCurrentModal = () => hideModal(type);

    return (
      <ModalComponent
        key={type + index}
        modalProps={{...modalProps}}
        onClose={hideCurrentModal}
        hideAllModals={hideAllModals}
        visible={visible}
      />
    );
  });
}

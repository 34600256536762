import React from "react";
import {IntLabel} from "@app/i18n";
import TableSkeleton from "./TableSkeleton";
import CustomSVG from "../../icons/svg";

export interface ColumnsProps{
    title: string,
    key?: any,
    align?: 'center'|'start'|'end'|undefined
    render?: (item: any, index: number) => {}
    width?: string
    dataIndex?: string
    sorter?: Function | boolean
    onRowClick?: Function
}

export interface  AppTableProps {
    title?: string,
    isLoading?: boolean,
    columns: ColumnsProps[],
    data: any[],
    children?: React.ReactNode[]
}

function AppTable(props: AppTableProps) {

    return(
        props.isLoading ?
            <TableSkeleton column={props.columns.length}/>
            :
            <div className="table-responsive">
                <table className="table table-row-bordered table-responsive">
                    <thead>
                    {props.title && <h6><IntLabel label={props.title}/></h6>}
                    <tr className="text-start text-gray-400 fw-bold">
                        {props.columns.map((col: ColumnsProps, index: any) =>
                            <th className={`p-3`}
                                style={{width: col.width ?? undefined, textAlign: col.align ?? "start"}} key={col.key ?? index}>
                                <IntLabel label={col.title}/>
                            </th>
                        )}
                    </tr>
                    </thead>

                    <tbody>
                    {
                        props.data?.length === 0 ?
                            <tr>
                                <td colSpan={props.columns.length} style={{ textAlign: 'center', padding: '50px 0', color: '#efe9e9' }}>
                                    <div style={{ width: '100%',}}>
                                        <CustomSVG type="emptyIcon"/>
                                    </div>
                                    <IntLabel label="no.content"/>
                                </td>
                            </tr>
                        :
                        props.data?.map((item, index) =>
                        <tr key={index}>
                            {props.columns.map((colomnItem, colomnIndex) => {
                                return <td style={{ textAlign: colomnItem.align ?? "start"}} key={colomnIndex}>
                                    {colomnItem.render ? colomnItem.render(item, index) : ''}
                                </td>
                            })}
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

    )
};

export default AppTable;
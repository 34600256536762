import {appMenu} from "../../ui/pages/app/routing/appMenu";
import {landMenu} from "../../ui/pages/land/routing/landMenu";
import {hotelMenu} from "../../ui/pages/hotel/routing/hotelMenu";
import {propertyMenu} from "../../ui/pages/property/routing/propertyMenu";
import {companyMenu} from "../../ui/pages/company/routing/companyMenu";
import {AccessDomain} from "@app/core/src/interface/AccessDomain";
import {PermissionAccess} from "@app/core/src/interface/PermissionAccess";
import {transactionMenu} from "../../ui/pages/Transaction/routing/transactionMenu";

export const defaultView = 'app';

export const secondaryNavItemConfig = [
    ...appMenu,
    ...landMenu,
    ...hotelMenu,
    ...propertyMenu,
    ...companyMenu,
    ...transactionMenu,
] as any;

interface PrimaryNavProps {
    target: string;
    title: string;
    icon: string;
    access?: AccessDomain;
}

export const primaryNavItemConfig = [
    {
        target: 'app',
        title: 'app.menu.app',
        icon: 'grid',
    },
    {
        target: 'land',
        title: 'app.menu.land',
        icon: 'land_location',
        access: [
            PermissionAccess.LAND_ADMINISTRATION,
            PermissionAccess.MANAGE_LAND_CONTRACT,
            PermissionAccess.MANAGE_LAND_PAYMENT,
            PermissionAccess.MANAGE_LAND,
        ]
    },
    {
        target: 'transaction',
        title: 'app.menu.transaction',
        icon: 'transaction',
        access: [
            PermissionAccess.TRANSACTION_ADMINISTRATION,
            PermissionAccess.MANAGE_TRANSACTION,
        ]
    },
    // {
    //     target: 'hotel',
    //     title: 'app.menu.hotel',
    //     icon: 'hotel',
    //     access: AccessDomain.HOTEL
    // },
    // {
    //     target: 'property',
    //     title: 'app.menu.property',
    //     icon: 'property',
    //     access: AccessDomain.REAL_ESTATE
    // },
    {
        target: 'company',
        title: 'app.menu.company',
        icon: 'company_hr',
        access: [
            PermissionAccess.MANAGE_COMPANY,
        ]
    },
] as PrimaryNavProps[];
import React from 'react';
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import {fetchTransactionsCategory} from "@app/core/src/api/domain/company/transaction";
import {Button, ListTable} from "@app/core";
import {useModal} from "@app/core/src/modals";
import useTransactionController from "../../controller/useTransactionController";
import CustomSVG from "@app/core/src/components/icons/svg";

function TransactionCategory() {
    const {showModal, hideModal} = useModal()
    const {updateCategory, createCategory, loading} = useTransactionController()

    const columns: any[] = [
        {
            title: "text.transaction.category",
            render: (item: any) => item.name
        },
        {
            title: "text.description",
            render: (item: any) => item.description
        },
        {
            title: "text.edit",
            align: 'center',
            render: (item: any) => <span
                className="text-primary cursor-pointer"
                onClick={() => showModal(
                    'TRANSACTION_CATEGORY_MODAL',
                    {
                        categoryId: item.id,
                        onValidate: (category: any) => updateCategory(item.id, category)
                            .finally(
                                () => hideModal('TRANSACTION_CATEGORY_MODAL')
                            )
                    })
                }
            >
                <CustomSVG type="pencil"/>
            </span>
        },
        {
            title: 'text.info',
            width: '6%',
            align: 'center',
            render: (item: any) => <OpenButton to={`/transaction?category=${item.id}`}/>
        }
    ]

    return <AppContainer breadcrumb={[
        {label: 'menu.domain.category.overview', path: appPaths.APP},
        {label: 'menu.domain.transaction.partner.overview'},
    ]}
         title={<IntLabel label="menu.domain.transaction.category.overview"/>}
         actions={[
             <Button
                 label="btn.add.transaction.category"
                 onClick={() => showModal(
                     'TRANSACTION_CATEGORY_MODAL',
                     {
                         onValidate: (category: any) => createCategory(category)
                             .finally(() => hideModal('TRANSACTION_CATEGORY_MODAL'))
                     })
                 }/>
         ]}
    >
        <ListTable
            columns={columns}
            refresh={loading}
            fetchData={fetchTransactionsCategory}/>
    </AppContainer>
}

export default TransactionCategory;
import React from 'react';
import Modal from "react-bootstrap/Modal";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import PartnerPaymentForm from "../../../components/form/PartnerPaymentForm";

function PartnerPaymentModal(props: any) {
    const {paymentId, onValidate} = props.modalProps;

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            size="lg"
            backdrop="static"
            centered
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {getLabel("transaction.modal")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <PartnerPaymentForm
                        paymentId={paymentId}
                        onFinish={onValidate}/>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PartnerPaymentModal;

const menuLabel = {
    "menu.domain.app": "Tableau de bord",
    "menu.domain.app.description": "Manager votre compte",
    "menu.domain.app.overview": "Tableau de board",
    "menu.domain.app.profile": "Profile",

    // ------- land
    "menu.domain.transaction": "Operations financiere",
    "menu.domain.transaction.description": "Manager vos térrains",

    "menu.manage.transaction": "Opérations",
    "menu.manage.transaction.partner": "Partenaires",
    "menu.manage.transaction.category": "Poles de depense",
    "menu.domain.transaction.list": "Liste des operations",
    "menu.domain.transaction.partner": "Partenaires",
    "menu.domain.transaction.category": "Pole de depense",
    "menu.domain.transaction.overview": "Vos operations financiere",

    // ------- land
    "menu.domain.land": "Térrains",
    "menu.domain.land.description": "Manager vos térrains",
    "menu.manage.land.interest":"Gestion des intérêts fonciers",
    "menu.manage.land": "Gestion des térrains",
    "menu.domain.land.list": "Liste des térrains",
    "menu.domain.land.validation": "Validation des térrains",
    "menu.domain.land.overview": "Vos térrains",
    "menu.domain.land.contract": "Vos contracts",
    "menu.domain.land.payment": "Vos paiements",
    "menu.domain.land.details":" Informations du terrain",
    "menu.domain.land.payment.validation": "Validation des paiements",


    "menu.manage.contract": "Gestion des contracts",
    "menu.domain.contract.list": "Liste des contracts",
    "menu.domain.contract.validation": "Validé des contracts",
    "menu.domain.contract.overview":"Vos contrats",
    "menu.domain.contract.details":"Informations du contrat",

    "menu.manage.payment": "Paiements",
    "menu.domain.payment.list": "Liste des paiements",
    "menu.domain.payment.validation": "Validé des paiements",

    "menu.domain.land.reservation": "Réservations de térrains",
    "menu.domain.land.interest": "Interets sur les térrains",
    "menu.domain.land.contractor": "Clients des térrains",

    // ------- hotel
    "menu.domain.hotel": "Hôtels",
    "menu.domain.hotel.description": "Gestion des hôtels",
    "menu.domain.hotel.overview": "Vos hôtels",
    "menu.domain.hotel.contract": "Contrats des hôtels",
    "menu.domain.hotel.payment": "Paiments hôtels",
    "menu.domain.hotel.reservation": "Réservations d'hôtels",
    "menu.domain.hotel.contractor": "Clients d'hôtels",

    // ------- property
    "menu.domain.property": "Biens immobiliers",
    "menu.domain.property.description": "Manager vos biens immobiliers",
    "menu.domain.property.overview": "Vos biens immobiliers",
    "menu.domain.property.contract": "Contrats immobiliers",
    "menu.domain.property.payment": "Paiments immobiliers",
    "menu.domain.property.reservation": "Réservations immobilieres",
    "menu.domain.property.contractor": "Client immobiliers",


    // --------- market profile
    "text.profile": "Mon profile",
    "text.profile.information": "Mes informations",
    "text.profile.contracts": "Mes contrats",
    "text.profile.payments": "Mes payments",
    "text.profile.favorites": "Mes favories",

    // ----------- security
    "menu.login": "Connexion",
    "menu.logout": "Déconnexion",
    "menu.register": "Inscription",

    "menu.domain.authentication": "Authentification",
    "menu.domain.authentication.description": "Connexion",
    "menu.domain.login": "Connexion",
    "menu.domain.menu.register": "Creer un compte",

    // -----------company
    "menu.domain.company.employees":" Employés",
    "menu.domain.company":"Vos compagnies",
    "menu.domain.company.overview":"Tableau de bord",
    "menu.domain.company.employee":" Liste des employés",
    "text.last_name":"Nom",
    "text.role":"Role",
    "text.companyName":"Compagnie",
    "menu.domain.company.autorisations":"Autorisations",
    "menu.domain.company.description":"MANAGER VOS COMPAGNIES",
    "menu.domain.company.employee.overview":"Informations de l'employé",
    "menu.domain.company.employees.list":"Liste des employés",
    "menu.domain.company.employees.companyInvitations":"Gestion des invitations",
    "menu.domain.company.invitations":"Gestion des invitations",
    'menu.domain.company.docs.templates': "Gérer les templates",
    "menu.domain.company.notifications": "Notifications",
    "menu.manage.transaction.partner.contract": "Contrats des partenaires",



}

export default menuLabel;
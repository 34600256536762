import React from "react";
import {IntLabel} from "@app/i18n";

interface SideBarTabPaneProps {
    activated: boolean;
    value: any
    children: any;
}

export default function SidebarTabPane({value, children, activated} : SideBarTabPaneProps) {

    return(
        <div className={`tab-pane fade ${activated && "active show"}`} id={`kt_aside_nav_tab_${value.domain}`}>
            <div className="mx-1 mt-10 active">
                <h2 className="text-gray-800 fw-bold mb-6 mx-4"><IntLabel label={value.title}/></h2>
                <div className="menu-item mb-2">
                    <div className="menu-content pb-2">
                        <span className="menu-section text-muted text-uppercase fs-8"><IntLabel label={value.description}/></span>
                    </div>
                </div>
                <div className="mb-12">
                    {children}
                </div>
            </div>
        </div>

    )
};
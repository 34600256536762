import React, {useEffect, useState} from 'react';
import {getLabel, IntLabel} from "@app/i18n/src/components/IntLabel";
import {enumLabel} from "../../interface/Enums";

interface LandPaymentFormParams {
    onSubmit?: any;
    onCancelClick?: any;
    isLoading?: boolean;
    payment?: any;
    lang?: any
}

function LandPaymentForm(props: LandPaymentFormParams) {
    const [landPayment, setLandPayment] = useState({
        information: undefined,
        contact: undefined,
        paymentMethod: "cash",
        amount: 0,
    })
    const {lang} = props

    useEffect(() => {
        if (props.payment !== undefined) {
            setLandPayment(props.payment);
        }

        return () => {};
    }, [props.payment]);


    return (
        <form className="form-row"
              onSubmit={(e) => {
                  e.preventDefault()
                  props?.onSubmit(landPayment)
              }}>
            <div className="row mb-6">
                <div className="col-12 my-3">
                    <label>{getLabel("text.label.payment.information", lang)}</label>
                    <input
                        className="form-control"
                        required={true}
                        onChange={(e ) => setLandPayment((p: any) => ({...p, information: e.target.value}))}
                        value={landPayment?.information}
                    />
                </div>
                <div className="col-12 my-3">
                    <label>{getLabel("text.label.payment.contact", lang)}</label>
                    <input
                        className="form-control"
                        required={true}
                        onChange={(e ) => setLandPayment((p: any) => ({...p, contact: e.target.value}))}
                        value={landPayment?.contact}
                    />
                </div>
                <div className="col-12 col-md-6 my-3">
                    <label>{getLabel("text.label.payment.method", lang)}</label>
                    <select value={landPayment?.paymentMethod} className="form-select"
                            onChange={(e ) => setLandPayment(
                                (p: any) => ({...p, paymentMethod: e.target.value}))}>
                        <option value="bank_transfer">{getLabel("payment.bank_transfer", lang)}</option>
                        <option value="cash">{getLabel("payment.cash", lang)}</option>
                    </select>
                </div>
                <div className="col-12 col-md-6 my-3">
                    <label>{getLabel("text.label.payment.amount", lang)}</label>
                    <input  className="form-control"
                           type="number"
                           required={true}
                           onChange={(e ) => setLandPayment((p: any) => ({...p, amount: parseFloat(e.target.value)}))}
                            value={landPayment?.amount}
                    />
                </div>
            </div>
            <div className="row mt-5">
                <div className="form-group col-md-6 mb-6">
                    <button  className="btn  btn-secondary w-100" onClick={props.onCancelClick}>
                        {getLabel("text.onCancel", lang)}
                    </button>
                </div>
                <div className="form-group col-md-6 mb-6" >
                    <button  className="btn btn-primary w-100" disabled={props.isLoading} type="submit">
                        {getLabel("text.finish", lang)}
                    </button>
                </div>
            </div>
        </form>
    );
}

export default LandPaymentForm;
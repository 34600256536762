import {hotelRoutesPaths} from "./hotelRoutesPaths";

export const hotelMenu = [
    {
        domain: 'hotel',
        title: 'menu.domain.hotel',
        description: 'menu.domain.hotel.description',
        children: [
            {
                label: 'menu.domain.hotel.overview',
                icon: 'windows_menu',
                path: hotelRoutesPaths.HOTEL_VIEW,
                parent: 'hotel',
            },
            {
                label: 'menu.domain.hotel.contract',
                icon: 'blocks',
                path: hotelRoutesPaths.HOTEL_CONTRACT,
                parent: 'hotel',
            },
            {
                label: 'menu.domain.hotel.reservation',
                icon: 'reservation',
                path: hotelRoutesPaths.HOTEL_RESERVATION,
                parent: 'hotel',
            },
            {
                label: 'menu.domain.hotel.contractor',
                icon: 'contractor',
                path: hotelRoutesPaths.HOTEL_CONTRACTOR,
                parent: 'hotel',
            },
            {
                label: 'menu.domain.hotel.payment',
                icon: 'payments',
                path: hotelRoutesPaths.HOTEL_PAYMENT,
                parent: 'hotel',
            },
        ] as any,
    },
] as any;
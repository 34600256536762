import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {ModalProps} from "../../ModalContainer";
import {IntLabel} from "@app/i18n";
import {DualAsyncSelect} from "../../../components/form/customInputs/DualAsyncSelect";
import {createCompanyInvitation, editCompanyInvitation} from "../../../api";
import {AppTable} from "../../../index";
import {InvitationStatusBadge, PermissionLevelStatusBadge} from "../../../components/Badges";

function AppCompanyInvitationModal(props: ModalProps){
    const {lang} = props.modalProps
    const [isLoading, setLoading] = useState<boolean>(false)
    const {currentCompany, currentInvitation, title} = props.modalProps
    const [invitation, setInvitation] = useState<any>(currentInvitation ?? {})
    const action = currentInvitation?.id ? 'edit': 'create';
    const editable = currentInvitation?.status === 'created';
    const listRoles: any[] = [{land: invitation?.role?.land, hotel: invitation?.role?.hotel, real_estate: invitation?.role?.real_estate}]
    const recipients: any[] = currentInvitation?.recipients ?? [
        {value: "elkira01@gmail.com", label: "elkira01@gmail.com"},
        {value: "leonel01@gmail.com", label: "leonel01@gmail.com"}
    ]

    const onFinish = (values: any) =>{
        switch (action){
            case 'create':{
                setLoading(true)
                createCompanyInvitation({id: currentCompany},{...values}).then((res: any)=>{
                    setLoading(false);
                }).catch((e: any) => {
                    console.log(e)
                })
            }
            break;
            case "edit":{
                setLoading(true)
                editCompanyInvitation(invitation.id, {...values}).then((res:any)=>{
                    setLoading(false)
                }).catch((e: any)=>{
                    console.log(e);
                })
            }
            break;
            default:
                console.log("Nothing to do");
        }
    }

    const onChangeEmails = (values: any[])=>{
        setInvitation((inv: any)=> ({...inv, recipients: values.map((v: any) => v?.value)}))
    }

    return(
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title><IntLabel label={'text.invitation.details'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="pt-3 pb-3">
                    <div className="row gy-6">
                        {action === 'edit' && <div className="col-12">
                            <input className="form-control" type="text" value={invitation?.user.name}/>
                        </div>}
                        {action === 'create' ? <DualAsyncSelect onChange={onChangeEmails} options={recipients}/> :
                            <div className="col-12"><input className="form-control" type="email" value={invitation.recipient} /></div>
                        }
                        <div className="col-12">
                            <textarea disabled={!editable && (action !== 'create')  } required={true} name="name" className="form-control" placeholder="Message content here"
                                      onChange={(e: any) => setInvitation((inv: any)=> ({...inv, content: e.target.value})) }
                                      defaultValue={currentInvitation?.content ?? ""}
                                      style={{backgroundColor: "white"}}
                            />
                        </div>
                        {action === 'edit' && <><div className="col-9">
                            <AppTable columns={[
                                {
                                    title: "text.land",
                                    render: (item: any) => <PermissionLevelStatusBadge status={item?.land?.toUpperCase()}/> ,
                                    align: 'center',
                                },
                                {
                                    title: "text.hotel",
                                    render: (item: any) => <PermissionLevelStatusBadge status={item?.hotel?.toUpperCase()}/> ,
                                    align: 'center',
                                },
                                {
                                    title: "text.real_estate",
                                    render: (item: any) => <PermissionLevelStatusBadge status={item?.real_estate?.toUpperCase()}/> ,
                                    align: 'center',
                                }
                            ]} data={listRoles}/>
                        </div>
                        <div className="col-3">
                            <AppTable columns={[
                                {
                                    title: "text.status",
                                    render: (item: any) => <InvitationStatusBadge status={item?.status}/>,
                                    align: 'center',
                                },
                            ]} data={[{status: invitation?.status}]}/>
                        </div>
                        </>
                        }
                        <div className="col-md-6 col-sm-12 mt-6">
                            <button className={"btn w-100 btn-secondary"} style={{border: 'none'}} disabled={false} onClick={() => props.onClose()} >
                                <IntLabel label={'text.cancel'}/>
                            </button>
                        </div>
                        <div className="col-md-6 col-sm-12 mt-6">
                            <button type="submit" className={"btn w-100 btn-primary"} disabled={!editable && (action !== "create")} onClick={() => onFinish({...invitation})} >
                                {action !== 'create' ? <IntLabel label={'text.save'}/> : <IntLabel label={'text.create'}/>}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
    document.querySelector('form')?.addEventListener('submit', (e: any)=>{
        e.preventDefault()
    });

};

export default AppCompanyInvitationModal;
import React, {useEffect, useState} from 'react';
import {
    fetchPartnerContract,
    fetchTransactionPartners,
    fetchTransactionsCategory
} from "../../api/domain/company/transaction";
import {getLabel, IntLabel} from "@app/i18n/src/components/IntLabel";
import AppAsyncSelect from "../AsyncSelect/AppAsyncSelect";
import {fetchCompanyLands} from "../../api";

interface FormPartnerContractProps {
    contractId?: string,
    companyId?: string,
    onFinish: any,
    onCancel: any,
}

function FormPartnerContract(props: FormPartnerContractProps) {
    const [loading, setLoading] = useState(false)
    const [contract, setContract] = useState<any>({
        categoryId: undefined,
        propertyId: undefined,
        propertyType: undefined,
        description: undefined,
        amount: undefined,
    });

    useEffect(() => {
        if (props.contractId) {
            setLoading(true)
            fetchPartnerContract(props.contractId).then((res: any) =>{
                let data = res.data

                setContract({
                    categoryId: data?.category?.id,
                    propertyId: data.property?.id,
                    description: data.description,
                    amount: data.amount,
                })
                setLoading(false)
            })
        }

    }, [])

    return (<form onSubmit={(e) => {
        e.preventDefault()
        props.onFinish(contract)
    }}>
        <div className="form-row ">
            <div className="row">
                <div className="form-group col-md-12 my-3">
                    <label>{getLabel("text.transaction.category")}</label>
                    <AppAsyncSelect
                        fetchData={fetchTransactionsCategory}
                        onChange={(e :any )=> setContract((prev: any) => ({...prev, categoryId: e[0]?.value}))}
                        parsing={(data: any) => {
                            return {value: data.id, label: data.name}
                        }}/>
                </div>
                <div className="form-group col-md-12 my-3">
                    <label>{getLabel("text.land.title")}</label>
                    <AppAsyncSelect
                        fetchData={fetchCompanyLands}
                        onChange={(e :any )=> setContract((prev: any) => ({...prev, propertyId: e[0]?.value}))}
                        addParam={{
                            companyId: props.companyId
                        }}
                        parsing={(data: any) => {
                            return {value: data.id, label: `${data?.landTitle} - ${data?.town}`}
                        }}/>
                </div>
                <div className="form-group col-12 my-3">
                    <label>{getLabel("text.description")}</label>
                    <textarea value={contract.description}
                           required={true}
                           onChange={(e :any )=> setContract((prev: any) => ({...prev, description: e.target.value}))}
                           className="form-control"
                           placeholder={getLabel('text.description')} />
                </div>
                <div className="form-group col-12 my-3">
                    <label>{getLabel("text.amount")}</label>
                    <input value={contract.amount}
                           type="number"
                           required={true}
                           onChange={(e :any )=> setContract((prev: any) => ({...prev, amount: parseFloat(e.target.value)}))}
                           className="form-control"
                           placeholder={getLabel('text.amount')} />
                </div>
            </div>

            <div className="form-row  mt-3">
                <div className="row">
                    <div className="form-group col-md-6 mb-6">
                        <button  className="btn btn-secondary w-100"
                                 onClick={props.onCancel}><IntLabel label="text.onCancel"/>
                        </button>
                    </div>
                    <div className="form-group col-md-6 mb-6">
                        <button type="submit"
                                disabled={loading}
                                className="btn btn-primary w-100">
                            <IntLabel label="text.finish"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>);
}

export default FormPartnerContract;
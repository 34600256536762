import React, {useEffect, useState} from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import BackButton from "@app/core/src/components/common/Button/Action/BackButton";
import DetailsCardView from "../../../../../components/DetailsCard/DetailsCardView";
import {ContractList, LandPartList,PaymentList} from 'components/Lists';
import {useParams} from "react-router";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {fetchCompanyContract, fetchCompanyPayments, fetchLandContractLogs, fetchLandLogs} from "@app/core/src/api";
import {formatCurrency} from "@app/core/src/service/formatService";
import {ContractStatusBadge} from "@app/core/src/components/Badges";
import {landRoutePaths} from "../../routing/landRoutePaths";
import LandContractDetails from "./LandContractDetails";
import InterestList from "../../../../../components/Lists/InterestList";
import LogListTable from "../../../../../components/Lists/LogListTable";


function getTabs(activeTab: string, setActiveTab: (value: (((prevState: string) => string) | string)) => void) {
    return [
        {label: 'text.details', isActive: activeTab === '', tab: '', handle: (value: any) => setActiveTab(value)},
        {label: 'text.payments', isActive: activeTab === 'payments', tab: 'payments', handle: (value: any) => setActiveTab(value)},
        {label: 'text.parts', isActive: activeTab === 'parts', tab: 'parts', handle: (value: any) => setActiveTab(value)},
        {label: 'text.interests', isActive: activeTab === 'interests', tab: 'interests', handle: (value: any) => setActiveTab(value)},
        {label: 'text.logs', isActive: activeTab === 'logs', tab: 'logs', handle: (value: any) => setActiveTab(value)},
    ];
}

function getAmountValues(contract: any) {
    return [
        {
            label: 'contract.amount',
            value: formatCurrency(contract?.amount ?? 0)
        },
        {
            label: 'contract.amount.cumulated',
            value: formatCurrency(contract?.paidAmount ?? 0)
        },
        {
            label: 'contract.amount.remain',
            value: formatCurrency(contract?.remainAmount ?? 0)
        },
        {
            label: 'contract.penalties',
            value: formatCurrency(contract?.interestAmount ?? 0)
        },
    ];
}

function LandDetailsView({}: any) {
    const {id, landTitle} = useParams();
    const [contract, setContract] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [activeHash, setActiveHash] = useState(window.location.hash?.substring(1))
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)

    useEffect(() => {
        setLoading(true)
        fetchCompanyContract(
            {id: id, companyId: currentCompany}
        ).then((res: any) => {
            setContract(res.data)
            setLoading(false)
        }).catch(() => setLoading(false))
        return () => {}
    }, [id]);

    useEffect(()=>{
        setActiveHash(window.location.hash?.substring(1))
    },[window.location.hash?.substring(1)])

    function getContent(tab: string) {
        switch (tab) {
            case '':
                return <LandContractDetails contract={contract} loading={isLoading} companyId={currentCompany ?? ''}/>;
            case 'parts':
                return <LandPartList companyId={currentCompany?? ''}
                     query={{'uuid[contract_id]': id}}/>;
            case 'interests':
                return <InterestList companyId={currentCompany ?? ''}  query={{ 'uuid[contract_id]': id }}/>;
            case 'payments':
                return <PaymentList contractId={contract?.id} canPay={true} companyId={currentCompany ?? ''}
                    query={{ 'uuid[contract_id]': id }} loading={isLoading} />;
            case 'logs':
                return <LogListTable
                    fetchData={fetchLandContractLogs}
                    addQuery={{ 'uuid[contract_id]': id }}
                    addParams={{ companyId: currentCompany }} />;
            default:
                return 'Unknown step';
        }
    }

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.contract.overview', path: landRoutePaths.LAND_CONTRACT},
            {label: 'menu.domain.contract.details'},
        ]} actions={[
            <BackButton />
        ]} title={<IntLabel label="menu.domain.contract.overview"/>}>
            <div className="row">
                <div className="col-12">
                    <DetailsCardView
                        title={`${contract?.land.title ?? ''} - [${landTitle ?? '---'}]`}
                        loading={isLoading}
                        badges={<ContractStatusBadge status={contract?.status}/>}
                        description={contract?.description ?? ''}
                        tabs={getTabs(activeHash, setActiveHash)}
                        amountValues={getAmountValues(contract)}
                    />
                </div>
                <div className="col-12 mt-5">
                    {getContent(activeHash)}
                </div>
            </div>
        </AppContainer>
    );
}

export default LandDetailsView
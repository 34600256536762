import React from 'react';
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {PropertyInterestBadge} from "@app/core/src/components/Badges";
import {IntLabel, IntlDate} from "@app/i18n";
import {ListTable} from "@app/core";

interface LogListTableProps {
    fetchData: any,
    addQuery?: object,
    addParams: object,
}
function LogListTable(props: LogListTableProps) {

    const columns: any[] = [
        {
            title: "text.user.name",
            render: (item: any) => <strong>{item.user?.name}</strong>
        },
        {
            title: "text.action",
            render: (item: any) => <IntLabel label={`text.${item.action}`}/>
        },
        {
            title: "text.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item?.createdAt}/>
        },
    ]

    return (
        <ListTable columns={columns}
           fetchData={props.fetchData}
           addQuery={{ 'order[created_at]': 'DESC', ...props.addQuery }}
           addParam={props.addParams}/>
    );
}

export default LogListTable;
import {callAction, callActionWithCustom, callActionWithId} from "../../helpers";

export const companyLogin = callAction<any>('/company/api/login', 'post', false);
export const companyRegister = callAction<any>('/company/api/register', 'post', false);

export const fetchUserCompany = callAction<any>('/company/api/user', 'get', false);
export const fetchUserCompanyDetails = callActionWithId<any>('/company/api/user/{id}', 'get', false);

export const fetchCompanyAllEmployees = callActionWithCustom<any>('/company/api/employee', 'get', false);
export const fetchEmployeeForSelect = callAction<any>('/company/api/employee/for-select', 'get', false);

export const fetchCompanyPermissions = callActionWithCustom('/company/api/{companyId}/permission/{domain}', 'get', false);
export const editCompanyPermissions = callActionWithCustom('/company/api/{companyId}/permission/edit/{domain}', 'patch', false);
export const resetCompanyPermissions = callActionWithCustom('/company/api/{companyId}/permission/reset/{domain}', 'patch', false);

export const fetchCompanyAllInvitations = callActionWithCustom<any>(`/company/api/{id}/invitation`, 'get', false);
export const fetchUserAllInvitations = callAction<any>(`/company/api/invitation/user`, 'get', false);
export const createCompanyInvitation = callActionWithCustom<any>(`/company/api/{id}/invitation/add`, 'post', true);
export const editCompanyInvitation = callActionWithId<any>(`/company/api/invitation/add/{id}`, 'patch', true);

export const addCompany = callAction<any>(`/company/api/add`, 'post', true);


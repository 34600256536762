const sectionLabel = {
    // ------- land
    "section.land": "Liste des terrains",
    "section.land.description": "Description des terrains",
    "section.land.details": "Informations sur le terrain",
    "section.land.details.description": "Informations détaillées",
    "section.land.part": "Parcelle de terrain",
    "section.land.part.description":"Description",
    "section.contracts.details":"Information sur votre contrat",
    "section.contracts.details.description":"Gerer et payer les tranche de votre contrat",

    // ------- admin
    "section.contracts":"Liste des Contracts",
    "section.contracts.description":"Informations sur le contract",
    "section.information.payments":"Mes informations",
    "section.information.description":"Informations détaillées",
    "section.profile.payments":"Paiements",
    "section.profile.payments.description":"Informations sur le paiement",

    "text.advises": "Conseils",
    "text.profile": "Profile",

    "section.advices": "Conseils et avis",
    "section.advices.description": "Conseils et avis",
    "section.announcements": "Informations",
    "section.announcements.description": "Annonces et information",
}

export default sectionLabel;
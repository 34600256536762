import React from 'react';
import { MapContainer as StreetMapContainer, TileLayer,  Polygon, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {locationMarket} from "../assets";
import './map-style.scss'

interface MapContainerProps {
    point?: any,
    className?: any,
    zoom?: number,
    polygon?: any[],
}

const myIcon = new L.Icon({
    iconUrl: locationMarket,
    className: 'marker-color',
    iconRetinaUrl: locationMarket,
    popupAnchor:  [-0, -0],
    iconSize: [25, 40],
});

const polyline = [
    {lat: 4.068050553561782, lng: 11.583594493099579},
    {lat: 4.06789043984638, lng: 11.584160192052817},
    {lat: 4.067834426241043, lng: 11.58449126438019},
    {lat: 4.067722048155679, lng: 11.585869288554248},
]

function MapContainer(props: MapContainerProps) {
    let child = <p>Erreur d'affichage</p>

    try {
        if (Math.abs(props?.point[0] ?? 0) <= 90 || Math.abs(props?.point[0] ?? 1) <= 180) {
            child = <StreetMapContainer className={props.className} style={{ height: '100%' }}
                center={props.point ?? [3.8600704, 11.5212288]}
                zoom={props.zoom ?? 12} scrollWheelZoom={false}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker icon={myIcon} position={props.point ?? [3.8600704, 11.5212288]}>
                    {/*<Popup>*/}
                    {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                    {/*</Popup>*/}
                </Marker>
                {props.polygon !== undefined && <Polygon pathOptions={{color: 'red'}} positions={props.polygon  ?? polyline}/>}
            </StreetMapContainer>
        }
    }catch (e) {
        console.log(e)
    }

    return child;
}

export default MapContainer;
import React, {useEffect, useState} from "react";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel} from "@app/i18n";
import AppContainer from "components/Layout/AppContainer/AppContainer";
import DetailsCardView from "../../../../../components/DetailsCard/DetailsCardView";
import TemplateEditor from "./compoenents/TemplateEditor";
import {NotificationTemplateType} from "../../../../../enum";

export default function ManageTemplatePage(){
    let hash = window.location.hash?.substring(1)
    const [activeHash, setActiveHash] = useState('payment_done')
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        setActiveHash(hash)
        return () => {};
    }, [hash]);

    useEffect(() => {
        let tab: any = []

        Object.values(NotificationTemplateType).map((item: any) => tab.push({
            label: `text.enum.${item}`,
            isActive: activeHash === item,
            tab: item,
            handle: (value: any) => setActiveHash(value)
        }))

        setTabs(tab)
        return () => {};
    }, [activeHash]);


    return(
        <AppContainer
            breadcrumb={[
                {label: 'menu.domain.app.overview', path: appPaths.APP},
                {label: 'menu.domain.company.docs.templates'},
            ]}
            title={<IntLabel label="menu.domain.company.docs.templates"/>}
        >
            <div className="row">
                <div className="col-12">
                    <DetailsCardView
                        noCard={true}
                        title={ 'text.templates.models'}
                        loading={false}
                        description={'Templates'}
                        tabs={tabs}
                    />
                </div>
                <div className="col-12 mt-5">
                    <TemplateEditor type={activeHash}/>
                </div>
            </div>
        </AppContainer>
        )
}
import React from 'react';
import AppLayout from "@apps/app/src/components/Layout/index";
import {transactionRoutePaths} from "./transactionRoutePaths";
import TransactionPage from "../page/transaction/TransactionPage";
import TransactionCategoryDetails from "../page/category/TransactionCategoryDetails";
import TransactionPartnerDetails from "../page/partner/TransactionPartnerDetails";
import TransactionPartner from "../page/partner/TransactionPartner";
import TransactionCategory from "../page/category/TransactionCategory";
import PartnerContractPage from "../page/contract/PartnerContractPage";

const TransactionRoutes = [
    {
        path: transactionRoutePaths.TRANSACTION,
        element: <AppLayout/>,
        children: [
            {
                path: transactionRoutePaths.TRANSACTION,
                element: <TransactionPage/>,
            },
            {
                path: transactionRoutePaths.PARTNER,
                element: <TransactionPartner/>,
            },
            {
                path: transactionRoutePaths.PARTNER_CONTRACT,
                element: <PartnerContractPage/>,
            },
            {
                path: transactionRoutePaths.PARTNER_VIEW,
                element: <TransactionPartnerDetails/>,
            },
            {
                path: transactionRoutePaths.CATEGORY,
                element: <TransactionCategory/>,
            },
            {
                path: transactionRoutePaths.CATEGORY_VIEW,
                element: <TransactionCategoryDetails/>,
            },
        ]
    }
]

export default TransactionRoutes;

import {Link} from "react-router-dom";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import {formatCurrency} from "@app/core/src/service/formatService";
import {IntlDate} from "@app/i18n";
import React from "react";

export const validationColumn = [
    {
        title: "text.contractor",
        render: (item: any) => <Link to={`/client/${item.creator.id}`} className="fw-bold fs-5">{item.contractor.name}</Link>
    },
    {
        title: "text.creator",
        render: (item: any) => <Link to={`/employee/${item.creator.id}`} className="fw-bold fs-5">{item.creator.name}</Link>
    },
    {
        title: "text.lands",
        render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land.landTitle}</Link>
    },
    {
        title: "contract.period",
        align: 'right',
        render: (item: any) => `${item.period} ${getLabel("text.months")}`
    },
    {
        title: "text.square.price",
        align: 'right',
        render: (item: any) => <strong>{formatCurrency(item.squarePrice)}</strong>
    },
    {
        title: "text.amount",
        align: 'right',
        render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
    },
    {
        title: "text.month.amount",
        align: 'right',
        render: (item: any) => <strong>{formatCurrency(item.amountPerMonth)}</strong>
    },
    {
        title: "text.date",
        align: 'right',
        render: (item: any) => <IntlDate date={item.createdAt}/>
    },
]
const TextLabels ={
    "text.real.estate":"Real estate agent",
    "text.space.pro":"Pro space",
    "text.newsletter":"Newsletter",
    'text.follow_us':'Follow Us',
    'text.call_us':'Call Us',
    'text.page_not_found':"Page Not Found",
    "text.phone_number": "Phone number",
    "text.address_number": "Location",
    "text.email_address": "E-mail",
    "text.connexion":"Login",
    "text.connexion.question":" Login in",
    "text.export.data": "Export data",
    'text.export.excel.data': "Export to Excel format",
    'text.export.pdf.data': "Export to PDF format",


    "text.details": "Details",
    "text.land.parts": "Land plot(s) ",
    "text.contracts": "Contracts",
    "text.medias": "Media",
    "text.interests": "Interest",
    "text.views": "views",


    "text.search.more": "Load more",
    "text.no.more.data": "No items to load",
    "text.loading": "Loading...",
    "text.price": "Price",
    "text.booked.land": "Reserve Land",
    "text.summary": "Total Amount",
    "text.land.selection": "Your land selection",

    "text.sold": "Sold",
    "text.add.land": "Choose Land",
    "text.location": "According to area",
    "text.budget": "According to budget",
    "text.measurements": "According to Surface",
    "text.rooms": "Number of rooms blablablabla",

    "text.my.profile": "My Profile",
    "land.part": "Lot(s)",
    "text.lands": "Land(s)",
    "land.part.available": "Land(s) available",
    "land.has.parts": "Plotted land",

    "text.creation.date": "Creation date",
    "text.success.operation": "Operation completed",
    "text.choose.element": "Select",
    "text.choose.sorting": "Sort by",

    // meny
    "text.home": "Home",
    "text.not.has.account": "Don't have an account yet?",
    "text.has.account": "Already have an account?",
    "text.land.catalog": "Land Catalog",
    "text.login": "Login",
    "text.register": " Create your account",
    "text.save": "Save",


    //select
    "text_select_menu":"Open this select menu",
    "text_growing_budget":"Growing budget",
    "text_decreasing_budget":"Decreasing budget",
    "text_growing_area":"Growing area",
    "text_decreasing_area":"Decreasing area",
    "text_land_relevance":"Land relevance",

    "text.firstName": "Name(s)",
    "text.lastName": "First name(s)",
    "text.password": "Password",
    "text.password.confirm": "Confirm your password",
    "text.email": "Email address",
    "text.email.notice": "",
    "text.phoneNumber": "Phone Number",
    "text.username": "Your credentials",
    "text.username.notice": "Your credentials can be your email or phone number",
    "text.login.question": "Login",
    "text.register.question": " Create your account",
    "text.password.notice":"Choose a strong password",


    // advices and announcements
    "text.advices": "Advice",
    "text.announcements": "News and Announcements",
    "text.all": "All posts",
    "text.tops": "Most Popular",
    "text.all.articles": "All articles",
    "text.land.information": "Land information",
    "text.land.title.information": "Land od title",
    "text.privilege": "Prerogatives",

    // contracts
    "contract.ref": "Contract reference",
    "contract.company": "Affiliate Company",
    "contracts.count":"Total Count",
    "contracts.value": "Total amount",
    "contracts.paid": "Amount paid",
    "contracts.remain": "Remaining amount",
    "contract.amount":" Contract Amount",
    "contract.amount.cumulated":"Cumulated Amount",
    "text.contractor":"Customer",
    "text.creator":"Initiator",
    "text.info": "info",
    "contract.contractor":"customer",
    "contract.creator":"Author",
    "contract.next.payment":"Payment deadline",
    "contract.amount.remain":" Amount remaining",
    "contract.penalties": "Penalty fee",
    "contract.information":"Contract information",
    "contract.slug":"lot",
    "contract.land":" Contract ID",
    "contract.period":"Period",
    "contract.start": "Date of Signing",
    "text.edit.contractor.label": "Edit",
    "text.payments": "Payment",
    "text.parts": "Lots",
    "text.contact": "Contact",
    "text.contract.amount": "Contract amount",
    "text.contract.start.date": "Contract start date",


    "property.available": "property available",
    "property.sold": "property sold",



    "text.add.company": "Create your company",
    "text.add.company.description": "When you create a company, you must provide in" +
        "formations such as company name, address, telephone number and field of activity. ",


    //land
    "text.land.title":"Registration number",
    "land.information":"Description",
    "land.part.count":"Number of lots",
    "land.square.price":"Price square ",
    "land.area":"Land area",
    "land.paid.amount":"Amount paid",
    "land.values":"Land price",
    "land.count":"Number of lands",
    "land.value":"Lands amount",
    "land.paid":"Amount paid",
    "update.date": "Last updated",
    "text.area":"Area",
    "text.published":"Published",
    "text.label.description":"Description",
    "text.label.Landtitle":"Registration number",
    "text.label.title":"Title",
    "text.add.land.label": "New land",
    "text.label.landvalue":"Land amount",
    "text.label.square.price":"Square price ",
    "text.finish":"Validate",
    "text.onCancel":"Cancel",
    "text.edit.land.label":"Edit",
    "text.edit":"Edit",
    "text.delete":"Delete",
    "text.bloc": "Part(s)",
    "text.land.part.number": "Land part number",
    "text.number":"Quantity",
    "text.square.price":"Price square ",
    "text.sold.percent":"Sold percent",
    "text.label.land.bloc": "Land block",
    "text.paid.amount": "Paid amount",

    "text.label.land.title": "Land title",
    "text.label.town": "Town",
    "text.label.visit.price": "Cost for visits",
    "text.label.latitude": "Latitude",
    "text.label.longitude": "Longitude",
    "text.label.axisX": "X coordonate",
    "text.label.axisY": "Y coordonate",


    //admin
    "text.date":"Date",
    "text.amount":"Amount",
    "text.payment.intent":"Intent",
    "text.payment.method" :"Payment method ",
    "text.contract":"Contract",
    "text.status":"Status ",
    "text.printed":"Print",
    //status
    'text.ongoing': "Ongoing",
    'text.draft': "Draft",
    'text.rejected': "Rejected",
    'text.finished': "Finished",
    "text.validate":"info",
    "payment.count":" Number of transactions",
    "payment.value":"Total amount",
    "text.author":"Customer",
    'payment.pending': "pending",
    'payment.failed': "failed",
    'payment.done': "done",

//Company
    "text.actions":"Permissions",
    "text.cancel":"Back",
//invitation
    "invitation.date.created":"Created date",
    "invitation.recipient":"Recipient",
    "invitation.recipient.email":"Email",
    "text.satus":" Status",
    "text.add.contractor.label":"New contract",
    "text.invitations.list":"List of invitations",
    "text.invitations.all":"General",
    "text.invitations.user":" Users",
    "text.choose.lang": 'Language',

    "property.draft": "Draft",
    "property.published": "Published",
    "property.unpublished": "Unpublished",

    "text.select.element": "Select an element",
    'text.filter.status': "Filter on status",
    "text.user.name": "Username",

    //select
    "text.select.date": "Select date",
    "text.sort.by.created.date.desc": "Sort by decreasing date",
    "text.sort.by.created.date.asc": "Sort by increasing date",
    "text.sort.by.highest.payer": "Filter by non-latecomers",
    "text.sort.by.lowest.payer": "Filter by latecomers",
    "text.due.amount": "Due amount",
    "text.payment.status": "Payment status",
    "text.decided": "Decision",
    "text.declined": "Decline",
    "total.interest.count": "Interests count",
    "total.paid.interest.count": "Paid interests",
    "total.interest.amount": "Interests total amount",
    "total.paid.interest.amount": "Paid interests total amount",
    "text.add.land.payment.label": "Add a payments for land",
    'text.register.new.company': "Register a new company",

    "text.label.payment.information": "Payment informations",
    "text.label.payment.contact": "Payment phone",
    "text.label.payment.method": "Payment method",
    "text.label.payment.amount": "Payment amount",

    "payment.bank_transfer": "bank transfer",
    "payment.cash": "cash payment",
    'text.land': "Land",

    //contractor
    'text.contract.number': "Contract Number",
    "text.contractor.name": "Contractor Name",
    "text.contractor.address": "Contractor Address",
    'contractor.count': "Contracts count",
    "contractor.total.paid": "Total amount paid",
    'text.roles': "Roles",
    'text.infos': "Informations",
    'text.complete.name': "Full name",
    'text.working.company': "Company",
    'text.created.at': "Date created",
    'text.added.at': "Date added",
    'text.detailed.infos': "Detailed informations",
    'text.edit.infos': "Edit informations",


}

export default TextLabels;
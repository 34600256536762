import React, {useEffect} from "react";
import {appPaths} from "@apps/app/src/ui/pages/app/routing/appRoutesPaths";
import {Link} from "react-router-dom";
import CustomSVG from "@app/core/src/components/icons/svg";
import {onToggleLanguageDrawer} from "../../../helpers";
import {useCompanySecurity} from "../../../../../security/useCompanySecurity";
import {useLocation} from "react-router";

interface AppPrimarySidebarProps {
    navItemConfig: any[];
    logo: any;
    setTarget: any;
    targeted: any;
}

function PrimaryNavItem(props: { value: any, setTarget: any, targeted: any})  {
    const {pathname} = useLocation()

    useEffect(() => {
        pathname.includes(props.value.target) && props.setTarget(props.value.target)
    }, [pathname])

    return <li className="nav-item mb-10" title={props.value.title}
               onClick={() => props.setTarget(props.value.target)}>
        <a className={`nav-link btn btn-custom btn-icon 
            ${props.targeted === props.value.target ? "active" : ""}`}>
            <span className="svg-icon svg-icon-2x">
                <CustomSVG type={props.value.icon}/>
            </span>
        </a>
    </li>;
}

function AppPrimarySidebar({navItemConfig, logo, setTarget, targeted}: AppPrimarySidebarProps) {
    const {canAccess} = useCompanySecurity();

    return(
        <div className="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
            <div className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10">
                <Link to={appPaths.APP}
                      onClick={() => document.body.setAttribute('data-kt-aside-minimize', 'off')}>
                    <img alt="Logo" src={logo} className="h-50px" />
                </Link>
            </div>

            <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0">
                <div className="hover-scroll-y mb-10" >
                    <ul className="nav flex-column">
                        {navItemConfig.map((value, index) =>
                            // (value?.access === undefined || canAccess(value?.access)) &&
                                <PrimaryNavItem key={index} value={value}
                                        setTarget={setTarget} targeted={targeted}
                                />
                        )}
                    </ul>
                </div>
            </div>

            <div className="aside-footer d-flex flex-column align-items-center flex-column-auto">
                <div className="btn btn-icon btn-active-dark mb-2" onClick={() => onToggleLanguageDrawer()}>
                    <span className="svg-icon-lg-1">
                        <CustomSVG type="language"/>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AppPrimarySidebar;
const sectionLabel = {
    // ------- land
    "section.land": "List of lands",
    "section.land.description": "Land information",
    "section.land.details": "Land",
    "section.land.details.description": "Land information",

    // ------- admin
    "section.contracts":"List of contracts",
    "section.contracts.description":"Contracts information",
    "section.information.payments":"Information",
    "section.information.description":"Detailed information",
    "section.profile.payments":"payments",
    "section.profile.payments.description":"information payments",
    "section.land.part" : "Land plot",
    "section.land.part.description":"Descritption",
    "text.advises": "Advises",
    "text.profile": "Profile",
}

export default sectionLabel;
import Labels from './labels/lables.en'
import LabelsFra from './labels/lables.fr'

export const Language = <any>{
  ENG: 'en',
  FRA: 'fr',
};

export const LanguageNameMapping = <object>{
  [Language.ENG]: 'English',
  [Language.FRA]: 'Français',
};

export const LanguagesBundle = <any>{
  [Language.ENG]: Labels,
  [Language.FRA]: LabelsFra,
};

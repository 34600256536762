export enum NotificationTemplateType {
    PAYMENT_DONE = 'payment_done',
    PAYMENT_ALERT = 'payment_alert',
    PAYMENT_REMIND = 'payment_remind',
    CONTRACT_VALIDATED = 'contract_validated',
    CONTRACT_FINISHED = 'contract_finish',
    CONTRACT_CANCELLED = 'contract_cancelled',
}

export enum NotificationVariable {
    CONTRACT_REFERENCE = 'CONTRACT_REFERENCE',
    CONTRACT_AMOUNT = 'CONTRACT_AMOUNT',
    CONTRACT_PERIOD = 'CONTRACT_PERIOD',
    CONTRACT_REMAIN = 'CONTRACT_REMAIN',
    CONTRACT_PAYMENT_INTERVAL = 'CONTRACT_PAYMENT_INTERVAL',
    PAYMENT_AMOUNT = 'PAYMENT_AMOUNT',
    PAYMENT_DATE = 'PAYMENT_DATE',
    CONTRACTOR_NAME = 'CONTRACTOR_NAME',
    COMPANY_NAME = 'COMPANY_NAME',
    LAND_TITLE = 'LAND_TITLE',
    MISSED_PERIOD = 'MISSED_PERIOD',
    NEXT_PAYMENT_DATE = 'NEXT_PAYMENT_DATE',
}
import React, {useContext, useState} from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {AnalyticsTable, Button} from "@app/core";
import {fetchCompanyPayments} from "@app/core/src/api";
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {PaymentStatusBadge} from "@app/core/src/components/Badges";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import {enumLabel, PaymentMethod, PaymentStatus} from "@app/core/src/interface/Enums";
import EnumSelect from "@app/core/src/components/EnumSelect";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import moment from "moment";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import IntlContext from "@app/i18n/src/context/IntlContext";

function LandPaymentView() {
    const {lang} = useContext(IntlContext)
    const [status, setStatus] = useState<any | undefined>(undefined);
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});

    const columns: any[] = [
        {
            title: "text.author",
            render: (item: any) => <Link to={`/employee/${item.author.id}`} className="fw-bold fs-5">{item.author.name}</Link>
        },
        {
            title: "text.contract",
            render: (item: any) => <Link to={`/land/contract/${item.contract.id}`} className="fw-bold fs-6">{item.contract.reference}</Link>
        },
        {
            title: "text.lands",
            render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land.landTitle}</Link>
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.contact",
            render: (item: any) => item.contact
        },
        {
            title: 'text.payment.method',
            render: (item: any) => <IntLabel label={enumLabel(PaymentMethod, item.paymentMethod)}/>
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => <PaymentStatusBadge status={item.status}/>
        },
        {
            title: 'text.date',
            render: (item: any) => <IntlDate date={item.createdAt} dateFormat="DD MMMM YYYY, H:m"/>
        },
        {
            title: 'text.printed',
            width: '10%',
            render: (item: any) => item.status === 'done' ?
                <PrintButton url={`/market/api/bill/payment/print/${item.id}`}/>
                : ''
        },
    ]

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.payment'},
        ]}
        actions={[
            <select  className="form-select form-select-sm w-100">
                <option selected value={""}>{getLabel("text.export.data", lang)}</option>
                <option value="excel">{getLabel("text.export.excel.data", lang)}</option>
                <option value="pdf">{getLabel("text.export.pdf.data", lang)}</option>
            </select>,
        ]}
        title={<IntLabel label="menu.domain.land.payment"/>}>
            <AnalyticsTable columns={columns} fetchData={fetchCompanyPayments}
                cards={[
                    {label: 'payment.count', render: (item: any) => item?.total ?? 0},
                    {label: 'payment.value', render: (item: any) => formatCurrency(item?.totalPayment ?? 0)},
                ]}
                addQuery={dateRange.startDate === null ? {
                    'order[created_at]': 'DESC',
                    'equal[status]' : status
                } : {
                    'order[created_at]': 'DESC',
                    'equal[status]' : status,
                    'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                }}
               addParam={{ companyId: currentCompany }}>
                <div className="row">
                    <div className="col-md-4 col-12">
                        <EnumSelect enum={PaymentStatus}
                                    onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                    </div>
                    <div className="col-md-4 col-12">
                        <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                    </div>
                </div>
            </AnalyticsTable>
        </AppContainer>
    );
}

export default LandPaymentView;
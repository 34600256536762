import React, {useState} from 'react';
import {ModalProps} from "../ModalContainer";
import Modal from "react-bootstrap/Modal";
import {IntLabel} from "@app/i18n";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import {addPaymentLink} from "../../api";
import toast from "react-hot-toast";

function GeneratePaymentLinkModal(props: ModalProps) {
    const {contractId, handleSubmit} = props.modalProps;
    const [loading, setLoading] = useState(false)
    const [designation, setDesignation] = useState(getLabel("text.payment.link.placeholder"))

    const onHandleSubmit = () => {
        setLoading(true)
        addPaymentLink({
            contractId: contractId,
            designation: designation,
        }).then(() => {
            toast.success("text.success.message")
            handleSubmit()
            setLoading(false)
            props.onClose()
        }).catch((res: any) => {
            setLoading(false)
            toast.error(res.message ?? "text.error.message")
        })
    }

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title><IntLabel label="text.generate.payment.link"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12 mb-6">
                        <input className="form-control"
                               onChange={(e )=> setDesignation(e.target.value)}
                               defaultValue={designation}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-6">
                        <button  className="btn  btn-secondary w-100"
                                 onClick={props.onClose}>
                            <IntLabel label="btn.close"/>
                        </button>
                    </div>
                    <div className="col-md-6 mb-6">
                        <button  className="btn btn-primary w-100"
                             onClick={onHandleSubmit} disabled={loading}>
                            <IntLabel label="text.finish"/>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default GeneratePaymentLinkModal;

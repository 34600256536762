import React, {useState} from 'react';
import {useModal} from "@app/core/src/modals";
import {useLocation} from "react-router";
import useTransactionController from "../../controller/useTransactionController";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {TransactionBadge} from "@app/core/src/components/Badges";
import {IntlDate} from "@app/i18n";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import CustomSVG from "@app/core/src/components/icons/svg";
import {fetchTransactions} from "@app/core/src/api/domain/company/transaction";
import moment from "moment";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {TransactionType} from "@app/core/src/interface/Enums";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import {AnalyticsTable} from "@app/core";

function TransactionAnalytics({refresh}: any) {
    const {showModal, hideModal} = useModal()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {updateTransaction} = useTransactionController()
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [status, setStatus] = useState<any | undefined>(undefined);
    const [dateRange, setDateRange] = useState<any>({
        startDate: null,
        endDate: null
    });

    const category = searchParams.get("category");
    const partner = searchParams.get("partner");

    const columns: any[] = [
        {
            title: "text.author",
            render: (item: any) => <strong>{item.staff.name}</strong>
        },
        {
            title: "text.beneficiary",
            render: (item: any) => <strong>{item.beneficiary}</strong>
        },
        {
            title: "text.category",
            render: (item: any) => <Link to={`/transaction/category/${item.category.id}`} className="fw-bold fs-5">
                {item.category.name}
            </Link>
        },
        {
            title: "text.partner",
            render: (item: any) => item.partner === null ?
                '' : <Link to={`/transaction/category/${item.partner.id}`} className="fw-bold fs-5">
                    {item.partner.name}
                </Link>
        },
        {
            title: "text.description",
            render: (item: any) => item.description
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => formatCurrency(item.amount)
        },
        {
            title: "text.status",
            width: '10%',
            render: (item: any) => <TransactionBadge status={item.type}/>
        },
        {
            title: "text.date",
            align: 'right',
            width: '20%',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: 'text.printed',
            width: '10%',
            render: (item: any) => <PrintButton url={`/api/transaction/print/${item.id}`}/>
        },
        {
            title: "text.edit",
            align: 'center',
            render: (item: any) => <span
                className="text-primary cursor-pointer"
                onClick={() => showModal(
                    'TRANSACTION_MODAL',
                    {
                        transactionId: item.id,
                        companyId: currentCompany,
                        onValidate: (transaction: any) => updateTransaction(item.id, transaction)
                            .finally(
                                () => hideModal('TRANSACTION_MODAL')
                            )
                    })
                }
            >
                <CustomSVG type="pencil"/>
            </span>
        },
    ]

    return <AnalyticsTable columns={columns}
       fetchData={fetchTransactions}
       refresh={refresh}
       cards={[
           {label: 'transaction.count', render: (item: any) => item?.count  ?? 0},
           {label: 'transaction.debit', render: (item: any) => formatCurrency(item?.debit ?? 0)},
           {label: 'transaction.credit', render: (item: any) => formatCurrency(item?.credit ?? 0)},
           {label: 'transaction.balance', render: (item: any) => formatCurrency(item?.balance ?? 0)},
       ]}
       addQuery={dateRange.startDate === null ? {
           'order[created_at]': 'DESC',
           'equal[type]' : status,
           'uuid[partner_id]' : null === partner ? undefined : partner,
           'uuid[category_id]' : null === category ? undefined : category,
       } : {
           'order[created_at]': 'DESC',
           'equal[type]' : status,
           'uid[partner_id]' : partner,
           'uid[category_id]' : category,
           'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
       }}>
        <div className="row">
            <div className="col-md-4 col-12">
                <EnumSelect enum={TransactionType} placeholder='text.filter.status'
                            onChange={(e: any) => setStatus(e.target.value)} value={status}/>
            </div>
            <div className="col-md-4 col-12">
                <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
            </div>
        </div>
    </AnalyticsTable>;
}

export default TransactionAnalytics;
import {propertyRoutePaths} from "./propertyRoutePaths";

export const propertyMenu = [
    {
        domain: 'property',
        title: 'menu.domain.property',
        description: 'menu.domain.property.description',
        children: [
            {
                label: 'menu.domain.property.overview',
                icon: 'squared',
                path: propertyRoutePaths.PROPERTY_VIEW,
                parent: 'property',
            },
            {
                label: 'menu.domain.property.contract',
                icon: 'blocks',
                path: propertyRoutePaths.PROPERTY_CONTRACT,
                parent: 'property',
            },
            {
                label: 'menu.domain.property.reservation',
                icon: 'reservation',
                path: propertyRoutePaths.PROPERTY_RESERVATION,
                parent: 'property',
            },
            {
                label: 'menu.domain.property.contractor',
                icon: 'contractor',
                path: propertyRoutePaths.PROPERTY_CONTRACTOR,
                parent: 'property',
            },
            {
                label: 'menu.domain.property.payment',
                icon: 'payments',
                path: propertyRoutePaths.PROPERTY_PAYMENT,
                parent: 'property',
            },
        ],
    },
] as any;
import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {IntLabel, IntlDate} from "@app/i18n";
import {PaymentStatusBadge} from "@app/core/src/components/Badges";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import {Button, ListTable} from "@app/core";
import {fetchCompanyPayments} from "@app/core/src/api";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {enumLabel, PaymentMethod, PaymentStatus} from "@app/core/src/interface/Enums";
import {useModal} from "@app/core/src/modals";
import CustomSVG from "@app/core/src/components/icons/svg";
import IntlContext from "@app/i18n/src/context/IntlContext";

interface PaymentListProps {
    companyId: string,
    contractId?: string,
    query?: object,
    loading?:boolean
    canPay?:boolean
}

function PaymentList(props: PaymentListProps) {
    const {showModal} = useModal()
    const {lang} = useContext(IntlContext)
    const [status, setStatus] = useState<any | undefined>(undefined);

    const columns: any[] = [
        {
            title: "text.author",
            render:  (item: any) =><Link
                to={`/employee/${item.author.id}`}
                className="fw-bold fs-5">{item.author.name}</Link>
        },
        {
            title: "text.contract",
            render: (item: any) => <Link
                to={`/land/contract/${item.contract.id}`}
                className="fw-bold fs-6">{item.contract.reference}</Link>
        },
        {
                title: "text.lands",
                render: (item: any) => <Link
                    to={`/land/${item.land.id}`}
                    className="fw-bold fs-5">{item.land.landTitle}</Link>
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.contact",
            render: (item: any) => (props.loading == undefined) && item.contact
        },
        {
            title: 'text.payment.method',
            render: (item: any) => <IntLabel label={enumLabel(PaymentMethod, item.paymentMethod)}/>
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => <PaymentStatusBadge status={item.status}/>
        },
        {
            title: 'text.date',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: "text.edit",
            align: 'center',
            render: (item: any) => (item.status === 'pending' || item.paymentMethod !== 'orange_money_cameroon')  ? <span
                className="text-primary cursor-pointer"
                onClick={() => showModal(
                    'EDIT_LAND_PAYMENT_MODAL',
                    {
                        paymentId: item?.id,
                        contractId: item.contract?.id,
                        companyId: props.companyId,
                        lang: lang,
                    })
                }
            >
                <CustomSVG type="pencil"/>
            </span> : ''
        },
        {
            title: 'text.printed',
            width: '10%',
            render: (item: any) =>  item.status === 'done' ?
                <PrintButton url={`/market/api/bill/payment/print/${item.id}`}/>
                : ''
        },
    ]

    return (
        <ListTable columns={columns} fetchData={fetchCompanyPayments}
           addQuery={{
               ...{'order[created_at]': 'DESC', 'equal[status]' : status},
               ...props.query
           }}
           addParam={{ companyId: props.companyId }}>
            <div className="row">
                <div className="col-md-4 col-12">
                    <EnumSelect enum={PaymentStatus}
                      onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                </div>
                {props.canPay && <div
                    className="col-md-3 col-12 ms-auto mb-md-0 mb-5">
                    <Button label="btn.add.payment"
                        onClick={() => showModal(
                            'ADD_LAND_PAYMENT_MODAL',
                            {
                                contractId: props?.contractId,
                                companyId: props.companyId,
                                lang: lang
                            })
                        }/>
                </div>}
            </div>
        </ListTable>
    );
}

export default PaymentList;
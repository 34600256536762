import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {IntLabel} from "@app/i18n";
import FormLand from "@app/core/src/components/form/FormLand"
import {editCompanyLand, fetchCompanyLandForEdit} from "../../../api";
import toast from "react-hot-toast";

function EditCompanyLandModal(props: any) {
    const {landId, companyId}: { landId?: any, companyId?:any} = props.modalProps
    const [fetching, setFetching] = useState(false)
    const [land, setLand] = useState(undefined)

    useEffect(() => {
        setFetching(true)
        fetchCompanyLandForEdit({companyId: companyId, id: landId})
            .then((res: any) => {
                setLand(res.data)
                setFetching(false)
            })
        return () => {};
    }, [landId]);

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><IntLabel label="text.edit.land.label"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormLand
                    land={land}
                    onSubmit={(land: any) => {
                        setFetching(true);
                        editCompanyLand(
                            {companyId: companyId, id: landId}, land)
                            .then(() => {
                                toast.success("text.success.message")
                                setFetching(false)
                                props.onClose()
                                // window.location.reload()
                            }).catch((res: any) => {
                                toast.error("text.error.message" ?? res.message)
                                setFetching(false)
                            })
                    }}
                  isLoading={fetching}
                  onCancelClick={()=>props.onClose()}/>
            </Modal.Body>
        </Modal>
    );
}

export default EditCompanyLandModal;
import {useState} from "react";
import {useDispatch} from "react-redux";
import {isLoading} from "../../../../application/appStore";
import {
    createTransactions,
    createTransactionsCategory,
    updateTransactions,
    updateTransactionsCategory
} from "@app/core/src/api/domain/company/transaction";

function UseTransactionController() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const  createTransaction = async (transaction: any) => {
        dispatch(isLoading(true))
        setLoading(true)
        createTransactions(transaction)
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    const updateTransaction = async (transactionId: string, transaction: any) => {
        dispatch(isLoading(true))
        setLoading(true)

        updateTransactions(transactionId, transaction)
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    const createCategory = async (category: any) => {
        dispatch(isLoading(true))
        setLoading(true)
        createTransactionsCategory(category)
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    const updateCategory = async (id: string, category: any) => {
        dispatch(isLoading(true))
        setLoading(true)

        updateTransactionsCategory(id, category)
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    return {
        loading,
        createTransaction,
        updateTransaction,
        createCategory,
        updateCategory,
    };
}

export default UseTransactionController;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import '@app/core/src/style/style.scss';
import {Provider as ReduxProvider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import appSlice from "./application/appStore";
import ModalContainer from "@app/core/src/modals/ModalContainer";
import modalSlice from "@app/core/src/modals";
import { Toaster } from 'react-hot-toast';

const store = configureStore({
    reducer: combineReducers({
        appSlice,
        modalSlice
    }),
})

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <Router>
                <App/>
                <Toaster />
                <ModalContainer/>
            </Router>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

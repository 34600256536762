import TransactionModal from "./TransactionModal";
import PartnerContractModal from "./PartnerContractModal";
import PartnerModal from "./PartnerModal";
import TransactionCategoryModal from "./TransactionCategoryModal";
import PartnerPaymentModal from "./PartnerPaymentModal";

export const transactionModal = {
    'TRANSACTION_MODAL': TransactionModal,
    'TRANSACTION_CATEGORY_MODAL': TransactionCategoryModal,

    'PARTNER_CONTRACT_MODAL': PartnerContractModal,
    'PARTNER_PAYMENT_MODAL': PartnerPaymentModal,
    'PARTNER_MODAL': PartnerModal,
}
import React, {useState} from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {fetchCompanyContractInterests} from "@app/core/src/api";
import {AnalyticsTable} from "@app/core";
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {PropertyInterestBadge} from "@app/core/src/components/Badges";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {InterestStatus} from "@app/core/src/interface/Enums";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import moment from "moment/moment";


function LandInterestView() {
    const [status, setStatus] = useState<any | undefined>(undefined);
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});

    const columns: any[] = [
        {
            title: "text.user.name",
            render: (item: any) => <strong>{item.userName}</strong>
        },
        {
            title: "text.contract",
            render: (item: any) => <Link to={`/land/contract/${item?.contract?.id}`} className="fw-bold fs-5">
                {item.contract.reference}
            </Link>
        },
        {
            title: "text.contract.amount",
            render: (item: any) => formatCurrency(item.contract?.amount)
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item?.amount)}</strong>
        },
        {
            title: "text.status",
            align: 'right',
            render: (item: any) => <PropertyInterestBadge status={item?.status}/>
        },
        {
            title: "text.contract.start.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item?.startDate}/>
        },
        {
            title: "text.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item?.createdAt}/>
        },
    ]

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.interest'},
        ]} title={<IntLabel label="menu.domain.land.interest"/>}>
            <AnalyticsTable columns={columns}
               fetchData={fetchCompanyContractInterests}
                cards={[
                    {label: 'total.interest.count', render: (item: any) => item?.count ?? 0},
                    {label: 'total.paid.interest.count', render: (item: any) => item?.unpaidCount ?? 0},
                    {label: 'total.interest.amount', render: (item: any) => formatCurrency(item?.totalUnpaid ?? 0)},
                    {label: 'total.paid.interest.amount', render: (item: any) => formatCurrency(item?.totalPaidInterest ?? 0)},
                ]}
                addQuery={dateRange.startDate === null ? {
                    'order[created_at]': 'DESC',
                    'equal[status]' : status
                } : {
                    'order[created_at]': 'DESC',
                    'equal[status]' : status,
                    'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                }}
               addParam={{ companyId: currentCompany }}>
                <div className="row">
                    <div className="col-md-4 col-12">
                        <EnumSelect enum={InterestStatus}
                                    onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                    </div>
                    <div className="col-md-4 col-12">
                        <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                    </div>
                </div>
            </AnalyticsTable>
        </AppContainer>
    );
}

export default LandInterestView;


//----
export function onToggleLanguageDrawer(){
    let classList = document.getElementById('kt_explore')?.classList;

    if(!classList?.toString().match('drawer-on')){
        document.getElementById('kt_explore')?.setAttribute('class', classList?.toString()+" drawer-on")
    }
    else{
        const newClassList = classList.toString().replace(" drawer-on", "");
        document.getElementById('kt_explore')?.setAttribute('class', newClassList);
    }
}
import React from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";

function HotelView() {
    return (
        <AppContainer breadcrumb={[
            {label: 'menu', path: appPaths.APP_PROFILE},
            {label: 'menu'},
        ]} title={<IntLabel label="home.menu"/>}>
            <div>
                AppPageView
            </div>
        </AppContainer>
    );
}

export default HotelView;
import { useContext } from 'react';
import moment from 'moment';
import React from 'react';
import IntlContext from "../context/IntlContext";

moment.locale('fr');

export function IntlDate({ date, dateFormat }: { date?: Date | any; dateFormat?: any }) {
  const { lang } = useContext(IntlContext);

  moment.locale('fr')

  if (!date) {
    return null;
  }

  return (
      <span className="capitalize">
        {moment(date).format(dateFormat ?? 'DD MMMM YYYY, H:m')}
      {/*{(format(date, dateFormat, { locale: LanguageFnsConfig[lang || 'fr'] } as any) || '').replace(/\./g, '')}*/}
    </span>
  );
}

import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {IntLabel} from "@app/i18n";
import FormContract from "../../../components/form/FormContract"
import {addCompanyContract} from "../../../api";
import toast from "react-hot-toast";

function AddContractModal(props: any) {
    const {companyId, landId}:{companyId: any, landId: any} = props.modalProps
    const [fetching, setFetching] = useState(false);

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title> <IntLabel label="text.add.contractor.label"/> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormContract
                    isLoading={fetching}
                    landId={landId}
                    onFinish={(data: any) => {
                        let payload = {
                            landId: landId,
                            ...data
                        }

                        setFetching(true);
                        addCompanyContract({companyId:companyId}, payload)
                            .then(() => {
                            toast.success("text.success.message")
                            setFetching(false)
                            props.onClose()
                        }).catch((res: any) => {
                            toast.error("text.error.message" ?? res.message)
                            setFetching(false)
                        })
                    }}
                    companyId={companyId}
                    onCancel={()=> props.onClose()}/>
            </Modal.Body>
        </Modal>
    );
}

export default AddContractModal;
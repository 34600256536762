import {transactionRoutePaths} from "./transactionRoutePaths";
import {PermissionAccess} from "@app/core/src/interface/PermissionAccess";

export const transactionMenu = [
    {
        domain: 'transaction',
        title: 'menu.domain.transaction',
        description: 'menu.domain.transaction.description',
        children: [
            {
                label: 'menu.manage.transaction',
                icon: 'payments',
                path: transactionRoutePaths.TRANSACTION,
                parent: 'transaction',
                access: [
                    PermissionAccess.MANAGE_TRANSACTION,
                    PermissionAccess.TRANSACTION_ADMINISTRATION,
                ]
            },
            {
                label: 'menu.manage.transaction.category',
                icon: 'windows_menu',
                path: transactionRoutePaths.CATEGORY,
                parent: 'transaction',
                access: [
                    PermissionAccess.MANAGE_TRANSACTION,
                    PermissionAccess.TRANSACTION_ADMINISTRATION,
                ]
            },
            {
                label: 'menu.manage.transaction.partner',
                icon: 'users',
                path: transactionRoutePaths.PARTNER,
                parent: 'transaction',
                access: [
                    PermissionAccess.MANAGE_TRANSACTION,
                    PermissionAccess.TRANSACTION_ADMINISTRATION,
                ]
            },
            {
                label: 'menu.manage.transaction.partner.contract',
                icon: 'users',
                path: transactionRoutePaths.PARTNER_CONTRACT,
                parent: 'transaction',
                access: [
                    PermissionAccess.MANAGE_TRANSACTION,
                    PermissionAccess.TRANSACTION_ADMINISTRATION,
                ]
            }
        ],
    },
];
import React, {useContext, useState} from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {AnalyticsTable, Button} from "@app/core";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {fetchCompanyContracts} from "@app/core/src/api";
import {formatCurrency} from "@app/core/src/service/formatService";
import {ContractStatusBadge} from "@app/core/src/components/Badges";
import {Link} from "react-router-dom";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {ContractStatus} from "@app/core/src/interface/Enums";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import moment from "moment/moment";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import IntlContext from "@app/i18n/src/context/IntlContext";

function LandContractView() {
    const {lang} = useContext(IntlContext)
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [status, setStatus] = useState<any | undefined>();
    const [orderType, setOrderType] = useState<any>('created_date_desc');
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});

    const columns: any[] = [
        {
            title: "text.contractor",
            render: (item: any) => <Link to={`/client/${item.creator.id}`} className="fw-bold fs-5">{item.contractor.name}</Link>
        },
        {
            title: "text.lands",
            render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land.landTitle}</Link>
        },
        {
            title: "contract.reference",
            align: 'right',
            render: (item: any) => <strong>{item.reference}</strong>
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.paid.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.paidAmount)}</strong>
        },
        {
            title: "text.due.amount",
            align: 'right',
            render: (item: any) => item.state === 'late' ?
                <strong>{formatCurrency(item.dueMonthAmount)}</strong>
                : ''
        },
        {
            title: "text.payment.status",
            align: 'center',
            render: (item: any) => item.status !== 'finish' ? <span className={`${ item.state === 'late' && 'text-danger'}`}>
                {`${item.dueMonth} ${getLabel('text.'+ item.state + '.month')} / ${item.period}`}
            </span> : ''
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => <ContractStatusBadge status={item.status}/>
        },
        {
            title: "text.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: 'text.printed',
            render: (item: any) => <PrintButton url={`/company/api/${currentCompany}/land/contract/print/${item.id}`}/>
        },
        {
            title: 'text.info',
            width: '5%',
            align: 'center',
            render: (item: any) => <OpenButton to={`/land/contract/${item.id}`}/>
        }
    ]

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.contract'},
        ]} actions={[
            <select  className="form-select form-select-sm w-100">
                <option selected value={""}>{getLabel("text.export.data", lang)}</option>
                <option value="excel">{getLabel("text.export.excel.data", lang)}</option>
                <option value="pdf">{getLabel("text.export.pdf.data", lang)}</option>
            </select>,
        ]} title={<IntLabel label="menu.domain.land.contract"/>}>
            <AnalyticsTable columns={columns}
                fetchData={fetchCompanyContracts}
                cards={[
                    {label: 'contracts.count', render: (item: any) => `${item?.total ?? 0} / ${item?.contractDueMonth ?? 0}`},
                    {label: 'contracts.value', render: (item: any) => formatCurrency(item?.contractValue ?? 0)},
                    {label: 'contracts.paid', render: (item: any) => formatCurrency(item?.paidAmount ?? 0)},
                    {label: 'contracts.remain', render: (item: any) => formatCurrency(item?.totalRemain ?? 0)},
                ]}
                addQuery={dateRange.startDate === null ? {
                    'orderType': orderType,
                    'equal[status]' : status
                } : {
                    'orderType': orderType,
                    'equal[status]' : status,
                    'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                }}
                addParam={{ companyId: currentCompany }}>
                <div className="row">
                    <div className="col-md-4 col-12">
                        <EnumSelect enum={ContractStatus}
                            onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                    </div>
                    <div className="col-md-4 col-12">
                        <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                    </div>
                    <div className="col-md-4 col-12">
                        <select
                            onChange={(e) => setOrderType(e.target.value)}
                            value={orderType} className="form-select form-select-sm w-100">
                            <option value="created_date_desc">{getLabel("text.sort.by.created.date.desc", lang)}</option>
                            <option value="created_date_asc">{getLabel("text.sort.by.created.date.asc", lang)}</option>
                            <option value="highest_payer">{getLabel("text.sort.by.highest.payer", lang)}</option>
                            <option value="lowest_payer">{getLabel("text.sort.by.lowest.payer", lang)}</option>
                        </select>
                    </div>
                </div>
            </AnalyticsTable>
        </AppContainer>
    );
}

export default LandContractView;
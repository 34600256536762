import React from 'react';
import {landRoutePaths} from "./landRoutePaths";
import LandView from "../pages/land/LandView";
import LandContractView from "../pages/landContract/LandContractView";
import LandReservationView from "../pages/landReservation/LandReservationView";
import LandContractorView from "../pages/landContractor/LandContractorView";
import LandPaymentView from "../pages/landPayment/LandPaymentView";
import LandInterestView from "../pages/landInterest/LandInterestView";
import AppLayout from "@apps/app/src/components/Layout/index";
import LandDetailsView from "../pages/land/LandDetailsView";
import LandValidationView from "../pages/land/LandValidationView";
import LandContractDetailsView from "../pages/landContract/LandContractDetailsView";
import ValidateLandPaymentView from "../pages/landPayment/ValidateLandPaymentView";
import LandContractValidation from "../pages/landContract/LandContractValidation";

const LandRoutes = [
    {
        path: landRoutePaths.LAND_VIEW,
        element: <AppLayout/>,
        children: [
            {
                path: landRoutePaths.LAND_VIEW,
                element: <LandView/>,
            },
            {
                path: landRoutePaths.LAND_DETAIL_VIEW,
                element: <LandDetailsView/>,
            },
            {
                path: landRoutePaths.LAND_VALIDATION_VIEW,
                element: <LandValidationView/>,
            },
            {
                path: landRoutePaths.LAND_CONTRACT,
                element: <LandContractView/>,
            },
            {
                path: landRoutePaths.LAND_CONTRACT_VALIDATION,
                element: <LandContractValidation/>,
            },
            {
                path: landRoutePaths.LAND_CONTRACT_DETAILS,
                element: <LandContractDetailsView/>,
            },
            {
                path: landRoutePaths.LAND_RESERVATION,
                element: <LandReservationView/>,
            },
            {
                path: landRoutePaths.LAND_CONTRACTOR,
                element: <LandContractorView/>,
            },
            {
                path: landRoutePaths.LAND_PAYMENT,
                element: <LandPaymentView/>,
            },
            {
                path: landRoutePaths.LAND_PAYMENT_VALIDATION,
                element: <ValidateLandPaymentView/>,
            },
            {
                path: landRoutePaths.LAND_INTEREST,
                element: <LandInterestView/>,
            },
        ]
    }
]

export default LandRoutes;
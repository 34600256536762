import React, {useEffect, useState} from 'react';
import {useModal} from "@app/core/src/modals";
import {appStoreProps, isLoading} from "../../../../../application/appStore";
import {useDispatch, useSelector} from "react-redux";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {ListTable} from "@app/core";
import {
    declineCompanyContract, deleteCompanyLand,
    fetchCompanyLand,
    fetchCompanyLandParts,
    fetchCompanyLands,
    validateCompanyContract, validateCompanyLand
} from "@app/core/src/api";
import CustomSVG from "@app/core/src/components/icons/svg";
import {validateColumn} from "./config";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import {formatCurrency, formatNumber} from "@app/core/src/service/formatService";
import MapContainer from "@app/core/src/maps/MapContainer";
import toast from "react-hot-toast";
import AppDatePicker from "@app/core/src/components/AppDatePicker";

function ModalLandConfirmation(props: {item: any, currentCompany: any}) {
    const [loading, setLoading] = useState(false)
    const [parts, setParts] = useState<any[]>([])
    const [land, setLand] = useState<any>()

    useEffect(() => {
        setLoading(true)
        fetchCompanyLand(
            {id: props.item.id, companyId: props.currentCompany},
        ).then((res: any) => {
            setLoading(false)
            setLand(res.data)
        }).catch(() => setLoading(false))
        return () => {};
    }, []);

    useEffect(() => {
        setLoading(true)
        fetchCompanyLandParts(
            {companyId: props.currentCompany},
            {'uuid[land_id]': props.item.id}
        ).then((res: any) => {
            setLoading(false)
            setParts(res.data)
        }).catch(() => setLoading(false))
        return () => {};
    }, []);

    return <ul className="list-group list-group-flush">
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.land.parts")}</div>
                {loading ? 'Recherche en cours...' :
                    <div className="row justify-content-around">
                        {parts.map((item, index) =>
                            <div key={index} className="col-12 m-3 btn btn-sm btn-outline-dark">
                                {`Bloc: ${item.bloc} | Numéro de lot: ${item.number} | Superficie: ${formatNumber(item.area)}`} m<sup>2</sup>
                            </div>
                        )}
                    </div>}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="w-100" style={{ height: '300px'}}>
                    {land && <MapContainer point={[
                        land?.point?.latitude ?? 0,
                        land?.point?.longitude ?? 0
                    ]} zoom={16} className="card-img"
                       polygon={land?.localisation?.map((
                           {longitude, latitude}: any) => ({lon: longitude, lat: latitude}))}
                    />}
                </div>
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.title")}</div>
                {props.item?.title}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.label.town")}</div>
                {props.item?.town}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("land.information")}</div>
                {land?.description}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.label.land.title")}</div>
                {props.item?.landTitle}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.square.price")}</div>
                {formatCurrency(props.item.squarePrice)}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.amount")}</div>
                {formatCurrency(land?.landValue)}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.date")}</div>
                <IntlDate date={props.item.createdAt}/>
            </div>
        </li>
    </ul>;
}

function LandValidationView() {
    const {showModal, hideModal} = useModal()
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const dispatch = useDispatch()

    const columns: any[] = [
        ...validateColumn,
        {
            title: 'text.decided',
            width: '10%',
            align: 'center',
            render: (item: any) => <span
                className="text-primary text-center cursor-pointer"
                onClick={() => showModal(
                    'APP_VALIDATION_MODAL',
                    {
                        content: <ModalLandConfirmation item={item} currentCompany={currentCompany}/>,
                        onValidate: () => onValidate(item),
                        onDeclined: () => onDeclined(item),
                    })
                }
            >
                <CustomSVG type="pencil"/>
            </span>
        },
    ]

    const onValidate = (item: any) => {
        dispatch(isLoading(true))
        validateCompanyLand({
            id: item.id,
            companyId: currentCompany,
        }).then(() => {
            toast.success("text.success.message")
            dispatch(isLoading(false))
            hideModal('APP_VALIDATION_MODAL')
        }).catch((res: any) => {
            toast.error("text.error.message" ?? res.message)
            dispatch(isLoading(false))
        })
    }

    const onDeclined = (item: any) => {
        dispatch(isLoading(true))
        deleteCompanyLand({
            id: item.id,
            companyId: currentCompany,
        }).then(() => {
            toast.success("text.success.message")
            dispatch(isLoading(false))
            hideModal('APP_VALIDATION_MODAL')
        }).catch((res: any) => {
            toast.error("text.error.message" ?? res.message)
            dispatch(isLoading(false))
        })
    }

    return <AppContainer breadcrumb={[
        {label: 'menu.domain.app.overview', path: appPaths.APP},
        {label: 'menu.domain.land.validation'},
    ]} title={<IntLabel label="menu.domain.land.overview"/>}>
        <ListTable
            columns={columns}
            fetchData={fetchCompanyLands}
            addQuery={{ 'order[created_at]': 'DESC', 'equal[status]' : 'draft' }}
            addParam={{ companyId: currentCompany }}>
            <div className="col-md-4 col-12">
                <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
            </div>
        </ListTable>
    </AppContainer>
}

export default LandValidationView;
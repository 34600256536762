import React from 'react';
import {appPaths} from "./appRoutesPaths";
import AppPageView from "../pages/home/AppPageView";
import AppProfileView from "../pages/profile/AppProfileView";
import AppLayout from "@apps/app/src/components/Layout/index";


const appRoutes = [
    {
        path: appPaths.APP,
        element: <AppLayout/>,
        children:[
            {
                path: appPaths.APP,
                element: <AppPageView/>,
            },
            {
                path: appPaths.APP_PROFILE,
                element: <AppProfileView/>,
            },
        ]
    },
];

export default appRoutes;
import React, { useCallback, useContext, useMemo, useState} from "react";
import {Language} from "@app/i18n/src/languages";
import IntlContext from "@app/i18n/src/context/IntlContext";
import {useRoutes} from "react-router-dom";
import appRoutes from "./ui/pages/app/routing/appRoutes";
import propertyRoutes from "./ui/pages/property/routing/propertyRoutes";
import LandRoutes from "./ui/pages/land/routing/landRoutes";
import hotelRoutes from "./ui/pages/hotel/routing/hotelRoutes";
import securityRoutes from "./ui/pages/security/routing/securityRoutes";
import companyRoutes from "./ui/pages/company/routing/companyRoutes";
import {useSelector} from "react-redux";
import transactionRoutes from "./ui/pages/Transaction/routing/transactionRoutes";

function App() {
    const {lang} = useContext(IntlContext)
    const {loading} = useSelector((state: any) => state.appSlice)
    const [language, setLang] = useState<any>(lang);

    const toggleLang = useCallback(function (){
        setLang((l: string) => l === Language.ENG ? Language.FRA : Language.ENG)
    }, [])

    const value = useMemo(function (){
        return {
            lang: language,
            toggleLang
        }
    }, [language, toggleLang])

    return (
        <IntlContext.Provider value={value}>
            {loading && <div className="overlay">
                <div className="loader">
                </div>
            </div>}

            {useRoutes([
                ...appRoutes,
                ...LandRoutes,
                ...hotelRoutes,
                ...propertyRoutes,
                ...securityRoutes,
                ...companyRoutes,
                ...transactionRoutes,
            ])}
        </IntlContext.Provider>
  );
}

export default App;

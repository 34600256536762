import React, {useState} from 'react';
import {useModal} from "@app/core/src/modals";
import usePartnerController from "../../controller/usePartnerController";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel, IntlDate} from "@app/i18n";
import {AnalyticsTable} from "@app/core";
import {fetchPartnerContracts} from "@app/core/src/api/domain/company/transaction";
import {formatCurrency} from "@app/core/src/service/formatService";
import moment from "moment";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import CustomSVG from "@app/core/src/components/icons/svg";

function PartnerContractPage() {
    const {showModal, hideModal} = useModal()
    const {addPayment} = usePartnerController()
    const [dateRange, setDateRange] = useState<any>({
        startDate: null,
        endDate: null
    });

    const columns: any[] = [
        {
            title: "text.transaction.category",
            render: (item: any) => item.category.name
        },
        {
            title: "text.partner",
            render: (item: any) => item.partner === null ? '' : item.partner.name
        },
        {
            title: "text.description",
            render: (item: any) => item.description
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => formatCurrency(item.amount)
        },
        {
            title: "text.paid.amount",
            align: 'right',
            render: (item: any) => formatCurrency(item.paidAmount)
        },
        {
            title: "text.date",
            align: 'right',
            width: '20%',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: "btn.add.payment",
            align: 'center',
            render: (item: any) => <span
                className="text-primary cursor-pointer"
                onClick={() => showModal(
                    'PARTNER_PAYMENT_MODAL',
                    {
                        contractId: item.id,
                        onValidate: (contract: any) => addPayment(item.id, contract)
                            .finally(() => hideModal('PARTNER_PAYMENT_MODAL'))
                    })}
            >
                <CustomSVG type="addPayment"/>
            </span>
        },
    ]

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.transaction.partner.overview'},
        ]}
              title={<IntLabel label="menu.domain.transaction.partner.overview"/>}
        >
            <AnalyticsTable columns={columns}
                            fetchData={fetchPartnerContracts}
                            cards={[
                                {label: 'contract.count', render: (item: any) => item?.count  ?? 0},
                                {label: 'contract.amount', render: (item: any) => formatCurrency(item?.amount ?? 0)},
                                {label: 'contract.paidAmount', render: (item: any) => formatCurrency(item?.paidAmount ?? 0)},
                            ]}
                            addQuery={dateRange.startDate === null ? {
                                'order[created_at]': 'DESC',
                            } : {
                                'order[created_at]': 'DESC',
                                'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                            }}>
                <div className="row">
                    <div className="col-md-4 col-12">
                        <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                    </div>
                </div>
            </AnalyticsTable>
        </AppContainer>
    );
}

export default PartnerContractPage;
import React, {useContext, useState} from 'react';
import DatePicker from "react-datepicker";
import "@app/core/src/style/_app_date_range.scss";
import moment from "moment";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import IntlContext from "@app/i18n/src/context/IntlContext";

export interface DateRangeProps {
    startDate?: any,
    endDate?: any
}

export interface AppDatePickerProps {
    dateRange: DateRangeProps
    onChange: any
}
function AppDatePicker(props: AppDatePickerProps) {
    const {lang} = useContext(IntlContext)

    return (
        <DatePicker
            className="form-control form-control-sm"
            selectsRange={true}
            monthsShown={2}
            todayButton={getLabel("text.select.today", lang)}
            placeholderText={getLabel("text.select.date", lang)}
            startDate={props.dateRange.startDate ?? null}
            endDate={props.dateRange.endDate ?? null}
            onChange={(update) => props.onChange({startDate: update[0], endDate: update[1]})}
            isClearable={true}
        />
    );
}

export default AppDatePicker;
import React from 'react';
import {IntLabel} from "@app/i18n";
import {Link} from "react-router-dom";
import CustomSVG from "../../../icons/svg";

function OpenButton(props: any) {
    return (
        <Link {...props}>
            <CustomSVG type="info"/>
        </Link>
    );
}

export default OpenButton;
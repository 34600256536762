const enumLabel = {
    "payment.pending": "En cours",
    "payment.failed": "Echoué",
    "payment.done": "Réussi",

    "contract.ongoing": "En cours",
    "contract.finish": "Terminé",
    "contract.cancelled": "Rejété",
    "contract.draft": "Brouillon",
    "contract.archived": "Archivé",

    "property.sold": "Vendu",
    "property.available": "Disponible",
    "property.booked": "Reservé",

    "property.draft": "Brouillon",
    "property.published": "Publié",
    "property.unpublished": "Masqué",

    "payment.orange_money_cameroon": "Orange money",
    "payment.mtn_mobile_money_cameroon": "Mobile money",
    "payment.card_payment": "Cart bancaire",
    "payment.cash": "Paiement cash",
    "payment.bank_transfer": "Virement bancaire",

    "domain.land": "Terrains",
    "domain.hotel": "Hotels",
    "domain.real_estate": "Immobiliers",
    "domain.company": "Entreprises",
    "land": "Terrains",
    "hotel": "Hotels",
    "real_estate": "Immobiliers",
    "company": "Entreprises",

    "interest.generated": 'génerée',
    "interest.unpaid": 'impayé   ',
    "interest.paid": 'payé',
    "interest.draft": 'exonéré',

    "invitation.accepted": 'Acceptée',
    "invitation.created": 'Crée   ',
    "invitation.rejected": 'Rejetée',

    "level.high": "Elevé",
    "level.medium": "Moyen",
    "level.basic": "Basic",
    "level.owner": "Proprietaire",

    'transaction.payment': 'Paiement',
    'transaction.deposit': 'Versemment',
    'transaction.disburse': 'Decaisssement',

    'text.enum.payment_done': "Paiement effectue",
    'text.enum.payment_remind': "Rappel sur paiement",
    'text.enum.payment_alert': "Alerte sur paiement",
    'text.enum.contract_validated': "Validation contrat",
    'text.enum.contract_finish': "Fin de contrat",
    'text.enum.contract_cancelled': "Resilliation du contrat",


    // permission action
    'text.enum.manage_land': "Gérer les terrains",
    'text.enum.manage_land_payment': "Gérer les paiements",
    'text.enum.manage_land_contract': "Gérer les contracts",
    'text.enum.land_administration': "Administrer les terrains",

    'text.enum.manage_client': "Gérer les clients",
    'text.enum.client_administration': "Administrer les clients",

    'text.enum.manage_company': "Gérer l'entreprise",
    'text.enum.manage_notification': "Gérer les notifications",

    'text.enum.manage_transaction': "Gérer les operations financières",
    'text.enum.transaction_administration': "Administrer les operations financières",
}

export default enumLabel;
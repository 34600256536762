import React, {useState} from 'react';
import {appStoreProps} from "../../../../application/appStore";
import {useSelector} from "react-redux";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../app/routing/appRoutesPaths";
import {AnalyticsTable} from "@app/core";
import moment from "moment/moment";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import {fetchNotificationLogs} from "@app/core/src/api/domain/company/notifications";

function NotificationLogPage() {
    // const [status, setStatus] = useState<any | undefined>(undefined);
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});

    const columns: any[] = [
        {
            title: "text.name",
            render: (item: any) => item.name
        },
        {
            title: "text.channel",
            render: (item: any) => item.channel
        },
        {
            title: "text.contact",
            render: (item: any) => item.contact
        },
        {
            title: "text.message",
            render: (item: any) => item.message
        },
        {
            title: "text.charge",
            render: (item: any) => item.charge
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => item.status
        },
        {
            title: 'text.date',
            render: (item: any) => <IntlDate date={item.createdAt} dateFormat="DD MMMM YYYY, H:m"/>
        },
    ]

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.payment'},
        ]} title={<IntLabel label="menu.domain.land.payment"/>}>
            <AnalyticsTable
                columns={columns}
                fetchData={fetchNotificationLogs}
                // cards={[
                //     {label: 'payment.count', render: (item: any) => item?.total ?? 0},
                //     {label: 'payment.value', render: (item: any) => formatCurrency(item?.totalPayment ?? 0)},
                // ]}
                addQuery={dateRange.startDate === null ? {
                    'order[created_at]': 'DESC',
                    // 'equal[status]' : status
                } : {
                    'order[created_at]': 'DESC',
                    // 'equal[status]' : status,
                    'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                }}
                addParam={{ companyId: currentCompany }}>
                <div className="row">
                    {/*<div className="col-md-4 col-12">*/}
                    {/*    <EnumSelect enum={PaymentStatus}*/}
                    {/*                onChange={(e: any) => setStatus(e.target.value)} value={status}/>*/}
                    {/*</div>*/}
                    <div className="col-md-4 col-12">
                        <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                    </div>
                </div>
            </AnalyticsTable>
        </AppContainer>
    );
}

export default NotificationLogPage;
import ConfirmationModal from "../modals/ConfirmationModal";
import {EmployeeModalMap} from "../modals/user";
import {CompanyModalMap} from "../modals/company";
import {InvitationModalMap} from "../modals/invitations";
import GeneratePaymentLinkModal from "../modals/GeneratePaymentLinkModal";
import {landModal} from "../modals/land";
import AppValidationModal from "../modals/AppValidationModal";
import {transactionModal} from "../modals/transaction";

export const ModalMap = {
    'APP_CONFIRMATION_MODAL': ConfirmationModal,
    'APP_VALIDATION_MODAL': AppValidationModal,
    'GENERATE_PAYMENT_LINK':  GeneratePaymentLinkModal,
    ...landModal,
    ...EmployeeModalMap,
    ...CompanyModalMap,
    ...InvitationModalMap,
    ...transactionModal
}
import React from 'react';
import {useModal} from "@app/core/src/modals";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {IntLabel} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {Button} from "@app/core";
import useTransactionController from "../../controller/useTransactionController";
import TransactionAnalytics from "../components/TransactionAnalytics";

function TransactionPage() {
    const {showModal, hideModal} = useModal()
    const {createTransaction, loading} = useTransactionController()
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)

    return <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.transaction.overview'},
        ]} actions={[
            // <select  className="form-select form-select-sm w-100">
            //     <option selected value={""}>{getLabel("text.export.data")}</option>
            //     <option value="excel">{getLabel("text.export.excel.data")}</option>
            //     <option value="pdf">{getLabel("text.export.pdf.data")}</option>
            // </select>,
            <Button label="btn.add.transaction"
                onClick={() => showModal(
                'TRANSACTION_MODAL',
                {
                    companyId: currentCompany,
                    onValidate: (transaction: any) => createTransaction(transaction)
                        .finally(
                            () => hideModal('TRANSACTION_MODAL')
                        )
                })
            }/>
        ]} title={<IntLabel label="menu.domain.transaction.overview"/>}>
        <TransactionAnalytics refresh={loading}/>
    </AppContainer>
}

export default TransactionPage;
import React from "react";
import {IntLabel} from "@app/i18n";
import {securityRoutesPaths} from "../../routing/securityRoutesPaths";
import {useNavigate} from "react-router";


export default function AppRegisterPage(){
    const navigate = useNavigate();

    return(
        <div className="container">
            <div className="row vh-100">
                <div className="col-12 col-md-6 col-lg-4 m-auto">
                    <div  className="card rounded p-3 w-100 shadow-sm rounded-2">
                        <div className="row">
                            <div className="form-group py-1 fv-plugins-icon-container col-12">
                                <label className="fs-base fw-semibold" htmlFor="email">
                                    <IntLabel label={'text.email'}/>
                                </label>
                                <input type="email" className="form-control from-control-custom" name="email"/>
                                <div className="fv-plugins-message-container"></div>
                            </div>

                            <div className="form-group py-1 fv-plugins-icon-container col-12">
                                <label className="fs-base fw-semibold" htmlFor="phoneNumber">
                                    <IntLabel label={'text.phoneNumber'}/>
                                </label>
                                <input type="text" className="form-control from-control-custom" name="phoneNumber"
                                       placeholder={"Ex: +237 655-55-55-55"} required={true}
                                />
                                <div className="fv-plugins-message-container"></div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 form-group py-1 fv-plugins-icon-container">
                                <label className="fs-base fw-semibold" htmlFor="firstName"><IntLabel label={'text.firstName'}/></label>
                                <input type="text" className="form-control from-control-custom" name="firstName" required={true}/>
                                <div className="fv-plugins-message-container"></div>
                            </div>

                            <div className="col-md-6 col-12 form-group py-1 fv-plugins-icon-container">
                                <label className="fs-base fw-semibold" htmlFor="lastName"><IntLabel label={'text.lastName'}/></label>
                                <input type="text" className="form-control from-control-custom" name="lastName" required={true}/>
                                <div className="fv-plugins-message-container"></div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 form-group py-1 fv-plugins-icon-container">
                                <div className="d-flex justify-content-between">
                                    <label className="fs-base fw-semibold" htmlFor="password">
                                        <IntLabel label={"text.password"}/>
                                    </label>
                                </div>
                                <input type="password" className="form-control from-control-custom" name="password" required={true}/>
                                <div className="fv-plugins-message-container"></div>
                            </div>

                            <div className="col-md-6 col-12 form-group py-1 fv-plugins-icon-container">
                                <div className="d-flex justify-content-between">
                                    <label className="fs-base fw-semibold" htmlFor="confirmation">
                                        <IntLabel label={"text.password.confirm"}/>
                                    </label>
                                </div>
                                <input type="password" className="form-control from-control-custom" name="confirmation" required={true}/>
                                <div className="fv-plugins-message-container"></div>
                            </div>
                        </div>

                        <div className="form-group mb-12 d-flex justify-content-between pt-2">
                            <button type="submit" id="page_account_signin_submit"
                                    className="btn btn-custom btn-primary fw-semibold py-4 px-7 me-3 radius-lg w-100">
                                <span className="indicator-label">
                                    <IntLabel label={'text.register'}/>
                                </span>
                                <span className="indicator-progress">
                                    <IntLabel label={'text.wait.progress'}/>
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>


                        <div className="d-flex flex-center fs-5 fw-bold">
                            <span className="text-muted me-2"><IntLabel label={'text.has.account'}/></span>

                            <a href="#" className="text-primary" onClick={() => navigate(securityRoutesPaths.LOGIN_PAGE)}>
                                <IntLabel label={'text.sign.in'}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, {useContext} from 'react';
import {Language, LanguagesBundle} from "../languages";
import LabelsFra from "../labels/lables.fr";
import IntlContext from "../context/IntlContext";

export const getLabelPack = (lang: string): any => {
    return LanguagesBundle[lang] || LabelsFra;
};

export const getLabel = (label: string, lang?: string) => getLabelPack(lang ?? Language.FRA)[(label) || Language.FRA] ??  label ?? ''

export function IntLabel({label}: {label: string}) {
    const {lang} = useContext(IntlContext);
    return getLabel(label,  lang) ?? label;
}

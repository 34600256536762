import React, {useContext, useState} from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {AnalyticsTable, Button} from "@app/core";
import {fetchCompanyLands} from "@app/core/src/api";
import {useSelector} from "react-redux";
import {appStoreProps} from "../../../../../application/appStore";
import {formatCurrency, formatNumber} from "@app/core/src/service/formatService";
import OpenButton from "@app/core/src/components/common/Button/Action/OpenButton";
import {PropertyVisibilityStatusBadge} from "@app/core/src/components/Badges";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {PropertyVisibility} from "@app/core/src/interface/Enums";
import PrintButton from "@app/core/src/components/common/Button/Action/PrintButton";
import {useModal} from "@app/core/src/modals";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import moment from "moment";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import IntlContext from "@app/i18n/src/context/IntlContext";

function LandView() {
    const {showModal} = useModal()
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [status, setStatus] = useState<any | undefined>(undefined);
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});
    const {lang} = useContext(IntlContext);

    const columns: any[] = [
        {
            title: "text.land.title",
            render: (item: any) => <strong>{item.landTitle}</strong>
        },
        {
            title: "land.part",
            align: 'right',
            render: (item: any) => `${item.parts ?? 0} Lot(s)`
        },
        {
            title: "text.area",
            align: 'right',
            render: (item: any) => <div>{formatNumber(item.area)} m<sup>2</sup></div>
        },
        {
            title: "text.square.price",
            align: 'right',
            render: (item: any) => formatCurrency(item.squarePrice)
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => formatCurrency(item.amount)
        },
        {
            title: "text.paid.amount",
            align: 'right',
            render: (item: any) => formatCurrency(item.paidAmount)
        },
        {
            title: "text.sold.percent",
            align: 'right',
            render: (item: any) => `${item.percent ?? 0} %`
        },
        {
            title: "text.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: "text.status",
            width: '8%',
            align: 'center',
            render: (item: any) => <PropertyVisibilityStatusBadge status={item.status}/>
        },
        {
            title: 'text.printed',
            width: '8%',
            render: (item: any) => <PrintButton url={`/company/api/${currentCompany}/land/print/${item.id}`}/>
        },
        {
            title: 'text.info',
            width: '5%',
            align: 'center',
            render: (item: any) => <OpenButton to={`/land/${item.id}`}/>
        }
    ]

    return <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.overview'},
        ]} actions={[
            <select  className="form-select form-select-sm w-100">
                <option selected value={""}>{getLabel("text.export.data")}</option>
                <option value="excel">{getLabel("text.export.excel.data")}</option>
                <option value="pdf">{getLabel("text.export.pdf.data")}</option>
            </select>,
            <Button label="btn.add.land"
                onClick={() => showModal(
                      'ADD_LAND_MODAL',
                 {
                    companyId: currentCompany,
                 })
            }/>
        ]} title={<IntLabel label="menu.domain.land.overview"/>}>
            <AnalyticsTable columns={columns} fetchData={fetchCompanyLands}
                 cards={[
                    {label: 'land.count', render: (item: any) => item?.total  ?? 0},
                    {label: 'land.value', render: (item: any) => formatCurrency(item?.landValue ?? 0)},
                    {label: 'land.paid', render: (item: any) => formatCurrency(item?.totalPayment ?? 0)},
                 ]}
                 addQuery={dateRange.startDate === null ? {
                     'order[created_at]': 'DESC',
                     'equal[status]' : status
                 } : {
                     'order[created_at]': 'DESC',
                     'equal[status]' : status,
                     'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                 }}
                 addParam={{ companyId: currentCompany }}>
                 <div className="row">
                    <div className="col-md-4 col-12">
                        <EnumSelect enum={PropertyVisibility} placeholder='text.filter.status'
                            onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                    </div>
                     <div className="col-md-4 col-12">
                         <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                    </div>
                {/* @todo: add land order (sort_by_land_part_desc, sort_by_land_part_asc, sort_by_paid_amount_asc, sort_by_paid_amount_desc)*/}
                </div>
            </AnalyticsTable>
        </AppContainer>
}

export default LandView;
import React from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel} from "@app/i18n";
import {Button} from "@app/core";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {useModal} from "@app/core/src/modals";
import BackButton from '@app/core/src/components/common/Button/Action/BackButton';
import PartnerContractAnalytics from "../components/PartnerContractAnalytics";
import usePartnerController from "../../controller/usePartnerController";
import {useParams} from "react-router";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";

function TransactionPartnerDetails() {
    const {showModal, hideModal} = useModal()
    const {updatePartner, createContract} = usePartnerController()
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const {id}: any = useParams()

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.transaction.partner.overview'},
        ]}
          title={<IntLabel label="menu.domain.transaction.partner.overview"/>}
          actions={[
              <Button label="btn.edit.partner"
                  onClick={() => showModal(
                      'PARTNER_MODAL',
                      {
                          partnerId: id,
                          companyId: currentCompany,
                          onValidate: (partner: any) => updatePartner(id, partner)
                              .finally(() => hideModal('PARTNER_MODAL'))
                      })
                  }/>,
              <Button label="btn.add.contract"
                  onClick={() => showModal(
                      'PARTNER_CONTRACT_MODAL',
                      {
                          companyId: currentCompany,
                          onValidate: (contract: any) => createContract(id, contract)
                              .finally(() => hideModal('PARTNER_CONTRACT_MODAL'))
                      })
                  }/>,
              <BackButton/>
          ]}
        >
            <PartnerContractAnalytics id={id}/>
        </AppContainer>
    );
}

export default TransactionPartnerDetails;
import React, {useContext, useState} from 'react';
import {useModal} from "@app/core/src/modals";
import {appStoreProps, isLoading} from "../../../../../application/appStore";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {IntLabel, IntlDate} from "@app/i18n";
import {enumLabel, PaymentMethod} from "@app/core/src/interface/Enums";
import {PaymentStatusBadge} from "@app/core/src/components/Badges";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {ListTable} from "@app/core";
import {declineCompanyPayment, fetchCompanyPayments, validateCompanyPayment} from "@app/core/src/api";
import CustomSVG from "@app/core/src/components/icons/svg";
import toast from "react-hot-toast";
import IntlContext from "@app/i18n/src/context/IntlContext";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import AppDatePicker from "@app/core/src/components/AppDatePicker";
import moment from "moment/moment";

function ModalPaymentConfirmationInfo(props: { item: any, lang: any }) {

    return <ul className="list-group list-group-flush">
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.creator", props.lang)}</div>
                {props.item.author?.name}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.contract",props.lang)}</div>
                {props.item.contract?.reference}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.land", props.lang)}</div>
                {props.item.land?.landTitle}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.amount", props.lang)}</div>
                {formatCurrency(props.item.amount)}
            </div>
        </li>
        <li className="list-group-item">
            <div className="ms-2 me-auto">
                <div className="fs-5 text-gray-400">{getLabel("text.date", props.lang)}</div>
                <IntlDate date={props.item.createdAt}/>
            </div>
        </li>
    </ul>
}

function ValidateLandPaymentView(props: any) {
    const {lang} = useContext(IntlContext)
    const {showModal, hideModal} = useModal()
    const dispatch = useDispatch()
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)
    const [dateRange, setDateRange] = useState<any>({startDate: null, endDate: null});

    const onValidate = (item: any) => {
        dispatch(isLoading(true))
        validateCompanyPayment({
            id: item.id,
            companyId: currentCompany,
        }).then(() => {
            toast.success("text.success.message")
            dispatch(isLoading(false))
            hideModal('APP_VALIDATION_MODAL')
        }).catch((res: any) => {
            toast.error("text.error.message" ?? res.message)
            dispatch(isLoading(false))
        })
    }

    const onDeclined = (item: any) => {
        dispatch(isLoading(true))
        declineCompanyPayment({
            id: item.id,
            companyId: currentCompany,
        }).then(() => {
            toast.success("text.success.message")
            dispatch(isLoading(false))
            hideModal('APP_VALIDATION_MODAL')
        }).catch((res: any) => {
            toast.error("text.error.message" ?? res.message)
            dispatch(isLoading(false))
        })
    }

    const columns: any[] = [
        {
            title: "text.author",
            render: (item: any) => <Link to={`/employee/${item.author.id}`} className="fw-bold fs-5">{item.author.name}</Link>
        },
        {
            title: "text.contract",
            render: (item: any) => <Link to={`/land/contract/${item.contract.id}`} className="fw-bold fs-6">{item.contract.reference}</Link>
        },
        {
            title: "text.lands",
            render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land.landTitle}</Link>
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.contact",
            render: (item: any) => item.contact
        },
        {
            title: 'text.payment.method',
            render: (item: any) => <IntLabel label={enumLabel(PaymentMethod, item.paymentMethod)}/>
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => <PaymentStatusBadge status={item.status}/>
        },
        {
            title: 'text.date',
            render: (item: any) => <IntlDate date={item.createdAt}/>
        },
        {
            title: 'text.decided',
            width: '10%',
            align: 'center',
            render: (item: any) => <span
                className="text-primary text-center cursor-pointer"
                onClick={() => showModal(
                    'APP_VALIDATION_MODAL',
                    {
                        content: <ModalPaymentConfirmationInfo lang={lang} item={item}/>,
                        onValidate: () => onValidate(item),
                        onDeclined: () => onDeclined(item),
                        lang: lang
                    })
                }
            >
                <CustomSVG type="pencil"/>
            </span>
        },
    ]

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.payment.validation'},
        ]}
           title={<IntLabel label="menu.domain.land.payment"/>}>
            <ListTable columns={columns}
                fetchData={fetchCompanyPayments}
                addQuery={dateRange.startDate === null ? {
                    'order[created_at]': 'DESC',
                    'equal[status]' : 'pending'
                } : {
                    'order[created_at]': 'DESC',
                    'equal[status]' : 'pending',
                    'date[created_at]': `${moment(dateRange.startDate).format('YYYY-MM-DD')},${moment(dateRange.endDate).format('YYYY-MM-DD')}`
                }}
                addParam={{ companyId: currentCompany }}>
                <div className="col-md-4 col-12">
                    <AppDatePicker dateRange={dateRange} onChange={setDateRange}/>
                </div>
            </ListTable>
        </AppContainer>
    );
}

export default ValidateLandPaymentView;
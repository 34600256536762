import {IntLabel} from "@app/i18n";
import React, {useEffect, useState} from "react";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import {NotificationTemplateType, NotificationVariable} from "../../../../../../enum";
import {fetchTemplateByType, updateNotificationTemplate} from "@app/core/src/api/domain/company/notifications";
import {useDispatch} from "react-redux";
import {isLoading} from "../../../../../../application/appStore";
import {fetchEmployeeForSelect} from "@app/core/src/api";
import AsyncSelect from "react-select/async";

export default function TemplateEditor(props: {type: string}){
    const dispatch = useDispatch()
    const [options, setOptions] = useState<any[]>([])
    const [fetching, setFetching] = useState<any>(false)
    const [template, setTemplate] = useState({
        id: undefined,
        type: undefined,
        enabled: undefined,
        smsSenderName: undefined,
        smsTemplate: undefined,
        emailSubject: undefined,
        emailTemplate: undefined,
        channels: [] as any,
        targets: [] as any
    })

    useEffect(() => {
        loadOptions()

        if (props.type) {
            dispatch(isLoading(true))
            fetchTemplateByType(props.type)
                .then((res: any) => {
                    setTemplate(res.data)
                    dispatch(isLoading(false))
                }).catch(() => {
                dispatch(isLoading(false))
            })
        }
    }, [props.type])

    function onCheckboxChange(channel: string) {
        let channels: any = [...template.channels]
        if (channels.includes(channel)) {
            channels = channels.filter((item: any) => item !== channel);
        } else {
            channels.push(channel)
        }

        setTemplate((prev: any) => ({...prev, channels: channels}))
    }

    const onSaveTemplate = () => {
        dispatch(isLoading(true))
        updateNotificationTemplate(props.type, {
            enabled: template.enabled,
            smsSenderName: template.smsSenderName,
            smsTemplate: template.smsTemplate,
            emailSubject: template.emailSubject,
            emailTemplate: template.emailTemplate,
            channels: template.channels,
            targets: template.targets.map((item: any) => item.value)
        }).then(() => {
                dispatch(isLoading(false))
            }).catch(() => {
            dispatch(isLoading(false))
        })
    }

    const loadOptions = (q?: any)=> {
        setFetching(true)
        fetchEmployeeForSelect({q: q}).then((res: any) => {
            setFetching(false)
            let data = res.data.map((u: any) => ({label: `${u.name}`, value: u.id}))
            setOptions(data)
        })
    }

    return (
        <div className="card mb-5 mb-xl-10 shadow-sm">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                        <IntLabel label="text.update.template"/>
                    </h3>
                </div>
            </div>
            <div className="card-body p-9">
                <div className="row justify-content-between gx-5">
                    <div className="col-12 col-md-8">
                        <div className="col-12 row m-2">
                            <div className="form-check col-md-3 col-12">
                                <input className="form-check-input" type="checkbox"
                                       checked={template?.channels.includes("sms")}
                                       onChange={() => onCheckboxChange('sms')}
                                />
                                <label className="form-check-label">Sms</label>
                            </div>
                            <div className="form-check col-md-3 col-12">
                                <input className="form-check-input"  type="checkbox"
                                       checked={template?.channels.includes("email")}
                                       onChange={() => onCheckboxChange('email')}
                                />
                                <label className="form-check-label">Email</label>
                            </div>

                            <div className="col-md-6 me-auto col-12">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                       value={template?.enabled}
                                       onChange={(e) => setTemplate(
                                           (prev: any) =>({...prev, enabled: e.target.checked}))}
                                       type="checkbox" checked={template?.enabled}/>
                                    <label className="form-check-label">
                                        {template?.enabled ? "Desactiver pour ne plus envoyer" : "Activer pour envoyer"}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {(props.type === NotificationTemplateType.PAYMENT_ALERT
                                || props.type === NotificationTemplateType.CONTRACT_FINISHED) &&
                            <div className="row gy-6 my-5">
                                <AsyncSelect
                                    placeholder={"Rechercher le personnel"}
                                    required={true}
                                    isLoading={fetching}
                                    isMulti={true}
                                    value={template.targets}
                                    loadOptions={loadOptions}
                                    defaultOptions={options}
                                    onChange={(e: any) => setTemplate((prev: any) => ({...prev, targets: e}))}
                                />
                            </div>
                        }


                        {template?.channels.includes("sms") && <div className="row gy-6 my-5">
                            <p className="h6 m-1 text-secondary">Sms template</p><hr/>
                            <div className="col-12">
                                <input type="text"
                                       name="content"
                                       className="form-control"
                                       value={template?.smsSenderName}
                                       placeholder={getLabel("text.smsSenderName")}
                                       onChange={(e: any) => setTemplate(
                                           (prev: any) =>({...prev, smsSenderName: e.target.value}))}
                                />
                            </div>
                            <div className="col-12">
                            <textarea name="content"
                                      className="form-control"
                                      rows={6}
                                      value={template?.smsTemplate}
                                      placeholder={getLabel("text.smsTemplate")}
                                      onChange={(e: any) => setTemplate(
                                          (prev: any) =>({...prev, smsTemplate: e.target.value}))}
                            />
                            </div>
                        </div>}

                        {template?.channels.includes("email") && <div className="row gy-6 my-5">
                            <p className="h6 m-1 text-secondary">Email template</p><hr/>
                            <div className="col-12">
                                <input type="text"
                                       name="content"
                                       className="form-control"
                                       value={template?.emailSubject}
                                       placeholder={getLabel("text.emailSubject")}
                                       onChange={(e: any) => setTemplate(
                                           (prev: any) =>({...prev, emailSubject: e.target.value}))}
                                />
                            </div>
                            <div className="col-12">
                                <textarea name="content"
                                      className="form-control"
                                      rows={6}
                                      value={template?.emailTemplate}
                                      placeholder={getLabel("text.smsTemplate")}
                                      onChange={(e: any) => setTemplate(
                                          (prev: any) =>({...prev, emailTemplate: e.target.value}))}
                                />
                            </div>
                        </div>}

                        <div className="col-12 my-5">
                            <button className="btn btn-sm w-100 btn-primary align-self-center"
                                onClick={onSaveTemplate}>
                                <IntLabel label="btn.save"/>
                            </button>
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <p className="h6 m-1 text-secondary">Varialble</p><hr/>
                        {Object.keys(NotificationVariable).map((item: any) =>
                            <div className="col-12 m-5">
                                <strong >{item}</strong>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
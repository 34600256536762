import {callAction, callActionWithId} from "../../helpers";

export const fetchTransactions = callAction<any>('/api/transaction/get', 'get', false);
export const fetchTransaction = callActionWithId<any>('/api/transaction/get/{id}', 'get', false);
export const createTransactions = callAction<any>('/api/transaction/add', 'post', false);
export const updateTransactions = callActionWithId<any>('/api/transaction/edit/{id}', 'patch', false);

export const fetchTransactionsCategory = callAction<any>('/api/transaction/category/get', 'get', false);
export const fetchCategory = callActionWithId<any>('/api/transaction/category/get/{id}', 'get', false);
export const createTransactionsCategory = callAction<any>('/api/transaction/category/add', 'post', false);
export const updateTransactionsCategory = callActionWithId<any>('/api/transaction/category/{id}/update', 'patch', false);

export const fetchTransactionPartners = callAction<any>('/api/partner/get', 'get', false);
export const createTransactionPartner = callAction<any>('/api/partner/add', 'post', false);
export const updateTransactionPartner = callActionWithId<any>('/api/partner/edit/{id}', 'patch', false);
export const fetchTransactionPartner = callActionWithId<any>('/api/partner/get/{id}', 'get', false);

export const fetchPartnerContracts = callAction<any>('/api/partner/contracts', 'get', false);
export const fetchPartnerContract = callActionWithId<any>('/api/partner/contract/get/{id}', 'get', false);
export const createPartnerContract = callAction<any>('/api/partner/contract/add', 'post', false);
export const updatePartnerContract = callActionWithId<any>('/api/partner/contract/{id}/update', 'patch', false);

export const createPartnerPayment = callAction<any>('/api/partner/contract/payment', 'post', false);

import React, {useState} from 'react';
import {IntLabel} from "@app/i18n";
import {getLabel} from "@app/i18n/src/components/IntLabel";

interface PartnerPaymentFormProps {
    onFinish?: any
    onCancel?: any
    paymentId?: any
    isLoading?: boolean,
}
function PartnerPaymentForm(props: PartnerPaymentFormProps) {
    const [payment, setPayment] = useState<any>({
        contractId: undefined,
        description: undefined,
        beneficiary: undefined,
        phoneNumber: undefined,
        amount: undefined
    })

    return (<form onSubmit={(e) => {
        e.preventDefault()
        props.onFinish(payment)
    }}>
        <div className="form-row ">
            <div className="row">
                <div className="form-group col-12 my-3">
                    <label>{getLabel("text.description")}</label>
                    <input value={payment.description}
                           required={true}
                           onChange={(e :any )=> setPayment((prev: any) => ({...prev, description: e.target.value}))}
                           className="form-control"
                           placeholder={getLabel('text.description')} />
                </div>
                <div className="form-group col-12 my-3">
                    <label>{getLabel("text.beneficiary")}</label>
                    <input value={payment.beneficiary}
                           required={true}
                           onChange={(e :any )=> setPayment((prev: any) => ({...prev, beneficiary: e.target.value}))}
                           className="form-control"
                           placeholder={getLabel('text.beneficiary')} />
                </div>
                <div className="form-group col-md-6 col-12 my-3">
                    <label>{getLabel("text.amount")}</label>
                    <input value={payment.amount}
                           type="number"
                           required={true}
                           onChange={(e :any )=> setPayment((prev: any) => ({...prev, amount: parseFloat(e.target.value)}))}
                           className="form-control"
                           placeholder={getLabel('text.amount')} />
                </div>
                <div className="form-group col-md-6 col-12 my-3">
                    <label>{getLabel("text.contact")}</label>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">+237</span>
                        </div>
                        <input type="text"
                               value={payment?.phoneNumber?.replace('+237-', '')}
                               onChange={(e :any )=> setPayment(
                                   (prev: any) => ({...prev, phoneNumber: '+237-' + e.target.value}))}
                               className="form-control" placeholder="655555555"/>
                    </div>
                </div>
            </div>

            <div className="form-row  mt-3">
                <div className="row">
                    <div className="form-group col-md-6 mb-6">
                        <button  className="btn btn-secondary w-100"
                             onClick={props.onCancel}><IntLabel label="text.onCancel"/></button>
                    </div>
                    <div className="form-group col-md-6 mb-6">
                        <button type="submit"  className="btn btn-primary w-100">
                            <IntLabel label="text.finish"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>);
}

export default PartnerPaymentForm;
import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {ModalProps} from "../../ModalContainer";
import {addCompanyPayment} from "../../../api";
import toast from "react-hot-toast";
import LandPaymentForm from "../../../components/form/LandPaymentForm";
import {getLabel} from "@app/i18n/src/components/IntLabel";

function AddLandPaymentModal(props: ModalProps) {
    const {contractId, companyId, lang}:{contractId?:any, companyId: any, lang: any} = props.modalProps
    const [fetching, setFetching] = React.useState(false);

    useEffect(() => console.log(lang), [lang])

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{getLabel("text.add.land.payment.label", lang)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LandPaymentForm
                    lang={lang}
                      onSubmit={(payment: any) => {
                          setFetching(true);
                          addCompanyPayment(
                              {companyId: companyId},
                              {
                                  contractId: contractId,
                                  ...payment
                              })
                              .then(() => {
                                  toast.success("text.success.message")
                                  setFetching(false)
                                  props.onClose()
                                  window.location.reload()
                              }).catch((res: any) => {
                                  toast.error("text.error.message" ?? res.message)
                                  setFetching(false)
                          })
                      }}
                      isLoading={fetching}
                      onCancelClick={()=>props.onClose()}
                />
            </Modal.Body>
        </Modal>
    );
}

export default AddLandPaymentModal;
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {IntLabel} from "@app/i18n";
import React from "react";

export interface SubNavItemProps {
    onClick?: () => void;
    label: any;
    parent: any;
    path?: any;
    access?: any;
}

function SubNavItem({nav, idx}: {nav: SubNavItemProps,  idx: any}) {
    const location = useLocation();

    return <div className={`menu-sub menu-sub-accordion ${nav.parent === idx && "show"}`}>
        <div className="menu-item" onClick={nav.onClick}>
            <Link className={`menu-link ${location.pathname === nav.path ? 'active' : ''}`}
                  to={nav.path}>
                <span className="menu-bullet">
                    <span className="bullet bullet-dot"/>
                </span>
                <span className="menu-title fw-bold"><IntLabel label={nav.label}/></span>
            </Link>
        </div>
    </div>;
}

export default SubNavItem;
import {useState} from "react";
import {useDispatch} from "react-redux";
import {isLoading} from "../../../../application/appStore";
import {
    createPartnerContract,
    createPartnerPayment,
    createTransactionPartner,
    updatePartnerContract,
    updateTransactionPartner
} from "@app/core/src/api/domain/company/transaction";

function usePartnerController() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const  createPartner = async  (partner: any) =>  {
        dispatch(isLoading(true))
        setLoading(true)
        createTransactionPartner(partner)
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    const  updatePartner = async  (id: string, partner: any) =>  {
        dispatch(isLoading(true))
        setLoading(true)
        updateTransactionPartner(id, partner)
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    const  createContract = async  (partnerId: string, contract: any) =>  {
        dispatch(isLoading(true))
        setLoading(true)
        createPartnerContract({
            partnerId: partnerId,
            ...contract
        })
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    const  updateContract = async  (id: string, contract: any) =>  {
        dispatch(isLoading(true))
        setLoading(true)

        updatePartnerContract(id, contract)
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    const  addPayment = async  (id: string, contract: any) =>  {
        dispatch(isLoading(true))
        setLoading(true)
        console.log(id, contract)
        createPartnerPayment({
            ...contract,
            contractId: id,
        })
            .then(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
            .catch(() => {
                dispatch(isLoading(false))
                setLoading(false)
            })
    }

    return {
        loading,
        createPartner,
        updatePartner,
        createContract,
        updateContract,
        addPayment
    };
}

export default usePartnerController;
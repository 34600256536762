import React, {useContext, useEffect, useState} from 'react';
import {SideLanguageSelector} from "@app/core/src/components/language/SideLanguageSelector";
import IntlContext from "@app/i18n/src/context/IntlContext";
import {Language} from "@app/i18n/src/languages";
import ModuleHeader from "./components/ModuleHeader";
import {onToggleLanguageDrawer} from "../helpers";


interface AppContainerProps {
    children: React.ReactNode,
    title: any,
    actions?: any[],
    breadcrumb?: any[],
}

function AppContainer(props: AppContainerProps) {
    const [activeLang, setActivated] = useState<any>()
    const lang = useContext(IntlContext);
    const [label, setLabel] = useState<string>( lang.lang !== Language.ENG ? 'FR' : 'EN')

    const onSelectLang = (l: any) => {
        setActivated(!(activeLang))
        lang.toggleLang(l)
        onToggleLanguageDrawer(); // Make the lang sidebar disappear simultaneously
    }

    useEffect(()=>{
        setLabel(() => lang.lang !== Language.ENG ? 'FR' : 'EN')
    }, [lang])
    //
    // const body = document.querySelector('body');
    // body?.addEventListener("click", (e: any) => {
    //     console.log(e.target, e.getParentNode())
    // })

    return (
        <>
            <ModuleHeader {...props}/>
            <div className="content " id="kt_content">
                <div className="container-fluid" id="kt_content_container">
                    {props.children}
                </div>
                <button id="kt_explore_toggle"
                        className="explore-toggle btn btn-sm shadow-sm position-fixed btn-active-dark px-4 zindex-2 top-50 mt-10 end-0 transform-90 rounded-top-0"
                        title="" data-bs-toggle="tooltip" data-bs-placement="right"
                        data-bs-dismiss="click" data-bs-trigger="hover"
                        style={{background: '#808387', border: "none"}}
                        onClick={() => onToggleLanguageDrawer()}
                >
                    <span id="kt_explore_toggle_label" className="text-white"> {label} </span>
                </button>
                <SideLanguageSelector
                    active={activeLang}
                    onClick={() => !(activeLang) && onSelectLang(Language.FRA)}
                    onClick1={() => (activeLang) && onSelectLang(Language.ENG)}
                />
            </div>
        </>
    );
}

export default AppContainer;
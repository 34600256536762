import React from 'react';
import {fetchCompanyContractInterests} from "@app/core/src/api";
import {ListTable} from "@app/core";
import {Link} from "react-router-dom";
import {formatCurrency} from "@app/core/src/service/formatService";
import {PropertyInterestBadge} from "@app/core/src/components/Badges";
import {IntlDate} from "@app/i18n";

interface InterestListProps {
    companyId: string,
    query?: object,
    loading?:boolean
}

function InterestList(props: InterestListProps) {

    const columns: any[] = [
        {
            title: "text.user.name",
            render: (item: any) => <strong>{item.userName}</strong>
        },
        {
            title: "text.contract",
            render: (item: any) => <Link to={`/land/contract/${item?.contract?.id}`} className="fw-bold fs-5">
                {item.contract.reference}
            </Link>
        },
        {
            title: "text.contract.amount",
            render: (item: any) => formatCurrency(item.contract?.amount)
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item?.amount)}</strong>
        },
        {
            title: "text.status",
            align: 'right',
            render: (item: any) => <PropertyInterestBadge status={item?.status}/>
        },
        {
            title: "text.contract.start.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item?.startDate}/>
        },
        {
            title: "text.date",
            align: 'right',
            render: (item: any) => <IntlDate date={item?.createdAt}/>
        },
    ]

    return (
        <ListTable columns={columns}
                   fetchData={fetchCompanyContractInterests}
                   addQuery={{ 'order[created_at]': 'DESC', ...props.query }}
                   addParam={{ companyId: props.companyId }}/>
    );
}

export default InterestList;
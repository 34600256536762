import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {IntLabel} from "@app/i18n";
import LandPartForm from "../../../components/form/LandPartForm";
import {ModalProps} from "../../ModalContainer";
import {editCompanyLandPart, fetchCompanyLandPart} from "../../../api";
import toast from "react-hot-toast";

function EditLandPartModal(props: ModalProps) {
    const {landId, companyId, landPartId}:{landId?:any, companyId: any, landPartId: any} = props.modalProps
    const [fetching, setFetching] = React.useState(false);
    const [landPart, setLandPart] = useState<any | undefined>(undefined)

    useEffect(() => {
        setFetching(true)
        fetchCompanyLandPart({companyId: companyId, id: landPartId})
            .then((res: any) => {
                setLandPart(res.data)
                setFetching(false)
            })
        return () => {};
    }, [landPartId]);

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><IntLabel label="btn.edit.land.part"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LandPartForm
                    landPart={{
                        bloc: landPart?.bloc,
                        partNumber: landPart?.partNumber,
                        area: landPart?.partNumber
                    }}
                    onSubmit={(part: any) => {
                        setFetching(true);
                        editCompanyLandPart(
                            {companyId:companyId, id: landPartId },
                            {
                                landId: landId,
                                ...part
                            })
                            .then(() => {
                                toast.success("text.success.message")
                                setFetching(false)
                                props.onClose()
                                window.location.reload()
                            }).catch((res: any) => {
                            toast.error("text.error.message" ?? res.message)
                            setFetching(false)
                        })
                    }}
                    isLoading={fetching}
                    onCancelClick={()=>props.onClose()}
                />
            </Modal.Body>
        </Modal>
    );
}

export default EditLandPartModal;
import React, {useEffect} from 'react';
import {logout} from "@app/core/src/api";
import LocalStorage from "@app/core/src/api/axios/localStorage";
import {useDispatch} from "react-redux";
import {securityRoutesPaths} from "../routing/securityRoutesPaths";
import {onLogout} from "../../../../application/appStore";

function LogoutPage() {
    const dispatch = useDispatch()

    useEffect(() => {
        logout()
        LocalStorage.clearAll()
        dispatch(onLogout())
        window.location.href = securityRoutesPaths.LOGIN_PAGE
    }, [])

    return <div>Fetching....</div>;
}

export default LogoutPage;
import {useEffect, useState} from "react";
import React from "react";

export function FormatPhone(props: {phone: string}){
    const [formated, setFormated] = useState<string>("")

    useEffect(() => {
        props.phone?.toString()?.split('')?.map((c: any, index: number) => {
            setFormated((f: any) => f.concat(c))
            if(index === 3)
                setFormated((f: any) => f.concat(' '))
            else if( index === 6 || index === 9)
                setFormated((f: any) => f.concat('-'))
        })
    }, [])

    return <>{formated}</>
}

export enum transactionRoutePaths {
    TRANSACTION = '/transaction',
    TRANSACTION_HISTORY = '/transaction/history',

    CATEGORY = '/transaction/category',
    CATEGORY_VIEW = '/transaction/category/:id',

    PARTNER = '/transaction/partner',
    PARTNER_VIEW = '/transaction/partner/:id',
    PARTNER_CONTRACT = '/transaction/partner/contract',
}
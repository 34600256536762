import React, {useState} from 'react';
import {IntLabel} from "@app/i18n";

export interface AppButtonProps  {
    label: string,
    onClick?: () => any,
    size?: string,
    disabled?: boolean,
    background?: string,
    hover?: boolean,
    loading?: boolean,
}

function Button(props: AppButtonProps) {


    return (
        <button className={`btn fw-bold w-100 btn-${props.size ?? 'sm'} btn-${props.background ?? 'primary'}`}
                disabled={props.disabled ?? false} onClick={props.onClick}>
            <span className={'indicator-label'}>{<IntLabel label={props.label}/>}</span>

            <span className="indicator-progress h4">
                    <IntLabel label={'text.wait'}/>
                    <span className="spinner-border spinner-border-sm align-middle ms-2"/>
            </span>
        </button>
    );
}


export default  Button;
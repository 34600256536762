import {useDispatch, useSelector} from "react-redux";
import {isLoading, onCompanyChange} from "../application/appStore";
import {fetchUserCompanyDetails} from "@app/core/src/api";

function useCompany() {
    const {currentCompany} = useSelector((state: any) => state.appSlice)
    const dispatch = useDispatch()

    const changeCompany = (companyId: string) => {
        dispatch(isLoading(true))
        fetchUserCompanyDetails(companyId)
            .then((res: any) => {
                dispatch(onCompanyChange({
                    currentCompany: companyId,
                    access: res.data.access
                }))
                dispatch(isLoading(false))
            })
            .catch(() => {
                dispatch(isLoading(false))
            })
    }

    return {changeCompany, currentCompany}
}

export default useCompany;
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {PropertyStatusBadge} from "@app/core/src/components/Badges";
import {Button, ListTable} from "@app/core";
import {deleteCompanyLandPart, fetchCompanyLandParts, publishCompanyLand} from "@app/core/src/api";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {PropertyStatus} from "@app/core/src/interface/Enums";
import {formatCurrency, formatNumber} from "@app/core/src/service/formatService";
import {useModal} from "@app/core/src/modals";
import CustomSVG from "@app/core/src/components/icons/svg";
import {isLoading} from "../../application/appStore";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";

interface LandPartListProps {
    companyId: string,
    query?: object,
    landId?: string,
    canAdd?: boolean,
}
function LandPartList(props: LandPartListProps) {
    const {showModal, hideModal} = useModal()
    const dispatch = useDispatch()
    const [status, setStatus] = useState<any | undefined>(undefined);

    const columns: any[] = [
        {
            title: "text.land.title",
            render: (item: any) => <Link to={`/land/${item.land.id}`} className="fw-bold fs-5">{item.land?.landTitle}</Link>
        },
        {
            title: "text.amount",
            align: 'right',
            render: (item: any) => <strong>{formatCurrency(item.amount)}</strong>
        },
        {
            title: "text.area",
            align: 'center',
            render: (item: any) => <div>{formatNumber(item.area)} m<sup>2</sup></div>
        },
        {
            title: "text.bloc",
            align: 'center',
            render: (item: any) => item.bloc
        },
        {
            title: "text.land.part.number",
            align: 'center',
            render: (item: any) => item.number
        },
        {
            title: "text.status",
            align: 'center',
            render: (item: any) => <PropertyStatusBadge status={item.status}/>
        },
        {
            title: "text.edit",
            align: 'center',
            render: (item: any) => item.status !== 'sold' ? <span
                 className="text-primary cursor-pointer"
                 onClick={() => showModal(
                     'EDIT_LAND_PART_MODAL',
                     {
                         landPartId: item?.id,
                         landId: item?.land?.id,
                         companyId: props.companyId
                     })
                 }
            >
                <CustomSVG type="pencil"/>
            </span> : ''
        },
        {
            title: "text.delete",
            align: 'center',
            render: (item: any) => item.status !== 'sold' ? <span
                className="text-danger cursor-pointer"
                onClick={() => showModal(
                    'APP_CONFIRMATION_MODAL',
                    {
                        onValidate: () =>{
                            dispatch(isLoading(true))
                            deleteCompanyLandPart(
                                {companyId: props.companyId, id: item.id},
                            ).then(() => {
                                toast.success("text.success.message")
                                dispatch(isLoading(false))
                                hideModal('APP_CONFIRMATION_MODAL')
                                window.location.reload()
                            }).catch((res: any) => {
                                toast.error("text.error.message" ?? res.message)
                                dispatch(isLoading(false))
                            })
                        }
                    })
                }
            >
                <CustomSVG type="trash"/>
            </span> : ''
        },
    ]

    return (
        <ListTable columns={columns} fetchData={fetchCompanyLandParts}
                   addQuery={{
                       ...{'order[created_at]': 'DESC', 'equal[status]' : status},
                       ...props.query
                   }}
                   addParam={{ companyId: props.companyId }}>
            <div className="row">
                <div className="col-md-4 col-12 mb-md-0 mb-5">
                    <EnumSelect enum={PropertyStatus}
                                onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                </div>
                {props.canAdd && <div
                    className="col-md-3 col-12 ms-auto mb-md-0 mb-5">
                    <Button label="btn.add.land.part"
                            onClick={() => showModal(
                                'ADD_LAND_PART_MODAL',
                                {
                                    landId: props?.landId,
                                    companyId: props.companyId
                                })
                            }/>
                </div>}
            </div>
        </ListTable>
    );
}

export default LandPartList;
import React, {useEffect, useState} from 'react';
import {defaultListData} from "../../../interface/ListDataInterface";
import AppTable from "./index";
import {PaginationControl} from "react-bootstrap-pagination-control";
import {ListTableProps, parseData} from "./ListTable";
import AppValueDisplay from "../Card/AppValueDisplay";

interface AnalyticsTableProps extends ListTableProps {
    cards?: { label: string; render: (item: any) => string; }[]
}

function AnalyticsTable(props: AnalyticsTableProps) {
    const [query, setQuery] = useState<string | undefined>()
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(defaultListData());
    const [page, setPage] = useState(1);

    useEffect(() => {
        loadData()
    }, [page, query, props.addQuery, props.refresh])

    const loadData = (filter?: string) => {
        setLoading(true)
        let paginate: any = {limit: data?.meta?.pagination.limit, offset: page}
        let callBack = props.addParam === undefined ?
            props.fetchData(parseData(props, paginate, filter, query)) :
            props.fetchData(props.addParam, parseData(props, paginate, filter, query))

        callBack.then((res: any) => {
            setData(res)
            setLoading(false)
        }).catch(() => setLoading(false))
    }

    return (
        <div className="row">
            {(props.cards !== undefined || data?.analytics !== undefined) &&
                <div className="col-12">
                    <div className="row justify-content-end">
                        {props.cards?.map((item, index) =>
                            <div className="col-12 col-md-3 mb-5" key={index}>
                                <AppValueDisplay isLoading={isLoading}
                                    value={item.render(data?.analytics)} label={item.label}/>
                            </div>
                        )}
                    </div>
                </div>}
            <div className="col-12 mb-5">
                <div className="card shadow-sm p-3">
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <input onChange={(e) => setQuery(e.target.value)} value={query}
                                   className="form-control form-control-sm" placeholder="Recherche..."
                            />
                        </div>
                        <div className="col-12 col-md-9">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-5">
                <div className="card shadow-sm p-5">
                    <AppTable data={data?.data} isLoading={isLoading} columns={props.columns}/>
                </div>
            </div>
            <div className="col-12 col-md-6 mt-5">
                Total {data?.meta?.pagination.total}
            </div>
            <div className="col-12 col-md-6 mt-5">
                <PaginationControl
                    page={page}
                    between={4}
                    total={data?.meta?.pagination.total}
                    limit={data?.meta?.pagination.limit}
                    changePage={(page) => setPage(page)}
                    ellipsis={1}
                />
            </div>
        </div>
    );
}

export default AnalyticsTable;
import React from "react";
import AppLoginPage from "../pages/login/AppLoginPage";
import AppRegisterPage from "../pages/register/AppRegisterPage";
import {securityRoutesPaths} from "./securityRoutesPaths";
import LogoutPage from "../pages/LogoutPage";

const securityRoutes = [
    {
        path: securityRoutesPaths.LOGIN_PAGE,
        element: <AppLoginPage/>
    },
    {
        path: securityRoutesPaths.REGISTER_PAGE,
        element: <AppRegisterPage/>
    },
    {
        path: securityRoutesPaths.LOGOUT_PAGE,
        element: <LogoutPage/>
    },
];

export default securityRoutes;
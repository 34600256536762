export enum PaymentStatus {
    pending = 'payment.pending',
    failed = 'payment.failed',
    done = 'payment.done',
}

export enum Verified {
    verified = 'text.verified',
    pending = 'text.not.verified',
}

export enum ContractPaymentStatus {
    late = 'text.late',
    advanced = 'text.advanced',
}

export enum ContractStatus {
    ongoing = 'contract.ongoing',
    finish = 'contract.finish',
    cancelled = 'contract.cancelled',
    draft = 'contract.draft',
    archived = 'contract.archived',
}

export enum PropertyStatus {
    sold = 'property.sold',
    available = 'property.available',
    booked = 'property.booked',
}

export enum PropertyVisibility {
    draft = 'property.draft',
    published = 'property.published',
    unpublished = 'property.unpublished',
}

export enum TransactionType {
    payment = 'transaction.payment',
    disburse = 'transaction.disburse',
    deposit = 'transaction.deposit',
}

export enum PaymentMethod {
    orange_money_cameroon = 'payment.orange_money_cameroon',
    mtn_mobile_money_cameroon = 'payment.mtn_mobile_money_cameroon',
    stripe = 'payment.stripe',
    cash = 'payment.cash',
    bank_transfer = 'payment.bank_transfer',
}

export enum AccessDomainEnum {
    land = 'domain.land',
    hotel = 'domain.hotel',
    real_estate = 'domain.real_estate',
    company = 'domain.company',
}

export enum InvitationStatus {
    accepted = 'invitation.accepted',
    created = 'invitation.created',
    rejected = 'invitation.rejected',
}

export enum InterestStatus {
    generated = 'interest.generated',
    unpaid = 'interest.unpaid',
    paid = 'interest.paid',
    draft = 'interest.draft',
}

export function enumLabel(enums: any, value: string): string {
    try {
        return enums[value]
    } catch {
        return value;
    }
}

export function labelEnum(enums: any, label: string){
    try {
        const key = Object.entries(enums).map((e: any[], index: number) => label === e[1] && e[0])
        return key[0];
    }catch {
        return label;
    }
}
export interface PaginationProps {
    offset: number;
    limit: number;
    total: number;
}

export interface ListDataInterface {
	meta: {
        searchable: any[];
        classifiable: any[];
        pagination: PaginationProps;
    };
	data: any[];
    analytics?: string[]
}

export function defaultListData() {
    return <ListDataInterface>{
        meta: {
            searchable: [],
            classifiable: [],
            pagination: {
                offset: 1,
                limit: 10,
                total: 0,
            },
        },
        data: [],
    }
}

export function hasMore(data: ListDataInterface): boolean {
    return data.data.length < data.meta.pagination.total;
}
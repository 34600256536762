const enumLabel = {
    "contract.ongoing": "ongoing",
    "contract.finish": "finish",
    "contract.cancelled": "cancelled",
    "contract.draft": "draft",
    "contract.archived": "archived",

    "payment.orange_money_cameroon": "Orange money",
    "payment.mtn_mobile_money_cameroon": "Mobile money",
    "payment.card_payment": "Bank card",
    "payment.cash": "Cash",
    "payment.bank_transfer": "Bank transfer",

    'interest.generated': "initiated",
    'interest.unpaid': "unpaid",
    'interest.paid': "paid",
    'interest.draft': "draft",

    "invitation.accepted": "Accepted",
    "invitation.created": "Created",
    "invitation.rejected": "Rejected",

    'level.basic': "basic",
    'level.medium': "medium",
    'level.high': "high",
    'level.owner': "owner",

    'transaction.payment': 'Paiement',
    'transaction.deposit': 'Versemment',
    'transaction.disburse': 'Decaisssement',
}


export default enumLabel;

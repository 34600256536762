import React, {useEffect, useState} from 'react';
import {appPaths} from "../../../app/routing/appRoutesPaths";
import {IntLabel, IntlDate} from "@app/i18n";
import AppContainer from "../../../../../components/Layout/AppContainer/AppContainer";
import BackButton from "@app/core/src/components/common/Button/Action/BackButton";
import DetailsCardView from "../../../../../components/DetailsCard/DetailsCardView";
import {ContractList, LandPartList} from 'components/Lists';
import {useParams} from "react-router";
import {appStoreProps} from "../../../../../application/appStore";
import {useSelector} from "react-redux";
import {formatCurrency, formatNumber} from "@app/core/src/service/formatService";
import {PropertyVisibilityStatusBadge} from "@app/core/src/components/Badges";
import {landRoutePaths} from "../../routing/landRoutePaths";
import LandDetails from "./LandDetails";
import {fetchCompanyLand, fetchLandLogs} from "@app/core/src/api";
import LogListTable from "../../../../../components/Lists/LogListTable";

function getTabs(activeTab: string, setActiveTab: (value: (((prevState: string) => string) | string)) => void) {
    return [
        {label: 'text.details', isActive: activeTab === '', tab: '', handle: (value: any) => setActiveTab(value)},
        {label: 'text.land.parts', isActive: activeTab === 'parts', tab: 'parts', handle: (value: any) => setActiveTab(value)},
        {label: 'text.contracts', isActive: activeTab === 'contracts', tab: 'contracts', handle: (value: any) => setActiveTab(value)},
        {label: 'text.logs', isActive: activeTab === 'logs', tab: 'logs', handle: (value: any) => setActiveTab(value)},
    ];
}

function getAmountValues(property: any) {
    return [
        {
            label: 'land.part.count',
            value: formatNumber(property?.parts ?? 0)
        },
        {
            label: 'land.square.price',
            value: formatCurrency(property?.squarePrice ?? 0)
        },
        {
            label: 'land.area',
            value: <div>{formatNumber(property?.area ?? 0)} m<sup>2</sup></div>
        },
        {
            label: 'land.paid.amount',
            value: formatCurrency(property?.paidAmount ?? 0)
        },
        {
            label: 'land.values',
            value: formatCurrency(property?.landValue ?? 0)
        }
    ];
}

function LandDetailsView({}: any) {
    const {id} = useParams();
    const [land, setLand] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [activeHash, setActiveHash] = useState(window.location.hash?.substring(1))
    const {currentCompany}: appStoreProps = useSelector((state: any) => state.appSlice)

    useEffect(() => {
        setLoading(true)
        fetchCompanyLand(
            {id: id, companyId: currentCompany}
        ).then((res: any) => {
            setLand(res.data)
            setLoading(false)
        }).catch(() => setLoading(false))

        return () => {}
    }, [id]);

    useEffect(()=>{
        setActiveHash(window.location.hash?.substring(1))
    },[window.location.hash?.substring(1)])
    function getContent(tab: string) {
        switch (tab) {
            case '':
                return <LandDetails property={land} loading={isLoading} companyId={currentCompany ?? ''}/>;
            case 'parts':
                return <LandPartList canAdd={true} companyId={currentCompany ?? ''} landId={id} query={{ 'uuid[land_id]': id }}/>;
            case 'contracts':
                return <ContractList canAdd={true} landId={id}  companyId={currentCompany ?? ''} query={{ 'uuid[land_id]': id }}/>;
            case 'logs':
                return <LogListTable
                    addQuery={{ 'uuid[land_id]': id }}
                    fetchData={fetchLandLogs}
                    addParams={{ companyId: currentCompany }} />;
            default:
                return 'Unknown step';
        }
    }

    return (
        <AppContainer breadcrumb={[
            {label: 'menu.domain.app.overview', path: appPaths.APP},
            {label: 'menu.domain.land.overview', path: landRoutePaths.LAND_VIEW},
            {label: 'menu.domain.land.details'},
        ]} actions={[
            <BackButton />
        ]} title={<IntLabel label="menu.domain.land.overview"/>}>
            <div className="row">
                <div className="col-12">
                    <DetailsCardView
                        title={`${land?.landTitle ?? ''} - ${land?.title ?? ''}`}
                        loading={isLoading}
                        badges={<PropertyVisibilityStatusBadge status={land?.status}/>}
                        description={land?.description ?? ''}
                        tabs={getTabs(activeHash, setActiveHash)}
                        amountValues={getAmountValues(land)}
                    />
                </div>
                <div className="col-12 mt-5">
                    {getContent(activeHash)}
                </div>
            </div>
        </AppContainer>
    );
}

export default LandDetailsView;
import React from 'react';
import AppCardValueDisplay, {AppCardValueDisplayProps} from "@app/core/src/components/common/Card/AppCardValueDisplay";
import {IntLabel} from "@app/i18n";
import TextTranslator from "@app/i18n/src/components/TextTranslator";

interface tabsProps {
    label: string;
    isActive: boolean;
    tab: string;
    handle: (value: any) => void;
}

interface DetailsCardView {
    title: string,
    description: string,
    loading: boolean,
    badges?: any,
    amountValues?: AppCardValueDisplayProps[]
    tabs: tabsProps[],
    noCard?: any
}
function DetailsCardView(props: DetailsCardView) {

    return (
        <div className="card shadow-sm">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2 justify-content-between">
                                    <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                        {props.loading ?
                                            <div className="skeleton-box" style={{ width: '200px', height: '20px'}}/> :
                                            <div style={{marginRight: "1.5rem"}}>{props.noCard ?
                                                <IntLabel label={props.title}/> : <TextTranslator text={props.title}/>}</div>}
                                    </a>
                                    {props.loading ?
                                        <div className="skeleton-box" style={{ width: '50px', height: '20px', marginLeft: "1rem"}}/> :
                                        props.badges
                                    }
                                </div>
                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                    <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                        {props.loading ?
                                            <div className="skeleton-box" style={{ width: '100px', height: '20px'}}/> :
                                            <TextTranslator text={props.description}/>
                                        }
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap flex-md-nowrap">
                            {(props.amountValues || []).map((item, index) =>
                                <AppCardValueDisplay key={index} loading={props.loading}
                                     value={item.value} label={item.label}/>
                            )}
                        </div>
                    </div>
                </div>

                <div className="d-flex overflow-auto h-55px">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                        <li className="nav-item">
                            {props.tabs.map((item: any, index: number) =>
                                <a key={index} href={`#${item.tab}`} onClick={() => item.handle(item.tab)}
                                     className={`nav-link text-active-primary me-6 cursor-pointer ${item.isActive ? 'active' : ''}`}>
                                    <IntLabel label={item.label}/>
                                </a>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default DetailsCardView;
import React from 'react';
import _ from "lodash";

function TableSkeleton({column}: {column?: number}) {
    return (
        <div className="table-responsive">
            <table className="table table-row-bordered table-responsive">
                <thead>
                {_.times(column ?? 5, (i) =>
                    <th className={`pe-3 `}>
                        <div className="skeleton-box" style={{ width: '100%', height: '20px'}}/>
                    </th>
                )}
                </thead>

                <tbody>
                    {_.times(10, (i) =>
                        <tr key={i}>
                            {_.times(column ?? 5,(ik) => {
                                return <td key={ik}>
                                    <div className="skeleton-box" style={{ width: '100%', height: '20px'}}/>
                                </td>
                            })}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default TableSkeleton;
import React from "react";
import CustomIconSVG from './assets/CustomIconSVG'
import MenuIconSvg from "./assets/MenuIconSvg";

export const svg_assets = {
    ...CustomIconSVG,
    ...MenuIconSvg,
    arrow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
        </svg>
    )
}

function CustomSVG(props: { type: Extract<keyof typeof svg_assets, string>, onClick?: any, className?: any }) {

    return <div onClick={props.onClick} className={props.className}>{(svg_assets as any)[props.type] || null}</div>;
}

export default CustomSVG;
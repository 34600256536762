import React, {useState} from 'react';
import AppContainer from "../../../../../../components/Layout/AppContainer/AppContainer";
import {companyRoutesPaths} from "../../../routing/companyRoutesPaths";
import {Button, ListTable} from "@app/core";
import {IntLabel, IntlDate} from "@app/i18n";
import {fetchCompanyAllInvitations, fetchUserAllInvitations} from "@app/core/src/api";
import {useModal} from "@app/core/src/modals";
import {useSelector} from "react-redux";
import {ColumnsProps} from "@app/core/src/components/common/Table";
import {InvitationStatusBadge} from "@app/core/src/components/Badges";
import EnumSelect from "@app/core/src/components/EnumSelect";
import {InvitationStatus} from "@app/core/src/interface/Enums";
import CustomSVG from "@app/core/src/components/icons/svg";
import DetailsCardView from "../../../../../../components/DetailsCard/DetailsCardView";

function getTabs(activeTab: string, setActiveTab: (value: (((prevState: string) => string) | string)) => void) {
    return [
        {label: 'text.invitations.all', isActive: activeTab === '', tab: '', handle: (value: any) => setActiveTab(value)},
        {label: 'text.invitations.user', isActive: activeTab === 'user', tab: 'user', handle: (value: any) => setActiveTab(value)},
    ];
}

function CompanyInvitationList(props: any){
    const [status, setStatus] = useState()

    return(
        <ListTable fetchData={fetchCompanyAllInvitations} columns={props?.columns} addParam={{ id: props?.companyId }}
                   addQuery={{'equal[status]': status ?? ""}}>
            <div className="row">
                <div className="col-md-4 col-12">
                    <EnumSelect enum={InvitationStatus}
                                onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                </div>
            </div>
        </ListTable>
    )
}

function UserInvitationList(props: { columns: ColumnsProps[], userId: any } ) {
    const [status, setStatus] = useState()

    return (
        <ListTable fetchData={fetchUserAllInvitations} columns={props?.columns}
                   addQuery={{'equal[status]': status ?? ""}}>
            <div className="row">
                <div className="col-md-4 col-12">
                    <EnumSelect enum={InvitationStatus}
                                onChange={(e: any) => setStatus(e.target.value)} value={status}/>
                </div>
            </div>
        </ListTable>
    );
}

function EmployeesInvitationsView(){
    const {showModal} = useModal()
    const {currentCompany} = useSelector((state: any) => state.appSlice)
    const [activeHash, setActiveHash] = useState(window.location.hash?.substring(1))


    const columns: ColumnsProps[]= [
        {
            title: "invitation.recipient",
            render: (item: any) => item.user.name,
        },
        {
            title: "invitation.recipient.email",
            render: (item: any) => item.recipient,
        },
        {
            title: "text.satus",
            align:'center',
            render: (item: any) => <InvitationStatusBadge status={item.status}/> ,
        },
        {
            title: "invitation.date.created",
            align:'center',
            render: (item: any) => <IntlDate date={item.created_at}/> ,
        },
        {
            title: 'text.info',
            width: '6%',
            align: "center",
            key: "info",
            render: (item: any) => <div style={{cursor: 'pointer'}} onClick={()=> showModal('APP_COMPANY_INVITATION',{
                currentInvitation: item,})}> <CustomSVG type="info"/> </div>
        }
    ]

    function getContent(tab: string) {
        switch (tab) {
            case '':
                return <CompanyInvitationList columns={columns} companyId={currentCompany}/>
            case 'user':
                return <UserInvitationList columns={columns} userId={'7ba038a6-06c8-11ee-be9c-4d221a839a92'} />
            default:
                return 'Unknown step';
        }
    }

    return(
        <AppContainer breadcrumb={[
            {label: 'menu.domain.company.overview', path: companyRoutesPaths.COMPANY_EMPLOYEES},
            {label: 'menu.domain.company.invitations'},
        ]} actions={[
            <Button label="btn.add.invitation"
                    onClick={() => showModal(
                        'APP_COMPANY_INVITATION',
                        {
                            title: "modal.invitation",
                        }
                    )}/>
        ]} title={<IntLabel label="menu.domain.company.overview"/>}>

            <div className="row">
                <div className="col-12">
                    <DetailsCardView
                        noCard={true}
                        title={ 'text.invitations.list'}
                        loading={false}
                        description={'Company'}
                        tabs={getTabs(activeHash, setActiveHash)}
                    />
                </div>
                <div className="col-12 mt-5">
                    {getContent(activeHash)}
                </div>
            </div>
        </AppContainer>

    )
}

export default EmployeesInvitationsView;
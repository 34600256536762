export const accessLabels = {
    'access.read.land': "Consulter terrains",
    'access.read.land.contract': "Consulter contrats des terrains",
    'access.read.land.interest':"Consulter interets des terrains",
    'access.read.land.payment':"Consulter paiements des terrains",
    'access.read.hotel':"Consulter hotels",
    'access.read.hotel.contract':"Consulter contrats des hotels",
    'access.read.hotel.interest':"Consulter interets sur les hotels",
    'access.read.hotel.payment':"Consulter paiements hotels",
    'access.read.estate':"Consulter biens immobiliers",
    'access.read.estate.contract':"Consulter contrats sur biens immobiliers",
    'access.read.estate.interest':"Consulter interets sur biens immobiliers",
    'access.read.estate.payment':"Consulter paiements sur biens immobiliers",
    'access.read.company':"Consulter enteprises",
    'access.read.company.contract':"Consulter contrats des enteprises",
    'access.read.company.interest':"Consulter interets des enteprises",
    'access.read.company.payment':"Consulter paiements des enteprises",

    'access.manage.land':"Gestion des terrains",
    'access.manage.land.contract':"Gestion contrats des terrains",
    'access.manage.land.interest':"Gestion interets des terrains",
    'access.manage.land.payment':"Gestion paiements des terrains",
    'access.manage.hotel':"Gestion des hotels",
    'access.manage.hotel.contract':"Gestion contrats des hotels",
    'access.manage.hotel.interest':"Gestion interets des hotels",
    'access.manage.hotel.payment':"Gestion paiements des hotels",
    'access.manage.estate':"Gestion de biens immobiliers",
    'access.manage.estate.contract':"Gestion contrats sur biens immobiliers",
    'access.manage.estate.interest':"Gestion interets sur biens immobiliers",
    'access.manage.estate.payment':"Gestion paiements sur biens immobiliers",
    'access.manage.company':"Gestion des entreprises",
    'access.manage.company.contract':"Gestion contrats des entreprises",
    'access.manage.company.interest':"Gestion interets des entreprises",
    'access.manage.company.payment':"Geston paiements des entreprises",

    'access.manage.company.employee':"Geston de employes des entreprises",
    'access.manage.land.employee':"Geston employes terrains",
    'access.manage.hotel.employee':"Geston employes des hotels",
    'access.manage.estate.employee':"Geston employes des biens immobiliers",

    'access.validate.land':"Validation des terrains",
    'access.validate.land.contract':"Validation contrats des terrains",
    'access.validate.land.interest':"Validation interets des terrains",
    'access.validate.land.payment':"Validation paiements des terrains",
    'access.validate.hotel':"Validation hotels",
    'access.validate.hotel.contract':"Validation contrats des hotels",
    'access.validate.hotel.interest':"Validation interets des hotels",
    'access.validate.hotel.payment':"Validation paiements des hotels",
    'access.validate.estate':"Validation des biens immobiliers",
    'access.validate.estate.contract':"Validation contrats sur biens immobiliers",
    'access.validate.estate.interest':"Validation interets sur biens immobiliers",
    'access.validate.estate.payment':"Validation paiements sur biens immobiliers",
    'access.validate.company':"Validation des entreprises",
    'access.validate.company.contract':"Validation contrats des entreprises ",
    'access.validate.company.interest':"Validation interets des entreprises",
    'access.validate.company.payment':"Validation paiements des entreprises",
}
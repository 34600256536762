import React, {useState} from 'react';
import {IntLabel} from "@app/i18n";
import {getLabel} from "@app/i18n/src/components/IntLabel";
import AppAsyncSelect from "../AsyncSelect/AppAsyncSelect";
import {fetchAllUsers, fetchCompanyLandParts} from "../../api";
import {formatCurrency, formatNumber} from "../../service/formatService";

interface FormContractProps {
    onFinish?: any
    companyId: any
    landId: string,
    onCancel?: any
    isLoading: boolean,
    contract?: {
        contractorId: undefined,
        period: undefined,
        squarePrice: 0,
        interestPercent: undefined,
        startDate: undefined,
        parts: []
    }
}
function FormContract(props: FormContractProps) {
    const [area, setArea] = useState(0)
    const [contract, setContract] = useState({
        contractorId: undefined,
        period: undefined,
        squarePrice: 0,
        interestPercent: undefined,
        startDate: undefined,
        parts: []
    });

    return (<form onSubmit={(e) => {
        e.preventDefault()
        props.onFinish(contract)
    }}>
        <div className="form-row ">
            <div className="row">
                <div>
                    <div className="card card-flush h-md-50 shadow-sm" style={{background:'#008374'}}>
                        <div className="card-header">
                            <div className="row col-12 justify-content-end  ">
                                <div className=" mb-6 mt-4 fs-1 fw-boldest text-white">
                                    <IntLabel label="Montant"/>: {formatCurrency(area * contract.squarePrice)}
                                </div>
                                <hr/>
                                <div className="fs-3 text-white">
                                    <IntLabel label="text.area"/>: {area}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-12 my-6">
                    <label>{getLabel("text.contractor")}</label>
                    <AppAsyncSelect
                        fetchData={fetchAllUsers}
                        onChange={(e :any )=> setContract((prev: any) => ({...prev, contractorId: e[0]?.value}))}
                        parsing={(data: any) => {
                            // @todo: add label 'Bloc' 'Numero' 'Superficie' in translation
                            return {value: data.id, label: `Nom: ${data.name} | Email: ${data.email} | Contact: ${data.contact}`}
                        }}/>
                </div>
                <div className="form-group col-md-6 col-12 my-6">
                    <label>{getLabel("text.square.price")}</label>
                    <input type="number"
                           value={contract.squarePrice}
                           required={true}
                           onChange={(e :any )=> setContract((prev: any) => ({...prev, squarePrice: parseFloat(e.target.value)}))}
                           className="form-control"
                           placeholder={getLabel('text.square.price')} />
                </div>
                <div className="form-group col-md-6 col-12 my-6">
                    <label>{getLabel("text.contract.start.date")}</label>
                    <input type="date"
                           value={contract.startDate}
                           required={true}
                           onChange={(e :any )=> setContract((prev: any) => ({...prev, startDate: e.target.value}))}
                           className="form-control" />
                </div>
                <div className="form-group col-md-6 col-12 my-6">
                    <label>{getLabel("contract.period")}</label>
                    <input type="number"
                       value={contract.period}
                       required={true}
                       onChange={(e :any )=> setContract((prev: any) => ({...prev, period: parseInt(e.target.value)}))}
                       className="form-control"
                       placeholder={getLabel('contract.period')} />
                </div>
                <div className="form-group col-md-6 col-12 my-6">
                    <label>{getLabel("contract.interest.percent")}</label>
                    <input type="number"
                           value={contract.interestPercent}
                           required={true}
                           onChange={(e :any )=> setContract((prev: any) => ({...prev, interestPercent: parseFloat(e.target.value)}))}
                           className="form-control"
                           placeholder={getLabel('contract.interest.percent')} />
                </div>
                <div className="form-group col-md-12 my-6">
                    <label>{getLabel("text.land.parts")}</label>
                    <AppAsyncSelect
                        fetchData={fetchCompanyLandParts}
                        isMulti={true}
                        onChange={(e :any )=> {
                            let amount = 0
                            e.map((item: any) => amount += item.area)
                            setArea(amount)
                            setContract((prev: any) => ({...prev, parts: e.map((item: any) => item.value)}))
                        }}
                        parsing={(data: any) => {
                            // @todo: add label 'Bloc' 'Numero' 'Superficie' in translation
                            return {value: data.id, area: data.area, label: `Bloc: ${data.bloc} | Numéro de lot: ${data.number} | Superficie: ${formatNumber(data.area)}`}
                        }}
                        addQuery={{ 'uuid[land_id]': props.landId, 'equal[status]': 'available' }}
                        addParam={{ companyId: props.companyId}}/>
                </div>
            </div>

            <div className="form-row  mt-3">
                <div className="row">
                    <div className="form-group col-md-6 mb-6">
                        <button  className="btn btn-secondary w-100" onClick={props.onCancel}><IntLabel label="text.onCancel"/></button>
                    </div>
                    <div className="form-group col-md-6 mb-6">
                        <button type="submit"
                                disabled={props.isLoading || undefined === contract.contractorId || contract.parts.length === 0}
                                className="btn btn-primary w-100">
                            <IntLabel label="text.finish"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>);
}

export default FormContract;
import React, {useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import {ModalProps} from "../../ModalContainer";
import {useForm} from "../../../components/form/CustomForm";


function EditCompanyModal(props: ModalProps){
    // const form = useForm("form-test");
    //
    // useEffect(()=>{
    //     form.formElement && console.log(form.formElement)
    //     form.formElement && console.log(form.formElement.elements)
    //
    // }, [form.formElement])
    //
    // useEffect(()=>{
    //         form.formData && console.log(form.formData.entries())
    // }, [form.formData])


    return(
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props?.modalProps?.title ?? "Modal title"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="form-test" className="pt-3 pb-3">
                    <div className="row gy-6">
                        <div className="col-6">
                            <input type="text" name="name" className="form-control" placeholder="Company name"/>
                        </div>
                        <div className="col-6">
                            <input type="text" name="acronym" className="form-control" placeholder="Acronyme"/>
                        </div>
                        <div className="col-12 mt-6">
                            <button type="submit" disabled={false} onClick={() => {}} className={"btn w-100 btn-primary"}>
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
};

export default EditCompanyModal;
import React, {useEffect, useState} from "react";
import SidebarTabPane from "./components/TabPane";
import {useLocation, useNavigate} from "react-router";
import {IntLabel} from "@app/i18n";
import {Link} from "react-router-dom";
import SubNavItem from "./components/SubNavItem";
import CustomSVG from "@app/core/src/components/icons/svg";
import TabCompanySelect from "../../../../../../ui/pages/app/routing/TabCompanySelect";
import {useCompanySecurity} from "../../../../../../security/useCompanySecurity";

interface NavItemParams {
    unrolled: any;
    idx: number;
    nav: any;
    onToggle: any;
    onClick: (value: any) => void;
}

function NavItem(props: NavItemParams) {
    const location = useLocation();
    const {canAccess} = useCompanySecurity();

    useEffect(() => {
        props.nav.path === location.pathname && props.onToggle(props.nav.parent ?? 'app')

        return () => {};
    }, []);


    return <div className={`menu-item mb-2 fs-5 fw-bold menu-accordion ${props.unrolled === props.idx && "hover show"}`}>
        {props.nav?.path !== undefined ?
            <Link to={props.nav.path} className={`menu-link ${props.nav.path === location.pathname ? "active" : ""}`}>
                <span className="menu-icon">
                    <CustomSVG type={props.nav.icon}/>
                </span>
                <span className="menu-title fw-bold">
                    <IntLabel label={props.nav.label}/>
                </span>
            </Link>
            :
            <span className="menu-link" onClick={props.onClick}>
                <span className="menu-icon">
                    <CustomSVG type={props.nav.icon}/>
                </span>
                <span className="menu-title fw-bold"><IntLabel label={props.nav.label}/></span>
                {props.nav?.children !== undefined && <span className="menu-arrow"/>}
            </span>
        }
        {props.nav?.children?.map((item: any, i: number) =>
            // (undefined === item.access || canAccess(item.access)) ?
                <SubNavItem key={i} nav={item} idx={props?.idx}/>
                // : null
        )}
    </div>;
}

function AppSecondarySidebar({navItemConfig, targeted, onToggle}: {navItemConfig: any[], targeted: string, onToggle: any}) {
    const [unrolled, setUnrolled] = useState<any>("")
    const {canAccess} = useCompanySecurity();

    return(<div className="aside-secondary d-flex flex-row-fluid">
        <div className="aside-workspace">
            <div className="d-flex h-100 flex-column">
                <div className="flex-column-fluid hover-scroll-y">
                    <div className="tab-content ">
                        {navItemConfig.map((item: any, index) => {
                            return <SidebarTabPane value={item} activated={targeted === item.domain} key={index}>
                                {item?.content === 'company_select' && <TabCompanySelect/>}

                                {item?.children  !== undefined && item?.children?.map(
                                    (nav: any, idx: number) => (undefined === nav.access || canAccess(nav.access)) ? <NavItem key={idx}
                                         unrolled={unrolled}  idx={idx} nav={nav} onToggle={onToggle}
                                         onClick={() => setUnrolled(unrolled !== idx ? idx : "")}/> : ''
                                )}
                            </SidebarTabPane>;
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default AppSecondarySidebar;
import {callAction, callActionWithCustom, callActionWithId} from "../../helpers";

let landBase = '/company/api/{companyId}/land'

export const fetchCompanyLands = callActionWithCustom<any>(`${landBase}/`, 'get', false);
export const fetchPartnerLands = callAction<any>(`${landBase}`, 'get', false);
export const fetchCompanyLand = callActionWithCustom<any>(`${landBase}/get/{id}`, 'get', false);
export const fetchCompanyLandForEdit = callActionWithCustom<any>(`${landBase}/get/{id}/for-edit`, 'get', false);
export const addCompanyLand = callActionWithCustom<any>(`${landBase}/create`, 'post', false);
export const editCompanyLand = callActionWithCustom<any>(`${landBase}/edit/{id}`, 'patch', false);
export const deleteCompanyLand = callActionWithCustom<any>(`${landBase}/{id}/delete`, 'delete', false);
export const publishCompanyLand = callActionWithCustom<any>(`${landBase}/{id}/change-status`, 'patch', false);
export const validateCompanyLand = callActionWithCustom<any>(`${landBase}/{id}/validate`, 'patch', false);
export const fetchLandLogs = callActionWithCustom<any>(`${landBase}/logs`, 'get', false);

export const fetchCompanyLandParts = callActionWithCustom<any>(`${landBase}/parts`, 'get', false);
export const fetchCompanyLandPart = callActionWithCustom<any>(`${landBase}/part/{id}`, 'get', false);
export const addCompanyLandPart = callActionWithCustom<any>(`${landBase}/part/create`, 'post', false);
export const editCompanyLandPart = callActionWithCustom<any>(`${landBase}/part/edit/{id}`, 'patch', false);
export const deleteCompanyLandPart = callActionWithCustom<any>(`${landBase}/part/delete/{id}`, 'delete', false);
export const fetchLandPartLogs = callActionWithCustom<any>(`${landBase}/part/logs`, 'get', false);

export const fetchCompanyContracts = callActionWithCustom<any>(`${landBase}/contracts`, 'get', false);
export const fetchCompanyContract = callActionWithCustom<any>(`${landBase}/contract/{id}`, 'get', false);
export const addCompanyContract = callActionWithCustom<any>(`${landBase}/contract/create`, 'post', false);
export const editCompanyContract = callActionWithCustom<any>(`${landBase}/contract/{id}`, 'patch', false);
export const validateCompanyContract = callActionWithCustom<any>(`${landBase}/contract/{id}/validate`, 'patch', false);
export const declineCompanyContract = callActionWithCustom<any>(`${landBase}/contract/{id}/delete`, 'delete', false);
export const fetchLandContractLogs = callActionWithCustom<any>(`${landBase}/contract/logs`, 'get', false);

export const fetchCompanyPayments = callActionWithCustom<any>(`${landBase}/payments`, 'get', false);
export const fetchCompanyPayment = callActionWithCustom<any>(`${landBase}/payment/get/{id}`, 'get', false);
export const addCompanyPayment = callActionWithCustom<any>(`${landBase}/payment/add`, 'post', false);
export const editCompanyPayment = callActionWithCustom<any>(`${landBase}/payment/edit/{id}`, 'patch', false);
export const validateCompanyPayment = callActionWithCustom<any>(`${landBase}/payment/validate/{id}`, 'patch', false);
export const declineCompanyPayment = callActionWithCustom<any>(`${landBase}/payment/delete/{id}`, 'delete', false);
export const fetchLandPaymentLogs = callActionWithCustom<any>(`${landBase}/payment/logs`, 'get', false);

export const fetchCompanyContractInterests = callActionWithCustom<any>(`${landBase}/interests`, 'get', false);

//contractor
export const fecthAllCompanyContractor = callActionWithCustom<any>(`${landBase}/get/all/user`, "get", false);
export const fecthOneCompanyContractor = callActionWithCustom<any>(`${landBase}/select/user`, "get", false);

export const fetchLocationApi = callAction<any>('https://nominatim.openstreetmap.org/search', 'get', false)
    // ?q=bodomo&format=json

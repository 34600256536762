import React from 'react';
import AppLayout from "@apps/app/src/components/Layout/index";
import {hotelRoutesPaths} from "./hotelRoutesPaths";
import HotelView from "../pages/hotel/HotelView";
import HotelContractView from "../pages/hotelContract/HotelContractView";
import HotelReservationView from "../pages/hotelReservation/HotelReservationView";
import HotelPaymentView from "../pages/hotelPayment/HotelPaymentView";
import HotelPropertyView from "../pages/hotelProperty/HotelPropertyView";
import HotelContractorView from "../pages/hotelContractor/HotelContractorView";

const hotelRoutes = [
    {
        path: hotelRoutesPaths.HOTEL_VIEW,
        element: <AppLayout/>,
        children: [
            {
                path: hotelRoutesPaths.HOTEL_VIEW,
                element: <HotelView/>,
            },
            {
                path: hotelRoutesPaths.HOTEL_CONTRACT,
                element: <HotelContractView/>,
            },
            {
                path: hotelRoutesPaths.HOTEL_RESERVATION,
                element: <HotelReservationView/>,
            },
            {
                path: hotelRoutesPaths.HOTEL_PAYMENT,
                element: <HotelPaymentView/>,
            },
            {
                path: hotelRoutesPaths.HOTEL_PROPERTY,
                element: <HotelPropertyView/>,
            },
            {
                path: hotelRoutesPaths.HOTEL_CONTRACTOR,
                element: <HotelContractorView/>,
            },
        ]
    }
]

export default hotelRoutes;
import React from 'react';
import Modal from "react-bootstrap/Modal";
import {IntLabel} from "@app/i18n";
import LandPartForm from "../../../components/form/LandPartForm";
import {ModalProps} from "../../ModalContainer";
import {addCompanyLandPart} from "../../../api";
import toast from "react-hot-toast";

function AddLandPartModal(props: ModalProps) {
    const {landId, companyId}:{landId?:any, companyId: any} = props.modalProps
    const [fetching, setFetching] = React.useState(false);

    return (
        <Modal
            show={props.visible}
            onHide={props.onClose}
            backdrop="static"
            centered
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><IntLabel label="btn.add.land.part"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LandPartForm
                      onSubmit={(part: any) => {
                          setFetching(true);
                          addCompanyLandPart(
                              {companyId:companyId},
                              {
                                  landId: landId,
                                  ...part
                              })
                              .then(() => {
                                  toast.success("text.success.message")
                                  setFetching(false)
                                  props.onClose()
                                  window.location.reload()
                              }).catch((res: any) => {
                                  toast.error("text.error.message" ?? res.message)
                                  setFetching(false)
                          })
                      }}
                      isLoading={fetching}
                      onCancelClick={()=>props.onClose()}
                />
            </Modal.Body>
        </Modal>
    );
}

export default AddLandPartModal;
import {landRoutePaths} from "./landRoutePaths";
import {SubNavItemProps} from "../../../../components/Layout/AppLayout/AppSidebars/layers/secondary/components/SubNavItem";
import {PermissionAccess} from "@app/core/src/interface/PermissionAccess";


export const landMenu = [
    {
        domain: 'land',
        title: 'menu.domain.land',
        description: 'menu.domain.land.description',
        children: [
            {
                label: 'menu.manage.land',
                icon: 'windows_menu',
                parent: 'land',
                access: [
                    PermissionAccess.LAND_ADMINISTRATION,
                    PermissionAccess.MANAGE_LAND,
                ],
                children: [
                    {
                        label: 'menu.domain.land.list',
                        parent: 'land',
                        path: landRoutePaths.LAND_VIEW,
                        access: [
                            PermissionAccess.LAND_ADMINISTRATION,
                            PermissionAccess.MANAGE_LAND,
                        ],
                    },
                    {
                        label: 'menu.domain.land.validation',
                        parent: 'land',
                        path: landRoutePaths.LAND_VALIDATION_VIEW,
                        access: [
                            PermissionAccess.LAND_ADMINISTRATION,
                        ],
                    }
                ] as SubNavItemProps[]
            },
            {
                label: 'menu.manage.contract',
                icon: 'blocks',
                parent: 'land',
                access: [
                    PermissionAccess.LAND_ADMINISTRATION,
                    PermissionAccess.MANAGE_LAND_CONTRACT,
                ],
                children: [
                    {
                        label: 'menu.domain.contract.list',
                        parent: 'land',
                        path: landRoutePaths.LAND_CONTRACT,
                        access: [
                            PermissionAccess.MANAGE_LAND_CONTRACT,
                            PermissionAccess.LAND_ADMINISTRATION,
                        ],
                    },
                    {
                        label: 'menu.domain.contract.validation',
                        parent: 'land',
                        path: landRoutePaths.LAND_CONTRACT_VALIDATION,
                        access: [
                            PermissionAccess.LAND_ADMINISTRATION,
                        ],
                    }
                ] as SubNavItemProps[]
            },
            {
                label: 'menu.manage.payment',
                icon: 'payments',
                parent: 'land',
                access: [
                    PermissionAccess.MANAGE_LAND_PAYMENT,
                    PermissionAccess.LAND_ADMINISTRATION,
                ],
                children: [
                    {
                        label: 'menu.domain.payment.list',
                        parent: 'land',
                        path: landRoutePaths.LAND_PAYMENT,
                        access: [
                            PermissionAccess.MANAGE_LAND_PAYMENT,
                            PermissionAccess.LAND_ADMINISTRATION,
                        ],
                    },
                    {
                        label: 'menu.domain.payment.validation',
                        parent: 'land',
                        path: landRoutePaths.LAND_PAYMENT_VALIDATION,
                        access: [
                            PermissionAccess.LAND_ADMINISTRATION,
                        ],
                    }
                ] as SubNavItemProps[]
            },
            {
                label: 'menu.manage.land.interest',
                icon: 'addAmount',
                path: landRoutePaths.LAND_INTEREST,
                parent: 'land',
                access: [
                    PermissionAccess.LAND_ADMINISTRATION,
                ],
            },
            {
                label: 'menu.domain.land.reservation',
                icon: 'reservation',
                path: landRoutePaths.LAND_RESERVATION,
                parent: 'land',
                access: [
                    PermissionAccess.LAND_ADMINISTRATION,
                ],
            },
            {
                label: 'menu.domain.land.contractor',
                icon: 'users',
                path: landRoutePaths.LAND_CONTRACTOR,
                parent: 'land',
                access: [
                    PermissionAccess.LAND_ADMINISTRATION,
                ],
            },
        ],
    },
];